import React from 'react';
import { ROUTES } from 'models';
import { useAuthorisationContext } from 'services';
import { NavLink } from 'components/ui';
import styles from './NavMenu.module.scss';

export const NavMenu = () => {
  const { logout, checkPermissions } = useAuthorisationContext();

  return (
    <>
      {Object.keys(ROUTES).map((sectionKey) => {
        const section = ROUTES[sectionKey];

        return (
          <div key={sectionKey} className={styles.section}>
            {sectionKey !== 'GENERAL' ? (
              <h2 className={styles.sectionTitle}>
                {sectionKey.toLowerCase()}
              </h2>
            ) : null}

            <div className={styles.linkList}>
              {Object.keys(section).map((key) => {
                const route = section[key];

                if (route.permissions && !checkPermissions(route.permissions)) return null;

                return (
                  <NavLink
                    key={key}
                    to={route.path}
                    onClick={key === 'LOG_OUT' ? logout : () => { }}
                  >
                    <>
                      {route.icon}
                      {route.label}
                    </>
                  </NavLink>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
