import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Drawer } from 'antd';
import { Logo, NavMenu } from 'components';
import { MenuOutlined } from '@ant-design/icons';
import styles from './NavMenuButton.module.scss';

export const NavMenuButton = (): ReactElement => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => { onClose(); }, [location]);

  return (
    <>
      <Button onClick={showDrawer} icon={<MenuOutlined />} className={styles.drawerButton} />
      <Drawer title={<Logo />} placement="right" onClose={onClose} open={open}>
        <NavMenu />
      </Drawer>
    </>
  );
};
