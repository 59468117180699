import React, { ReactElement, useState } from 'react';
import { Table } from 'antd';
import { IStat } from 'models';
import { useBusinessByIdQuery, useRestartContainerMutate } from 'services';
import { generateColumns } from './utils';

export const LogsViewerTable = ({ id }: { id: number; }): ReactElement => {
  const { data, isLoading } = useBusinessByIdQuery({ id });
  const { mutate, isLoading: isRestarting } = useRestartContainerMutate();
  const [, setContainerLogs] = useState<string[]>([]);

  const onRestartContainer = (values: IStat) => {
    return mutate({
      id, container: values.name,
    });
  };

  return (
    <Table
      dataSource={data?.stats}
      columns={generateColumns({ id, setContainerLogs, onRestartContainer })}
      loading={isLoading || isRestarting}
      rowKey={(record) => record.id}
    />
  );
};
