import React, { ReactElement, useCallback, useState } from 'react';
import { notification } from 'antd';
import { axios, queryClient } from 'config';
import { IUser, UnknownType } from 'models';
import { use2FA } from 'hooks/use2FA';
import { LoginData, useAuthMe, useLogin } from './hooks/useAuth';
import { LocalStorage, LocalStorageKey } from 'utils/localStorage';
import { AuthorisationContext, IAuthorisationContext, LoginParams } from './AuthorisationContext';
import { PageLoader } from 'components';

interface IAuthorisationProviderProps {
  children: ReactElement;
}

export const AuthorisationProvider = ({
  children,
}: IAuthorisationProviderProps): ReactElement => {
  const [user, setUser] = useState<IUser | null>(null);
  const [token, setToken] = useState(
    LocalStorage.get(LocalStorageKey.ACCESS_TOKEN),
  );
  const [initialLoading, setInitialLoading] = useState<boolean>(Boolean(token));

  const {
    qrcode,
    onError: onErrorWith2FAWrapper,
    onSuccess: onSuccessWith2FAWrapper,
    stage,
    setStage,
    prevStage,
    setPrevStage,
  } = use2FA();

  const { loginMutate, isLoading } = useLogin({
    onSuccess: (data: LoginData) => {
      onSuccessWith2FAWrapper(data, () => {
        LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, data.accessToken);
        axios.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
        setToken(data.accessToken);
        setUser(data.currentUser);
      });
    },
    onError: (error: UnknownType) => {
      onErrorWith2FAWrapper(error, () => {
        notification.error({
          message: 'Please, enter the correct email and password',
        });
      });
    },
  });

  useAuthMe({
    onSuccess: (data) => {
      setUser(data);
      setInitialLoading(false);
    },
    onError: () => {
      LocalStorage.remove(LocalStorageKey.ACCESS_TOKEN);
      setToken(null);
      setInitialLoading(false);
    },
  }, !!(initialLoading && token));

  const checkPermissions = useCallback(
    (permissions: string[]) => {
      if (!user || !user?.group?.isActive) {
        return false;
      }

      return user.group.permissions.some(({ name }) =>
        permissions.some((item) => item === name),
      );
    },
    [user],
  );

  const login = ({ email, password, otpCode }: LoginParams) => {
    loginMutate({ email, password, otpCode });
  };

  const logout = () => {
    if (initialLoading) {
      setInitialLoading(false);
    }
    axios.defaults.headers.Authorization = null;
    LocalStorage.remove(LocalStorageKey.ACCESS_TOKEN);
    setUser(null);
    setToken(null);
    queryClient.clear();
  };

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      return error.response.status === 401 &&
      !error.config.url.includes('auth/login')
        ? logout()
        : Promise.reject(error);
    },
  );

  const contextData: IAuthorisationContext = {
    user,
    token,
    login,
    logout,
    loading: isLoading || initialLoading,
    checkPermissions,
    twoFA: {
      qrcode,
      stage,
      setStage,
      prevStage,
      setPrevStage,
      onError: onErrorWith2FAWrapper,
      onSuccess: onSuccessWith2FAWrapper,
    },
  };

  return (
    <AuthorisationContext.Provider value={contextData}>
      {initialLoading ? <PageLoader /> : children}
    </AuthorisationContext.Provider>
  );
};
