import _ from 'lodash';
import {
  CurrencyEnum,
  IAverageTransactionsVolume,
  ICurrency,
  ICurrencyBreakdown,
  IGrossIncomeCEX,
  IPayinPayout,
  IRefill,
  IStatistics,
} from 'models';
import { countCex, countConvertedAmount, countTotal, filterByKeys } from 'utils';
import { CardTable } from 'components/common/CardTable';
import { Collapse } from 'components/ui';
import { CollapseHeader } from 'components/ui/Collapse/components';
import { CountUpType } from 'components/ui/CountUp/CountUp';

export enum CardCollapseTableEnum {
  CURRENCY = 'currency',
  EXPENSES_BREAKDOWN = 'expenses_breakdown',
  AVERAGE_TRANSCACTIONS_VOLUME = 'average_transactions_volume',
  AVERAGE_TRANSCACTIONS_TIME = 'average_transactions_time',
  GROSS_INCOME_CEX = 'gross_income_cex',
  PAYIN_PAYOUT = 'payin_payou',
  REFILL_EXPENSES = 'refill_expenses',
}

export type GenerateCardCollapseContent<T> = {
  title: string;
  amount?: number | null;
  currency: CurrencyEnum;
  data: T;
  type: CardCollapseTableEnum;
  content?: JSX.Element;
  countupType?: CountUpType;
};

export function generateCardCollapseItemContent<T>({
  amount,
  currency,
  data,
  title,
  type,
  content,
  countupType,
}: GenerateCardCollapseContent<T>) {
  return {
    header: (
      <CollapseHeader
        title={title}
        amount={amount!}
        currency={currency}
        type={countupType!}
      />
    ),
    content: content ? (
      content
    ) : (
      <CardTable data={data} type={type} currency={currency} />
    ),
  };
}

export function generateCardCollapseContent<T extends IStatistics>({
  currency,
  data,
}: GenerateCardCollapseContent<T>) {
  const incomeCardExchanges = [
    generateCardCollapseItemContent<IGrossIncomeCEX>({
      title: 'CEX Spread',
      data: data?.totalsGroups?.grossIncomeCEX,
      type: CardCollapseTableEnum.GROSS_INCOME_CEX,
      amount:
        currency === CurrencyEnum.USDT
          ? countCex(data?.totalsGroups?.grossIncomeCEX)
          : null,
      currency,
      countupType: 'currency',
    }),
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Dynamic Commision',
      data: data?.totalsGroups?.grossIncomeExchanges,
      type: CardCollapseTableEnum.CURRENCY,
      amount: data?.totalsAmounts?.grossIncomeExchanges,
      currency,
      countupType: 'currency',
    }),
  ];

  const incomeCard = [
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Deposits',
      data: data?.totalsGroups?.grossIncomeDeposits,
      type: CardCollapseTableEnum.CURRENCY,
      amount: data?.totalsAmounts?.grossIncomeDeposits,
      currency,
      countupType: 'currency',
    }),
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Withdrawals',
      data: data?.totalsGroups?.grossIncomeWithdrawals,
      type: CardCollapseTableEnum.CURRENCY,
      amount: data?.totalsAmounts?.grossIncomeWithdrawals,
      currency,
      countupType: 'currency',
    }),
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Invoices',
      data: data?.totalsGroups?.grossIncomeInvoices,
      type: CardCollapseTableEnum.CURRENCY,
      amount: data?.totalsAmounts?.grossIncomeInvoices,
      currency,
      countupType: 'currency',
    }),
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Clients',
      data: data?.totalsGroups?.grossIncomeClients,
      type: CardCollapseTableEnum.CURRENCY,
      amount: data?.totalsAmounts?.grossIncomeClients,
      currency,
      countupType: 'currency',
    }),
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Exchanges',
      data: data?.totalsGroups?.grossIncomeExchanges,
      type: CardCollapseTableEnum.CURRENCY,
      amount:
        data?.totalsAmounts?.grossIncomeExchanges +
        (currency === CurrencyEnum.USDT
          ? countCex(data?.totalsGroups?.grossIncomeCEX)
          : 0),
      currency,
      countupType: 'currency',
      content: (
        <div>
          <Collapse items={incomeCardExchanges} isInner />
        </div>
      ),
    }),
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Transferred Earnings',
      data: data?.totalsGroups?.income,
      type: CardCollapseTableEnum.CURRENCY,
      amount: data?.totalsAmounts?.income,
      currency,
      countupType: 'currency',
    }),
  ];

  const expensesCard = [
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Network',
      data: data?.totalsGroups?.expenses?.network,
      type: CardCollapseTableEnum.CURRENCY,
      amount: data?.totalsAmounts?.expensesBreakdown?.network,
      currency,
      countupType: 'currency',
    }),
    generateCardCollapseItemContent<IRefill>({
      title: 'Refill',
      data: filterByKeys(data?.totalsGroups?.expenses?.refill),
      type: CardCollapseTableEnum.REFILL_EXPENSES,
      amount: data?.totalsAmounts?.expensesBreakdown?.refill,
      currency,
      countupType: 'currency',
    }),
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Wallets activation',
      data: data?.totalsGroups?.expenses?.acc_activate,
      type: CardCollapseTableEnum.CURRENCY,
      amount: data?.totalsAmounts?.expensesBreakdown?.acc_activate,
      currency,
      countupType: 'currency',
    }),
  ];

  const averageTransactionsTimeCard = [
    generateCardCollapseItemContent<ICurrency<number>>({
      title: 'Average transaction time',
      data: data?.totalsGroups?.averageTransactionsTimeTotalsGroups,
      type: CardCollapseTableEnum.AVERAGE_TRANSCACTIONS_TIME,
      amount: data?.totalsGroups?.averageTransactionsTime,
      currency,
      countupType: 'time',
    }),
  ];

  const totalInflowCard = [
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Total inflow',
      data: data?.totalsGroups?.totalInflow,
      type: CardCollapseTableEnum.CURRENCY,
      amount: data?.totalsAmounts?.totalInflow,
      currency,
      countupType: 'currency',
    }),
    // generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
    //   title: 'Clients breakdown',
    //   data: data?.totalsGroups?.inflowTotalsGroups?.clients,
    //   type: CardCollapseTableEnum.CURRENCY,
    //   amount: data?.totalsAmounts?.inflowBreakdown?.clients,
    //   currency,
    //   countupType: 'currency',
    // }),
    // generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
    //   title: 'Exchanges breakdown',
    //   data: data?.totalsGroups?.inflowTotalsGroups?.exchanges,
    //   type: CardCollapseTableEnum.CURRENCY,
    //   amount: data?.totalsAmounts?.inflowBreakdown?.exchanges,
    //   currency,
    //   countupType: 'currency',
    // }),
    // generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
    //   title: 'Invoices breakdown',
    //   data: data?.totalsGroups?.inflowTotalsGroups?.invoices,
    //   type: CardCollapseTableEnum.CURRENCY,
    //   amount: data?.totalsAmounts?.inflowBreakdown?.invoices,
    //   currency,
    //   countupType: 'currency',
    // }),
    // generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
    //   title: 'Units breakdown',
    //   data: data?.totalsGroups?.inflowTotalsGroups?.units,
    //   type: CardCollapseTableEnum.CURRENCY,
    //   amount: data?.totalsAmounts?.inflowBreakdown?.units,
    //   currency,
    //   countupType: 'currency',
    // }),
  ];

  const totalOutflowCard = [
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'Total outflow',
      data: data?.totalsGroups?.totalOutflow,
      type: CardCollapseTableEnum.CURRENCY,
      amount: data?.totalsAmounts?.totalOutflow,
      currency,
      countupType: 'currency',
    }),
    // generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
    //   title: 'Clients breakdown',
    //   data: data?.totalsGroups?.outflowTotalsGroups?.clients,
    //   type: CardCollapseTableEnum.CURRENCY,
    //   amount: data?.totalsAmounts?.outflowBreakdown?.clients,
    //   currency,
    //   countupType: 'currency',
    // }),
    // generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
    //   title: 'Exchanges breakdown',
    //   data: data?.totalsGroups?.outflowTotalsGroups?.exchanges,
    //   type: CardCollapseTableEnum.CURRENCY,
    //   amount: data?.totalsAmounts?.outflowBreakdown?.exchanges,
    //   currency,
    //   countupType: 'currency',
    // }),
    // generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
    //   title: 'Invoices breakdown',
    //   data: data?.totalsGroups?.outflowTotalsGroups?.invoices,
    //   type: CardCollapseTableEnum.CURRENCY,
    //   amount: data?.totalsAmounts?.outflowBreakdown?.invoices,
    //   currency,
    //   countupType: 'currency',
    // }),
    // generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
    //   title: 'Units breakdown',
    //   data: data?.totalsGroups?.outflowTotalsGroups?.units,
    //   type: CardCollapseTableEnum.CURRENCY,
    //   amount: data?.totalsAmounts?.outflowBreakdown?.units,
    //   currency,
    //   countupType: 'currency',
    // }),
  ];

  const averageTransactionsVolumeCard = [
    generateCardCollapseItemContent<IAverageTransactionsVolume>({
      title: 'Average transactions Volume',
      data: data?.totalsGroups?.averageTransactionsVolume,
      type: CardCollapseTableEnum.AVERAGE_TRANSCACTIONS_VOLUME,
      amount: data?.totalsGroups?.averageTransactionsVolume?.convertedAmount,
      currency,
    }),
  ];

  const transactionCountCard = [
    generateCardCollapseItemContent<IPayinPayout>({
      title: 'Total breakdown',
      data: data?.totalsGroups?.transactionsCountTotalsGroups,
      amount: (data?.totalsGroups?.transactionsCountTotalsGroups?.payout || 0)
        + (data?.totalsGroups?.transactionsCountTotalsGroups?.payin || 0),
      type: CardCollapseTableEnum.PAYIN_PAYOUT,
      currency,
    }),
  ];

  const currencyExchangeCex = !_.isEmpty(
    data?.totalsGroups?.exchangeVolume?.cex,
  )
    ? Object.entries(data?.totalsGroups?.exchangeVolume?.cex).map(
      ([key, value]) =>
        generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
          title: key,
          data: value,
          amount: countConvertedAmount(Object.values(value)),
          type: CardCollapseTableEnum.CURRENCY,
          currency,
          countupType: 'currency',
        }),
    )
    : [];

  const currencyExchangeDex = !_.isEmpty(
    data?.totalsGroups?.exchangeVolume?.dex,
  )
    ? Object.entries(data?.totalsGroups?.exchangeVolume?.dex).map(
      ([key, value]) =>
        generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
          title: key,
          data: value,
          amount: countConvertedAmount(Object.values(value)),
          type: CardCollapseTableEnum.CURRENCY,
          currency,
          countupType: 'currency',
        }),
    )
    : [];

  const currencyExchangeCard = [
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'СEX',
      data: {},
      amount: countTotal(data?.totalsGroups?.exchangeVolume?.cex),
      type: CardCollapseTableEnum.CURRENCY,
      currency,
      countupType: 'currency',
      content: (
        <div>
          <Collapse items={currencyExchangeCex} isInner />
        </div>
      ),
    }),
    generateCardCollapseItemContent<ICurrency<ICurrencyBreakdown>>({
      title: 'DEX',
      data: {},
      amount: countTotal(data?.totalsGroups?.exchangeVolume?.dex),
      type: CardCollapseTableEnum.CURRENCY,
      currency,
      countupType: 'currency',
      content: (
        <div>
          <Collapse items={currencyExchangeDex} isInner />
        </div>
      ),
    }),
  ];

  return {
    incomeCard,
    expensesCard,
    averageTransactionsTimeCard,
    totalInflowCard,
    totalOutflowCard,
    averageTransactionsVolumeCard,
    transactionCountCard,
    currencyExchangeCard,
  };
}
