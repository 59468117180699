import type { Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { Form as AntdForm } from 'antd';
import type React from 'react';
import styles from './FormItem.module.scss';

type AntdFormItemProps = React.ComponentProps<typeof AntdForm.Item>;

export type FormItemProps<TFieldValues extends FieldValues = FieldValues> = {
  children: React.ReactNode;
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  error?: FieldError | string | undefined;
} & Omit<AntdFormItemProps, 'name' | 'normalize' | 'rules' | 'validateStatus'>;

export const FormItem = <TFieldValues extends FieldValues = FieldValues>({
  children,
  control,
  name,
  help,
  error,
  ...props
}: FormItemProps<TFieldValues>) => {
  const { field, fieldState } = useController({ name, control });

  const handleNormalize: AntdFormItemProps['normalize'] = (value) => {
    field.onChange(value);
    return value;
  };

  return (
    <AntdForm.Item
      {...props}
      name={name as string}
      initialValue={field.value}
      normalize={handleNormalize}
      validateStatus={fieldState.invalid ? 'error' : undefined}
      help={fieldState.error?.message ?? help}
      className={styles.formItem}
      labelAlign="left"
    >
      {children}
      {error && <p>{error as string}</p>}
    </AntdForm.Item>
  );
};
