import { CurrencyEnum, IGrossIncomeCEX, IStatistics } from 'models';

export const countCex = (data: IGrossIncomeCEX = {}): number => {
  return Object?.values(data)?.reduce(
    (total, exchange) => total + exchange?.spred_in_usd,
    0,
  );
};

export const countIncome = (
  data: IStatistics,
  currency: CurrencyEnum,
): number => {
  const cexAmount =
    currency === CurrencyEnum.USDT ? data?.totalsAmounts?.grossIncomeCEX : 0;

  return (
    data?.totalsAmounts?.grossIncomeClients +
    data?.totalsAmounts?.grossIncomeDeposits +
    data?.totalsAmounts?.grossIncomeExchanges +
    data?.totalsAmounts?.grossIncomeInvoices +
    data?.totalsAmounts?.grossIncomeWithdrawals +
    cexAmount
  );
};
