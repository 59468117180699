import { ReactElement } from 'react';
import { Control } from 'react-hook-form';
import { Select } from 'antd';
import { ApiResponseType, IBusiness, UnknownType } from 'models';
import { useBusinessesQuery } from 'services';
import { generateSelectOptions } from 'utils';
import { FormItem } from 'components';
import styles from './BusinessSelect.module.scss';

export type BusinessSelectProps = {
  control: Control<UnknownType>;
  name?: string;
  initialValues: IBusiness[];
};

export const BusinessSelect = ({
  control,
  name = 'ids',
  initialValues,
}: BusinessSelectProps): ReactElement => {
  const { data: businesses, isLoading } = useBusinessesQuery();

  const defaultOptions = (initialValues || []).map(
    (business: IBusiness) => business.id,
  );

  return (
    <FormItem control={control} name={name} label="Businesses">
      {isLoading ? <div className="__sk_input" /> : (
        <Select
          mode="multiple"
          options={generateSelectOptions(
            (businesses as unknown as ApiResponseType<IBusiness>).nodes,
          )}
          popupClassName={styles.dropdownMenu}
          getPopupContainer={(trigger) => trigger.parentElement}
          size="large"
          placeholder="Choose businesses"
          defaultValue={defaultOptions}
        />
      )}
    </FormItem>
  );
};
