import { useState } from 'react';
import { UnknownType } from 'models';

export const useSelectMenuItems = (initialState?: UnknownType) => {
  const [selected, setSelected] = useState<string[]>(initialState || []);

  const selectHandler = (id: string | string[]) => {
    if (!Array.isArray(id) && selected.includes(id)) {
      // TODO: check problem may be here
      return setSelected(selected.filter((i) => i !== id));
    }
    return setSelected(id as unknown as string[]);
  };

  return { selected, selectHandler };
};
