import { useMutation } from 'react-query';
import { notification } from 'antd';
import { axios } from 'config';

export type UseRestartContainerMutateType = {
  id: number;
  container: string;
};

export const useRestartContainerMutate = () => {
  return useMutation({
    mutationFn: async (values: UseRestartContainerMutateType) => {
      const { id, container } = values;
      const payload = { container };

      return axios.put(process.env.REACT_APP_AUTH_URI + '/ssh/' + id, {
        ...payload,
      });
    },
    onSuccess: () => notification.success({
      message: 'Container has been restarted succesfully.',
    }),
    onError: (e: Error) => notification.error({
      message: `Something went wrong while restarting the container, ${e.message}`,
    }),
  });
};
