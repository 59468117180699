import React, { PropsWithChildren, ReactElement } from 'react';
import { ConfigProvider } from 'antd';
import { theme } from 'config';

export type AntDesignConfigProviderProps = PropsWithChildren;

export const AntDesignConfigProvider = ({
  children,
}: AntDesignConfigProviderProps): ReactElement => {
  return (
    <ConfigProvider theme={theme} componentSize="large">
      {children}
    </ConfigProvider>
  );
};
