export enum StatusEnum {
  CREATE_DEPOSIT = 'Created Deposit',
  CREATE_WITHDRAW = 'Created Withdraw',
  CREATE_MARKET_ORDER = 'Created Market Order',
  DEPOSIT_SUCCESS = 'Deposit Success',
  MARKET_ORDER_SUCCESS = 'Market Order Success',
  COMPLETE = 'Complete',
  SENT = 'Sent',
  ACTIVE = 'Active',
  FINISHED = 'Finished',
  ERR_INSUFFICIENT = 'ErrInsufficient',
  ERR_MARKET_ORDER = 'ErrMarketOrder',
  UNEXPECTED_EXCHANGE_ERR = 'UnexpectedExchangeErr',
  WITHDRAWAL_ERROR = 'WithdrawalError',
  INACTIVE = 'Inactive',
}

export enum StatusClassEnum {
  CREATE = 'create',
  SUCCESS = 'success',
  ERROR = 'error',
}
