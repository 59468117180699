import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { axios } from 'config';
import { IPermission } from 'models';
import { LocalStorage, LocalStorageKey } from 'utils';

type OptionType = {
  onSuccess?: () => Promise<void>;
  onError?: (e: AxiosError<{ message: string }>) => void;
};

export const usePermissions = (options: OptionType) => {
  const getPermissions = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_AUTH_URI + '/permissions',
      {
        headers: {
          Authorization:
            'Bearer ' + LocalStorage.get(LocalStorageKey.ACCESS_TOKEN),
        },
      },
    );
    return data as { nodes: IPermission[]; totalCount: number };
  };

  return useQuery({
    queryKey: ['permissions'],
    queryFn: getPermissions,
    ...options,
  });
};
