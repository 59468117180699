import { CurrencyIcon } from 'utils';

export const currencyMap = {
  USDT: {
    title: 'USDT',
    icon: CurrencyIcon.USDT,
  },
  USDC: {
    title: 'USDC',
    icon: CurrencyIcon.USDC,
  },
  ETH: {
    title: 'ETH',
    icon: CurrencyIcon.ETH,
  },
  TRX: {
    title: 'TRX',
    icon: CurrencyIcon.TRX,
  },
  BTC: {
    title: 'BTC',
    icon: CurrencyIcon.BTC,
  },
  BNB: {
    title: 'BNB',
    icon: CurrencyIcon.BNB,
  },
  DAI: {
    title: 'DAI',
    icon: CurrencyIcon.DAI,
  },
  // BUSD: {
  //   title: 'BUSD',
  //   icon: CurrencyIcon.BUSD,
  // },
  // EUR: {
  //   title: 'EUR',
  //   icon: CurrencyIcon['EUR'],
  // },
  // USD: {
  //   title: 'USD',
  //   icon: CurrencyIcon['USD'],
  // },
  // GBP: {
  //   title: 'GBP',
  //   icon: CurrencyIcon['GBP'],
  // },
  // ILS: {
  //   title: 'ILS',
  //   icon: CurrencyIcon['ILS'],
  // },
  // AED: {
  //   title: 'AED',
  //   icon: CurrencyIcon['AED'],
  // },
  // UAH: {
  //   title: 'UAH',
  //   icon: CurrencyIcon['UAH'],
  // },
};
