import { UseMutateFunction, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { axios } from 'config/axios';
import { ApiPath } from 'models/consts';

type OptionType = {
  onSuccess?: () => Promise<void>;
  onError?: (e: AxiosError<{ message: string }>) => void;
};

export interface CreateGroupBody {
  name: string;
  isActive: boolean;
  permissionIds: number[];
}

type ReturnedType = {
  mutation: UseMutateFunction<void, AxiosError<{ message: string }>, CreateGroupBody, unknown>,
  loading: boolean
};

const useCreateGroup = (options: OptionType): ReturnedType => {
  const createGroup = async (body: CreateGroupBody) => {
    const { data } = await axios.post(`${process.env.REACT_APP_AUTH_URI}/${ApiPath.GROUPS_CREATE}`, body);
    return data;
  };

  const { mutate, isLoading: isPending } = useMutation({ mutationFn: createGroup, ...options });

  return {
    mutation: mutate,
    loading: isPending,
  };
};

export default useCreateGroup;
