import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { axios } from 'config';
import { ApiPath, CurrencyEnum, IStatistics, ParamsType } from 'models';
import { LocalStorage, LocalStorageKey } from 'utils';

export type UseBusinessStatisticsProps = {
  ids?: string[];
  params?: ParamsType;
  currency?: CurrencyEnum;
};

export const useBusinessStatisticsQuery = ({
  ids = [],
  params,
  currency,
}: UseBusinessStatisticsProps) => {
  const WITH_IDS = ids.length !== 0;
  const URL = `${process.env.REACT_APP_AUTH_URI}/${
    ApiPath.BUSINESS_STATISTICS
  }${WITH_IDS ? '?ids=' + ids : ''}`;

  const getBusinessStatistics = async (): Promise<IStatistics> => {
    const { data } = await axios.get(URL, {
      headers: {
        Authorization:
          'Bearer ' + LocalStorage.get(LocalStorageKey.ACCESS_TOKEN),
      },
      params: {
        ...params,
        currency: currency,
      },
    });
    return data;
  };

  return useQuery<IStatistics, AxiosError>({
    queryKey: `businesses-statistics-${ids}-${JSON.stringify(params)}`,
    queryFn: getBusinessStatistics,
    enabled: ids.length !== 0,
  });
};
