import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CurrencyEnum, IPayinPayout, UnknownType } from 'models';
import { DataSourceType } from '../types';
import { sortGenerator } from 'utils';
import { CardCollapseTableEnum } from 'components/common/CardNew/utils';
import { CountUp, getCurrencyLabelJSX } from 'components/ui';

type GenerateColumnsProps = {
  type: CardCollapseTableEnum;
  initCurrency?: string;
  tableName?: string;
};

const originalAmountColumnsIconByNetwork: Record<string, CurrencyEnum> = {
  bep20: CurrencyEnum.BNB,
  erc20: CurrencyEnum.ETH,
  trc20: CurrencyEnum.TRX,
};

export const generateCardTableColumns = ({
  type,
  tableName,
  initCurrency,
}: GenerateColumnsProps): ColumnsType<DataSourceType> => {
  const currencyColumns: ColumnsType<DataSourceType> = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      sorter: (a, b) => sortGenerator(a, b, 'currency'),
      render: (currency) =>
        getCurrencyLabelJSX({ currency: currency.toUpperCase() }),
    },
    {
      title: 'Original',
      dataIndex: 'originalAmount',
      key: 'originalAmount',
      sorter: (a, b) => sortGenerator(a, b, 'originalAmount'),
      render: (el, { currency }) => (
        <Tooltip
          title={tableName === 'expenses' && 'Network + Refill + Acc Activate'}
        >
          <CountUp end={el} currency={currency} type="currency" />
        </Tooltip>
      ),
    },
    {
      title: 'Converted',
      dataIndex: 'convertedAmount',
      key: 'convertedAmount',
      sorter: (a, b) => sortGenerator(a, b, 'convertedAmount'),
      render: (el, { currency }) => (
        <CountUp end={el} currency={initCurrency || currency} type="currency" />
      ),
    },
    {
      title: 'Weight, %',
      dataIndex: 'percentage',
      key: 'percentage',
      sorter: (a, b) => sortGenerator(a, b, 'percentage'),
      render: (el) => <CountUp end={el} type="percentage" />,
      width: '120px',
    },
  ];

  const refillExpensesColumns: ColumnsType<DataSourceType> = [
    currencyColumns[0],
    {
      title: 'Delta',
      dataIndex: 'delta',
      key: 'delta',
      sorter: (a, b) => sortGenerator(a, b, 'delta'),
      render: (el, { currency }) => <CountUp end={el} type="currency" currency={currency} />,
    },
    {
      title: 'Current',
      dataIndex: 'currentCost',
      key: 'currentCost',
      sorter: (a, b) => sortGenerator(a, b, 'currentCost'),
      render: (el, { currency }) => <CountUp end={el} type="currency" currency={currency} />,
    },
    {
      title: 'Historic',
      dataIndex: 'historicCost',
      key: 'historicCost',
      sorter: (a, b) => sortGenerator(a, b, 'historicCost'),
      render: (el, { currency }) => <CountUp end={el} type="currency" currency={currency} />,
    },
    {
      title: 'Original',
      dataIndex: 'originalAmount',
      key: 'originalAmount',
      sorter: (a, b) => sortGenerator(a, b, 'originalAmount'),
      render: (el, { currency }) => {
        const [, network] = currency.split('_');
        const networkCurrency = originalAmountColumnsIconByNetwork[network] || '';

        return (
          <Tooltip
            title={tableName === 'expenses' && 'Network + Refill + Acc Activate'}
          >
            <div style={{ display: 'flex', gap: 8, alignItems: 'center', width: 'max-content' }}>
              <CountUp end={el} currency={networkCurrency} type="currency" />
              {getCurrencyLabelJSX({ currency: networkCurrency, label: false })}
            </div>
          </Tooltip>
        );
      },
    },
    ...currencyColumns.slice(2),
  ];

  const cexColumns: ColumnsType<DataSourceType> = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      sorter: (a, b) => sortGenerator(a, b, 'currency'),
      render: (currency) =>
        getCurrencyLabelJSX({ currency: currency.toUpperCase() }),
    },
    {
      title: 'Exchange volume',
      dataIndex: 'exchangeVolume',
      key: 'exchangeVolume',
      sorter: (a, b) => sortGenerator(a, b, 'exchangeVolume'),
      render: (el, { currency }) => (
        <CountUp end={el} currency={currency} type="currency" />
      ),
    },
    initCurrency === CurrencyEnum.USDT ? {
      title: 'Spred in USD',
      dataIndex: 'spred_in_usd',
      key: 'spred_in_usd',
      sorter: (a, b) => sortGenerator(a, b, 'spred_in_usd'),
      render: (el, { currency }) => (
        <Tooltip
          title={tableName === 'expenses' && 'Network + Refill + Acc Activate'}
        >
          <CountUp end={el} currency={initCurrency || currency} type="currency" />
        </Tooltip>
      ),
    } : {},
    {
      title: 'Weight, %',
      dataIndex: 'percentage',
      key: 'percentage',
      sorter: (a, b) => sortGenerator(a, b, 'percentage'),
      render: (el) => <CountUp end={el} type="percentage" />,
      width: '120px',
    },
  ];

  const expensesBreakdownColumns: ColumnsType<DataSourceType> = [
    {
      title: 'Network',
      dataIndex: 'network',
      key: 'network',
      sorter: (a, b) => sortGenerator(a, b, 'network'),
      render: (el) => <CountUp end={el} />,
    },
    {
      title: 'Payout',
      dataIndex: 'payout',
      key: 'payout',
      sorter: (a, b) => sortGenerator(a, b, 'payout'),
      render: (el) => <CountUp end={el} />,
    },
  ];

  const averageTransactionsTimeColumns: ColumnsType<DataSourceType> = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      sorter: (a, b) => sortGenerator(a, b, 'currency'),
      render: (currency) =>
        getCurrencyLabelJSX({ currency: currency.toUpperCase() }),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => sortGenerator(a, b, 'amount'),
      render: (el) => <CountUp end={el} type="time" />,
    },
  ];

  const averageTransactionsVolumeColumns: ColumnsType<DataSourceType> = [
    {
      title: 'Converted amount',
      dataIndex: 'convertedAmount',
      key: 'convertedAmount',
      sorter: (a, b) => sortGenerator(a, b, 'convertedAmount'),
      render: (el) => <CountUp end={el} type="currency" />,
    },
    {
      title: 'Original amount',
      dataIndex: 'originalAmount',
      key: 'originalAmount',
      sorter: (a, b) => sortGenerator(a, b, 'originalAmount'),
      render: (el) => <CountUp end={el} type="currency" />,
    },
  ];

  const transactionsCountBreakdownColumns: ColumnsType<
  DataSourceType & IPayinPayout
  > = [
    {
      title: 'Payin',
      dataIndex: 'payin',
      key: 'payin',
      sorter: (a, b) => sortGenerator(a, b, 'payin'),
      render: (el) => <CountUp end={el} type="count" />,
    },
    {
      title: 'Payout',
      dataIndex: 'payout',
      key: 'payout',
      sorter: (a, b) => sortGenerator(a, b, 'payout'),
      render: (el) => <CountUp end={el} type="count" />,
    },
  ];

  const columns = {
    [CardCollapseTableEnum.CURRENCY]: currencyColumns,
    [CardCollapseTableEnum.GROSS_INCOME_CEX]: cexColumns,
    [CardCollapseTableEnum.EXPENSES_BREAKDOWN]: expensesBreakdownColumns,
    [CardCollapseTableEnum.AVERAGE_TRANSCACTIONS_VOLUME]: averageTransactionsVolumeColumns,
    [CardCollapseTableEnum.AVERAGE_TRANSCACTIONS_TIME]: averageTransactionsTimeColumns,
    [CardCollapseTableEnum.PAYIN_PAYOUT]: transactionsCountBreakdownColumns,
    [CardCollapseTableEnum.REFILL_EXPENSES]: refillExpensesColumns,
  };

  return (columns as UnknownType)[type];
};
