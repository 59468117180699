import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import {
  SegmentedDateEnum,
  segmentedOptions,
} from 'models';
import { Value } from 'hooks';
import styles from './SegmentedDate.module.scss';

export type SegmentedDateProps = {
  segmented: Value<SegmentedValue>;
  isCustomDate: boolean;
  handleSegmented: (val: SegmentedValue) => void,
  handleCustomDate: Dispatch<SetStateAction<boolean>>
  ;
};

export const SegmentedDate = ({ handleCustomDate, handleSegmented, segmented }: SegmentedDateProps) => {

  useEffect(() => {
    if (segmented === SegmentedDateEnum.CUSTOM) {
      handleCustomDate(true);
    } else {
      handleCustomDate(false);
    }
  }, [segmented, handleCustomDate]);

  return (
    <Segmented
      className={styles.segmented}
      defaultValue={segmented as SegmentedValue}
      options={segmentedOptions}
      onChange={handleSegmented}
    />
  );
};
