import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { axios } from 'config/axios';

export const useDeleteBusinessAction = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string | number) => {
      const response = await axios.delete(`/business/${id}`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['businesses'] });

      notification.success({
        message: 'Business deleted successfully',
        description: 'You have successfully deleted a business',
      });
    },
    onError: () => {
      notification.error({
        message: 'Error deleting business',
        description: 'There was an error deleting the business',
      });
    },
  });
};
