import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { ReactComponent as LogoText } from 'assets/images/fintegrity.svg';
import { ReactComponent as LogoIcon } from 'assets/images/Logo2.svg';
import styles from './Logo.module.scss';

export type LogoProps = unknown;

export const Logo = (): ReactElement => {
  return (
    <Link to="/">
      <Space className={styles.logoWrapper}>
        <LogoIcon />
        <LogoText />
      </Space>
    </Link>
  );
};
