import _ from 'lodash';
import qs from 'qs';
import { Sort } from '../types/Sort';

export type ParseSortFromQueryParamsProps<Field> = {
  location: Location;
  allowedFields: Field[];
};

const parseSortFromQueryParams = <Field extends string>({
  location,
  allowedFields,
}: ParseSortFromQueryParamsProps<Field>): Sort<Field> | null => {
  const { sort, order } = qs.parse(location.search, { ignoreQueryPrefix: true });

  if (
    _.isString(sort) && _.isString(order)
    && (order === 'descend' || order === 'ascend')
    && allowedFields.includes(sort as Field)
  ) {
    return { field: sort as Field, order };
  }

  return null;
};

export default parseSortFromQueryParams;
