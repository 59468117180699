import React from 'react';
import clsx from 'clsx';
import Error from './images/Error';
import NoBusiness from './images/NoBusiness';
import styles from './Empty.module.scss';

// type CompoundedComponent = React.FC<EmptyProps> & {
//   PRESENTED_IMAGE_DEFAULT: React.ReactNode;
//   PRESENTED_IMAGE_SIMPLE: React.ReactNode;
// };

const Empty = ({
  children,
  style,
  className,
  image = <Error />,
  imageStyle,
  title = 'Oops!',
  description = 'Something went wrong. Please try again or contact our Support Team',
}: {
  className?: string;
  title?: React.ReactNode;
  image?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  description?: React.ReactNode;
  imageStyle?: React.CSSProperties;
}) => {
  return (
    <div className={clsx(styles.wrapper, className)} style={style}>
      <div className={styles.image} style={imageStyle}>{image}</div>

      <h2>{title}</h2>
      <p>{description}</p>

      {children}
    </div>
  );
};

Empty.PRESENTED_IMAGE_DEFAULT = <Error />;
Empty.PRESENTED_IMAGE_BUSINESS = <NoBusiness />;

export default Empty;
