import { useQuery } from 'react-query';
import { axios } from 'config/axios';
import { ApiPath } from 'models/consts';
import { Filter, IGroup, IUser, Sort } from 'models/interfaces';

type GetGroupsParams = {
  filter: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset?: number };
  sorting?: (Sort<IUser>)[];
};

const getGroups = (params?: GetGroupsParams) => async () => {
  const response = await axios.get(ApiPath.GROUPS, { params });
  return (response.data || { totalCount: 0, nodes: [] });
};

const useGetGroups = (params?: GetGroupsParams) => {
  return useQuery<{ nodes: IGroup[]; totalCount: number }>({
    queryKey: ['groups', params],
    queryFn: getGroups(params),
  });
};

export default useGetGroups;
