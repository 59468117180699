import { ReactElement, useMemo } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import ReactSelect, { components } from 'react-select';
import { Form } from 'antd';
import { IBusinessPostResponse, UnknownType } from 'models';
import { useAuthorisationContext } from 'services';
import useGetUsers from 'services/api/users/useGetUsers';
import { generateDefaultOptions, generateSelectOptions } from 'utils';
import styles from './UserSelect.module.scss';

export const UserSelect = ({
  control,
  name,
  initialValues,
}: {
  control: Control<UnknownType, unknown>;
  name: string;
  initialValues: Partial<IBusinessPostResponse>;
}): ReactElement => {
  const { user: currentUser } = useAuthorisationContext();
  const { data, isLoading } = useGetUsers();
  const users = useMemo(() => data?.nodes || [], [data?.nodes]);
  const imRoot = useMemo(() => currentUser?.id === 1, [currentUser?.id]);

  const usersOptions: UnknownType = generateSelectOptions(users).map((option) => ({
    ...option,
    isFixed: !imRoot,
  }));

  const { userIds } = useWatch({ control });

  const defaultOptions = useMemo(() => {
    const selectedUsersIds = (initialValues?.users || []).map(
      (user: UnknownType) => user.id,
    );
    return generateDefaultOptions(usersOptions, selectedUsersIds);
  }, [initialValues?.users, usersOptions]);

  const newUsers = useMemo(() => {
    const defaultOptionsIds = defaultOptions.map((item) => item.value);
    const newUserIds: number[] = (userIds || []).map(
      (item: UnknownType) => item.value,
    );
    return newUserIds.filter((id) => !defaultOptionsIds.includes(id));
  }, [defaultOptions, userIds]);

  const MultiValueRemove = (selectProps: UnknownType) => {
    if (selectProps.data.isFixed) {
      return null;
    }
    return <components.MultiValueRemove {...selectProps} />;
  };

  return (
    <Form.Item className={styles.formItem} name={name} label="Users">
      {isLoading ? <div className="__sk_input" /> : (
        <Controller
          name="userIds"
          control={control}
          defaultValue={defaultOptions}
          render={({ field }) => (
            <ReactSelect
              {...field}
              isMulti
              isClearable={imRoot}
              escapeClearsValue={imRoot}
              backspaceRemovesValue={imRoot}
              options={usersOptions}
              components={{ MultiValueRemove }}
              formatOptionLabel={(option) => (
                <span
                  style={{
                    color:
                      defaultOptions.length > 0 && newUsers.includes(option.value)
                        ? '#008000'
                        : undefined,
                  }}
                >
                  {option.label}
                </span>
              )}
            />
          )}
        />
      )}
    </Form.Item>
  );
};
