import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { axios } from 'config/axios';
import { ApiPath } from 'models/consts';
import { UnknownType } from 'models/types';

export interface CreateUserBody {
  name: string;
  email: string;
  password: string;
  groupId: number;
  businessIds?: number[];
}

export type OptionsType = UseMutationOptions<UnknownType, AxiosError<{ message: string }>, CreateUserBody>;

const useCreateUser = (options?: OptionsType) => {
  return useMutation<UnknownType, AxiosError<{ message: string }>, CreateUserBody>({
    ...options,
    mutationFn: async (body: CreateUserBody) => {
      const { data } = await axios.post(ApiPath.USERS_CREATE, body);
      return data;
    },
  });
};

export default useCreateUser;
