export const CardTitles = {
  PROFIT: 'profit',
  INCOME: 'income',
  EXPENSES: 'expenses',
  TOTAL_VOLUME: 'total Volume',
  TRANSACTION_COUNT: 'transaction count',
  AVERAGE_TRANSACTION_TIME: 'average transaction time',
  AVERAGE_TRANSACTION_VOLUME: 'average transaction volume',
  TOTAL_INFLOW: 'total inflow',
  TOTAL_OUTFLOW: 'total outflow',
  CURRENCY_EXCHANGE_VOLUME: 'currency exchange volume',
};
