import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { axios } from 'config/axios';
import { UnknownType } from 'models/types';

export type UserUpdateBody = {
  email?: string;
  name?: string;
  isActive?: boolean;
  groupId?: number;
  businessIds?: number[];
};

export type UserUpdateVariables = UserUpdateBody & { id: number };

export type OptionsType = UseMutationOptions<UnknownType, AxiosError<{ message: string }>, UserUpdateVariables>;

const useUpdateUser = (options?: OptionsType) => {
  return useMutation<UnknownType, AxiosError<{ message: string }>, UserUpdateVariables>({
    ...options,
    mutationFn: async ({ id, ...body }: UserUpdateVariables) => {
      const response = await axios.put(`users/update/${id}`, body);
      return response.data;
    },
  });
};

export default useUpdateUser;
