import React, { ComponentType } from 'react';
import { UnknownType } from 'models';
import { useAuthorisationContext } from 'services';

export function withPermissions<T extends object>(
  WrappedComponent: ComponentType<T>, permissions: UnknownType = [],
) {
  return (props: T) => {
    const { checkPermissions } = useAuthorisationContext();

    if (!checkPermissions(permissions) && typeof WrappedComponent === 'function') {
      return null;
    }

    if (!checkPermissions(permissions)) {
      return <h1 style={{ padding: 24 }}>No access</h1>;
    }

    return <WrappedComponent {...props} />;
  };
}
