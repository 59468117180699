import React, { useMemo } from 'react';
import { Avatar, Button, Dropdown, MenuProps } from 'antd';
import { useAuthorisationContext } from 'services';
import styles from './UserCard.module.scss';

const UserCard = () => {
  const { logout, user } = useAuthorisationContext();

  const items: MenuProps['items'] = useMemo(() => [
    {
      key: '1',
      className: styles.dropdownItem,
      onClick: logout,
      label: 'Logout',
    },
  ], [logout]);

  if (!user) return null;

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      className={styles.userMenu}
      trigger={['click']}
    >
      <Button className={styles.container}>
        <Avatar size={26} className={styles.avatar} gap={7}>
          {(user.name && user.name[0]) || ''}
        </Avatar>

        <div className={styles.userInfo}>
          <p className={styles.name}>
            {user.name || 'Unknown user'}
          </p>
          <p className={styles.email}>
            {user.email || 'Unknown email'}
          </p>
        </div>
      </Button>
    </Dropdown>
  );
};

export default UserCard;
