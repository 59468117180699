export interface SelectOption {
  label: string;
  value: string | number;
}

export const generateSelectOptions = <T extends { id: number; name: string }, U>(
  items: T[],
  additionalFields?: U,
): SelectOption[] => {
  return items.map((item) => ({
    label: item.name,
    value: Number(item.id),
    ...additionalFields,
  }));
};
