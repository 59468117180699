import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PER_PAGE_ITEMS, UnknownType } from 'models';
import qs from 'qs';
import { useUpdateEffect } from 'hooks';
import { parsePaginationFromQueryParams } from './utils';
import { PerPageNumber } from 'components';

export type UseTableSortProps = {
  resetDependencies?: unknown[];
  allowedPerPageOptions?: PerPageNumber[];
  withoutURL?: boolean;
};

export type UseTablePaginationResult = {
  page: number;
  perPage: PerPageNumber;
  setPage: (value: number) => void;
  setPerPage: (value: PerPageNumber) => void;
};

const useTablePagination = ({
  resetDependencies = [],
  allowedPerPageOptions = PER_PAGE_ITEMS,
  withoutURL = false,
}: UseTableSortProps = {}): UseTablePaginationResult => {
  const navigate = useNavigate();
  const location: UnknownType = useLocation();
  const initialData = parsePaginationFromQueryParams({
    location,
    allowedPerPageOptions,
  });
  const [page, setPage] = useState(initialData.page || 1);
  const [perPage, setPerPage] = useState<PerPageNumber>(initialData.perPage || allowedPerPageOptions[0]);

  const setPageHandler = useCallback((value: number) => {
    setPage(value);

    if (!withoutURL) {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      navigate({
        pathname: location.pathname,
        search: qs.stringify({ ...queryParams, page: value }),
      }, { replace: true });
    }

  }, [withoutURL, location.search, location.pathname, navigate]);

  const setPerPageHandler = (value: PerPageNumber) => {
    setPerPage(value);

    if (!withoutURL) {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

      navigate({
        pathname: location.pathname,
        search: qs.stringify({ ...queryParams, perPage: value }),
      }, { replace: true });
    }
  };

  useUpdateEffect(() => {
    setPageHandler(1);
  }, [perPage, ...resetDependencies]);

  return {
    page,
    perPage,
    setPage: setPageHandler,
    setPerPage: setPerPageHandler,
  };
};

export default useTablePagination;
