import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';
import HeaderContext from './HeaderContext';

const HeaderProvider = ({ children }: PropsWithChildren) => {
  const [rendered, setRendered] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleSetContentRef = useCallback((node: HTMLDivElement | null) => {
    contentRef.current = node;
    if (!rendered) {
      setRendered(true);
    }
  }, [rendered]);

  return (
    <HeaderContext.Provider value={{
      contentRef,
      setContentRef: handleSetContentRef,
      rendered,
    }}>
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
