import React, { Dispatch, SetStateAction, useContext } from 'react';
import { AxiosError } from 'axios';
import { noop } from 'lodash';
import { IUser, UnknownType } from 'models';
import { StageEnum } from 'hooks/use2FA';

export type CheckPermissionsFunction = (permissions: string[]) => boolean;

export type LoginParams = {
  email: string,
  password: string,
  otpCode?: string,
};

export interface IAuthorisationContext {
  user: IUser | null,
  token: string | null;
  login: (data: LoginParams) => void;
  logout: () => void;
  loading: boolean;
  checkPermissions: CheckPermissionsFunction;
  twoFA: {
    qrcode: string | null;
    stage: StageEnum,
    prevStage: StageEnum,
    setPrevStage: Dispatch<SetStateAction<StageEnum>>,
    setStage: Dispatch<SetStateAction<StageEnum>>,
    onError: (error: AxiosError<UnknownType>, callback: () => void) => void;
    onSuccess: (response: UnknownType, callback: () => void) => void
  };
}

export const AuthorisationContext = React.createContext<IAuthorisationContext>({
  user: null,
  token: null,
  loading: false,
  login: () => null,
  logout: () => null,
  checkPermissions: () => false,
  twoFA: {
    qrcode: null,
    stage: StageEnum.DEFAULT,
    prevStage: StageEnum.DEFAULT,
    setStage: noop,
    setPrevStage: noop,
    onError: noop,
    onSuccess: noop,
  },
});

export const useAuthorisationContext = () => useContext(AuthorisationContext);
