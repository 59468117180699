import React, { MouseEvent, ReactElement, useState } from 'react';
import { Button, Divider, Modal, Space, Typography } from 'antd';
import { useDeleteBusinessAction } from './hooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './DeleteBusinessAction.module.scss';

export const DeleteBusinessAction = ({ id }: { id: string; }): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate } = useDeleteBusinessAction();

  const showModal = (e: MouseEvent) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleOk = () => {
    mutate(id, { onSuccess: () => setIsModalOpen(false) });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const modalHeader = (
    <Space style={{ gap: '16px' }}>
      <ExclamationCircleOutlined />
      <Typography.Text>Business Deletion</Typography.Text>
    </Space>
  );

  return (
    <>
      <span onClick={showModal}>Delete business</span>
      <Modal
        closable={false}
        className={styles.modal}
        title={modalHeader}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <Space className={styles.footerActions}>
            <Button
              className={styles.cancelButton}
              onClick={handleOk}
              size="large"
              type="text"
              danger
            >
              Delete
            </Button>
            <Button
              className={styles.submitButton}
              onClick={handleCancel}
              size="large"
            >
              Cancel
            </Button>
          </Space>
        }
      >
        <Space direction="vertical" style={{ gap: 0 }}>
          <Typography.Text className={styles.modalQuestion}>
            Are you sure you want to delete this business?
          </Typography.Text>
          <Divider style={{ background: '#6364FF1A', margin: '12px 0 4px' }} />
          <Typography.Text className={styles.modalDescription}>
            This action is irreversible, and all data associated with the
            business will be lost forever.
          </Typography.Text>
        </Space>
      </Modal>
    </>
  );
};
