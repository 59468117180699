import { isObject } from 'lodash';
import { countConvertedAmount } from './countConvertedAmount';

export const countTotal = <U extends object>(data: U) => {
  if (!isObject(data)) {
    return {};
  }

  return Object.values(data)
    .map((el) => countConvertedAmount(el))
    .reduce((acc, curr) => acc + curr, 0);
};
