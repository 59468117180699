import { useQuery } from 'react-query';
import { axios } from 'config';
import { ApiPath, IBusiness, IStat } from 'models';
import { LocalStorage, LocalStorageKey } from 'utils';

interface UseBusinessByIdReturn {
  business: IBusiness;
  stats: IStat[];
}

export type UseBusinessByIdProps = {
  id?: number;
  params?: string[]; // TODO: fix type
};

export const useBusinessByIdQuery = ({
  id,
  params,
}: UseBusinessByIdProps) => {
  const URL = `${process.env.REACT_APP_AUTH_URI}/${ApiPath.BUSINESSES}/${id}`;

  const getBusinessById = async (): Promise<UseBusinessByIdReturn> => {
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: 'Bearer ' + LocalStorage.get(LocalStorageKey.ACCESS_TOKEN),
      },
      params: {
        ...params,
      },
    });
    return data;
  };

  return useQuery({
    queryKey: `business-${id}-${JSON.stringify(params)}`,
    queryFn: getBusinessById,
    enabled: !!id,
  });
};
