import React from 'react';
import { LazyLog } from 'react-lazylog';

export type LogListProps = {
  logs: string;
};

export const LogList = ({ logs }: LogListProps) => {
  if (logs.length === 0) {
    return <span>Searching for logs...</span>;
  }

  return (
    <LazyLog
      text={logs}
      follow
      extraLines={1}
      containerStyle={{ background: 'black' }}
      selectableLines
      enableSearch
    />
  );
};
