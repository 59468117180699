import React, { ReactElement } from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';
import { TypeEnum } from 'models';
import { ReactComponent as WarningIcon } from 'assets/icons/types/warning.svg';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './TypeBadge.module.scss';

interface TypeObject {
  title: string;
  icon: ReactElement;
}

const Types: Record<string, TypeObject> = {
  warning: {
    title: 'Warning',
    icon: <WarningIcon />,
  },
  info: {
    title: 'Info',
    icon: <InfoCircleOutlined />,
  },
  error: {
    title: 'Error',
    icon: <InfoCircleOutlined />,
  },
};

export const TypeBadge = ({ type }: { type: TypeEnum; }): ReactElement => {
  const lowercaseType = type.toLowerCase().toLowerCase();
  const selectedType = Types[lowercaseType];

  if (!selectedType) {
    return <div>Invalid Type</div>;
  }

  return (
    <div className={clsx(styles.type, styles[lowercaseType])}>
      {selectedType.icon}
      <Typography.Text className={styles.title}>
        {selectedType.title}
      </Typography.Text>
    </div>
  );
};
