import { ReactElement } from 'react';
import ReactSelect from 'react-select';
import useGetGroups from 'services/api/groups/useGetGroups';
import { generateSelectOptions } from 'utils';
import { BusinessSelectProps } from '../BusinessSelect/BusinessSelect';
import { FormItem } from '../FormItem';
import styles from './RoleSelect.module.scss';

export const RoleSelect = (props: BusinessSelectProps): ReactElement => {
  const { data, isLoading } = useGetGroups();

  if (isLoading) return <div className="__sk_input"></div>;

  const options = data !== null ? generateSelectOptions(data!.nodes) : [];

  return (
    <FormItem control={props.control} name={props.name || 'groupId'} label="Role">
      <ReactSelect
        options={options}
        className={styles.select}
        {...props}
      />
    </FormItem>
  );
};
