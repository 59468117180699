import { StatusEnum } from 'models';

export const notifications = [
  {
    id: 85,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'error',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.CREATE_DEPOSIT,
  },
  {
    id: 86,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'info',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.CREATE_MARKET_ORDER,
  },
  {
    id: 87,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.CREATE_WITHDRAW,
  },
  {
    id: 88,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.DEPOSIT_SUCCESS,
  },
  {
    id: 89,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.ERR_INSUFFICIENT,
  },
  {
    id: 90,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.ERR_MARKET_ORDER,
  },
  {
    id: 91,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.FINISHED,
  },
  {
    id: 92,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.MARKET_ORDER_SUCCESS,
  },
  {
    id: 93,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.SENT,
  },
  {
    id: 94,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.UNEXPECTED_EXCHANGE_ERR,
  },
  {
    id: 95,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.WITHDRAWAL_ERROR,
  },
  {
    id: 96,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.FINISHED,
  },
  {
    id: 97,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.FINISHED,
  },
  {
    id: 98,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.FINISHED,
  },
  {
    id: 99,
    topic: 'Lorem ipsum dolor sit',
    message:
      'Lorem ipsum dolor sit amet consectetur. Enim ut imperdiet risus lectus lorem consectetur odio viverra. At aliquet arcu in gravida.',
    audience: 'all',
    type: 'warning',
    dateTime: '2024.01.23 16:45:03',
    status: StatusEnum.FINISHED,
  },
];

export const users = [
  {
    id: 85,
    clientId: 345678,
    name: 'PL-3ffa4863-5b3b-4f3b-8063-eb60d41d09b7',
    email: 'jermain.kassulke19@yahoo.com',
    phone: '+380937777777',
    role: 'employee',
    dateTime: '2024.01.23 16:45:03',
  },
  {
    id: 85,
    clientId: 345678,
    name: 'PL-3ffa4863-5b3b-4f3b-8063-eb60d41d09b7',
    email: 'jermain.kassulke19@yahoo.com',
    phone: '+380937777777',
    role: 'employee',
    dateTime: '2024.01.23 16:45:03',
  },
  {
    id: 85,
    clientId: 345678,
    name: 'PL-3ffa4863-5b3b-4f3b-8063-eb60d41d09b7',
    email: 'jermain.kassulke19@yahoo.com',
    phone: '+380937777777',
    role: 'employee',
    dateTime: '2024.01.23 16:45:03',
  },
  {
    id: 85,
    clientId: 345678,
    name: 'PL-3ffa4863-5b3b-4f3b-8063-eb60d41d09b7',
    email: 'jermain.kassulke19@yahoo.com',
    phone: '+380937777777',
    role: 'employee',
    dateTime: '2024.01.23 16:45:03',
  },
  {
    id: 85,
    clientId: 345678,
    name: 'PL-3ffa4863-5b3b-4f3b-8063-eb60d41d09b7',
    email: 'jermain.kassulke19@yahoo.com',
    phone: '+380937777777',
    role: 'employee',
    dateTime: '2024.01.23 16:45:03',
  },
];

export const roles = [
  {
    creator: 'Super Admin',
    id: 85,
    email: 'jermain.kassulke19@yahoo.com',
    name: 'admin holding',
    created: '2024.01.23 16:45:03',
    updated: '2024.01.28 10:15:00',
    permissions: 'hmm',
    status: StatusEnum.ACTIVE,
  },
  {
    creator: 'Super Admin',
    id: 85,
    email: 'jermain.kassulke19@yahoo.com',
    name: 'admin holding',
    created: '2024.01.23 16:45:03',
    updated: '2024.01.28 10:15:00',
    permissions: 'hmm',
    status: StatusEnum.ACTIVE,
  },
  {
    creator: 'Super Admin',
    id: 85,
    email: 'jermain.kassulke19@yahoo.com',
    name: 'admin holding',
    created: '2024.01.23 16:45:03',
    updated: '2024.01.28 10:15:00',
    permissions: 'hmm',
    status: StatusEnum.ACTIVE,
  },
  {
    creator: 'Super Admin',
    id: 85,
    email: 'jermain.kassulke19@yahoo.com',
    name: 'admin holding',
    created: '2024.01.23 16:45:03',
    updated: '2024.01.28 10:15:00',
    permissions: 'hmm',
    status: StatusEnum.ACTIVE,
  },
];

export const exchangeHistoryData = {
  'nodes': [
    {
      'id': 166,
      'currencyFrom': 'eth',
      'networkFrom': 'erc20',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '0.016',
      'depositAddress': '0xCDEC363d3d0E1672971ea90b53f0aE44347D27EF',
      'receivedAmount': '40.120108896000005',
      'addressTo': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
      'rate': '2508.75',
      'actualRate': '2507.5068060000003',
      'income': '0.40120108896000006',
      'source': null,
      'createdAt': '2024-02-11T15:32:52.195Z',
      'updatedAt': '2024-02-11T15:47:30.236Z',
      'transactions': [
        {
          'id': 223,
          'currency': 'ETH',
          'network': 'ERC20',
          'amount': '0.016',
          'fee': '0',
          'txId': '0x930afaba62dd33396e5223d50868daf026e4cfa04ca2ae3458caf18994a5635c',
          'address': '0xCDEC363d3d0E1672971ea90b53f0aE44347D27EF',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '1a2d72eb-2499-4141-b540-05c896bae99b',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 166,
          'createdAt': '2024-02-11T15:34:00.273Z',
          'updatedAt': '2024-02-11T15:40:07.251Z',
        },
        {
          'id': 225,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '38.718907807040004',
          'fee': '1.4012010889600002',
          'txId': 'cc013f028086dac9eae45d651c6723b0b37c0add7131dab6c97fbe1f14bbfaf1',
          'address': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': 'a0facdd8-815b-47a1-8b48-65a827306d7b',
          'errorText': null,
          'exchangeId': 166,
          'createdAt': '2024-02-11T15:40:07.244Z',
          'updatedAt': '2024-02-11T15:47:30.227Z',
        },
      ],
    },
    {
      'id': 165,
      'currencyFrom': 'eth',
      'networkFrom': 'erc20',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '0.016',
      'depositAddress': '0xC8c9BDC829CC804a7F15b981561ea49142febB1a',
      'receivedAmount': '40.0779048',
      'addressTo': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
      'rate': '2508.75',
      'actualRate': '2504.86905',
      'income': '0.40077904799999997',
      'source': null,
      'createdAt': '2024-02-11T15:29:58.458Z',
      'updatedAt': '2024-02-11T15:41:30.189Z',
      'transactions': [
        {
          'id': 224,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '38.677125752',
          'fee': '1.400779048',
          'txId': '9b7afbcd45c454a3c9d25cf3a8bb18ec29279dcdeaccaa21113322fa6530daba',
          'address': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': 'a95bfd18-557f-442a-af57-55895e4ce137',
          'errorText': null,
          'exchangeId': 165,
          'createdAt': '2024-02-11T15:37:07.452Z',
          'updatedAt': '2024-02-11T15:41:30.185Z',
        },
        {
          'id': 222,
          'currency': 'ETH',
          'network': 'ERC20',
          'amount': '0.016',
          'fee': '0',
          'txId': '0x9c7e9e6e6ac400380b08658b40d1ee158421144e7b7e4157de5f9c143dc8f3c4',
          'address': '0xC8c9BDC829CC804a7F15b981561ea49142febB1a',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': 'a81a18a5-1daf-4f33-ab9d-f1bf6f6a3716',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 165,
          'createdAt': '2024-02-11T15:31:00.283Z',
          'updatedAt': '2024-02-11T15:37:07.458Z',
        },
      ],
    },
    {
      'id': 164,
      'currencyFrom': 'BTC',
      'networkFrom': 'BTC',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '0.001',
      'depositAddress': 'bc1qukus0ehpfxl7hddnxzvj5fdgtn26fy9qk3wvzy',
      'receivedAmount': '48.0870242469585',
      'addressTo': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
      'rate': '48050.75',
      'actualRate': '48087.0242469585',
      'income': '0.480870242469585',
      'source': null,
      'createdAt': '2024-02-11T15:28:56.882Z',
      'updatedAt': '2024-02-11T15:57:30.237Z',
      'transactions': [
        {
          'id': 228,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '46.606154004488914',
          'fee': '1.480870242469585',
          'txId': '46c6c964788fc4f425ca135cc0d8121cf32dff71267ef733f6c316b25a76b2da',
          'address': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': '3069762a-60aa-4d8f-816a-c50eebd542d8',
          'errorText': null,
          'exchangeId': 164,
          'createdAt': '2024-02-11T15:54:07.242Z',
          'updatedAt': '2024-02-11T15:57:30.234Z',
        },
        {
          'id': 220,
          'currency': 'BTC',
          'network': null,
          'amount': '0.001',
          'fee': '0',
          'txId': 'c640a01bdc1f86a24809cced6166faa0467d57d561dc9e5154a1bff6bc5affb3',
          'address': 'bc1qukus0ehpfxl7hddnxzvj5fdgtn26fy9qk3wvzy',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '5a26435f-49de-4305-befd-60ac1fe11315',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 164,
          'createdAt': '2024-02-11T15:30:00.230Z',
          'updatedAt': '2024-02-11T15:54:07.253Z',
        },
      ],
    },
    {
      'id': 163,
      'currencyFrom': 'BTC',
      'networkFrom': 'BTC',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '0.001',
      'depositAddress': 'bc1qmqedc92tyhvtm8fqtg5wzvj3pcvvvcjvksr3f5',
      'receivedAmount': '48.082615008',
      'addressTo': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
      'rate': '48046.78',
      'actualRate': '48082.61500799999',
      'income': '0.48082615007999996',
      'source': null,
      'createdAt': '2024-02-11T15:28:18.100Z',
      'updatedAt': '2024-02-11T15:57:30.210Z',
      'transactions': [
        {
          'id': 229,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '46.60178885792',
          'fee': '1.48082615008',
          'txId': 'f199888b92f751b8f9f48e1ce70917ce07c38820c0e3bc22900439648dd55560',
          'address': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': '9e534ef5-3494-4e2e-88af-72a7a0ba9a83',
          'errorText': null,
          'exchangeId': 163,
          'createdAt': '2024-02-11T15:54:14.196Z',
          'updatedAt': '2024-02-11T15:57:30.206Z',
        },
        {
          'id': 221,
          'currency': 'BTC',
          'network': null,
          'amount': '0.001',
          'fee': '0',
          'txId': '8612e02eb4a219bd25647d490f24baea34706103d4b1f2f49665071d73acf614',
          'address': 'bc1qmqedc92tyhvtm8fqtg5wzvj3pcvvvcjvksr3f5',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '08646b0e-017a-4ff4-b823-904bf331c128',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 163,
          'createdAt': '2024-02-11T15:30:00.303Z',
          'updatedAt': '2024-02-11T15:54:14.201Z',
        },
      ],
    },
    {
      'id': 162,
      'currencyFrom': 'BTC',
      'networkFrom': 'BTC',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '0.001',
      'depositAddress': 'bc1q2q3x54kye96gq2hp6hjj0u3ypdzpppqfxgu5tz',
      'receivedAmount': '48.0773295045',
      'addressTo': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
      'rate': '48041.5',
      'actualRate': '48077.3295045',
      'income': '0.480773295045',
      'source': null,
      'createdAt': '2024-02-11T15:27:36.051Z',
      'updatedAt': '2024-02-11T15:50:30.233Z',
      'transactions': [
        {
          'id': 219,
          'currency': 'BTC',
          'network': null,
          'amount': '0.001',
          'fee': '0',
          'txId': '15675a1a276fae87e3921018d7112c1eecafb72e515995386c8b19f933af2211',
          'address': 'bc1q2q3x54kye96gq2hp6hjj0u3ypdzpppqfxgu5tz',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '350f5434-17c6-40dc-8c6e-2e6a807474d7',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 162,
          'createdAt': '2024-02-11T15:29:00.146Z',
          'updatedAt': '2024-02-11T15:43:07.309Z',
        },
        {
          'id': 226,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '46.596556209455',
          'fee': '1.480773295045',
          'txId': '5347e56a961a354ad3f10a226974b896cd136b5009ddb3a06608edbe06ffbc67',
          'address': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': '613421c7-103c-4bf0-94b5-7e2425892000',
          'errorText': null,
          'exchangeId': 162,
          'createdAt': '2024-02-11T15:43:07.304Z',
          'updatedAt': '2024-02-11T15:50:30.229Z',
        },
      ],
    },
    {
      'id': 161,
      'currencyFrom': 'BTC',
      'networkFrom': 'BTC',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '0.001',
      'depositAddress': 'bc1qkrnq8tp2m93zprt5da0nzjlgv55959569ujf6l',
      'receivedAmount': '48.077339496',
      'addressTo': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
      'rate': '48041.06',
      'actualRate': '48077.339496',
      'income': '0.48077339496',
      'source': null,
      'createdAt': '2024-02-11T15:26:37.377Z',
      'updatedAt': '2024-02-11T15:50:30.209Z',
      'transactions': [
        {
          'id': 218,
          'currency': 'BTC',
          'network': null,
          'amount': '0.001',
          'fee': '0',
          'txId': '34b336371e75510d66b1a67b5960fdb4d87e0cce5cc8cb0ee3ec73e48ca829a6',
          'address': 'bc1qkrnq8tp2m93zprt5da0nzjlgv55959569ujf6l',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '68fb8192-f262-48a6-8663-f2970aa7dfc3',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 161,
          'createdAt': '2024-02-11T15:28:00.229Z',
          'updatedAt': '2024-02-11T15:43:14.325Z',
        },
        {
          'id': 227,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '46.59656610104',
          'fee': '1.48077339496',
          'txId': 'a1046a3055c9cd230a58c9796eda1709301953ede2a20234a06a02ba688c522f',
          'address': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': 'a12c2097-ea4a-40cd-b635-e88a87d50d82',
          'errorText': null,
          'exchangeId': 161,
          'createdAt': '2024-02-11T15:43:14.319Z',
          'updatedAt': '2024-02-11T15:50:30.198Z',
        },
      ],
    },
    {
      'id': 160,
      'currencyFrom': 'BTC',
      'networkFrom': 'BTC',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Pending',
      'depositAmount': '0.001',
      'depositAddress': 'bc1qht5pwevc38cfxrxqwqltgsrzfd9m9jlxfvm0cu',
      'receivedAmount': null,
      'addressTo': 'TKrcU2NnCYeroGtdKJx9Sq7yijHTTW6Jmt',
      'rate': '48067.94',
      'actualRate': null,
      'income': null,
      'source': null,
      'createdAt': '2024-02-11T15:25:05.778Z',
      'updatedAt': '2024-02-11T15:25:05.778Z',
      'transactions': [],
    },
    {
      'id': 48,
      'currencyFrom': 'ETH',
      'networkFrom': 'ERC20',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '0.1',
      'depositAddress': '0x486c5c7a8fb8CeCE3a38486C54674855F3128d99',
      'receivedAmount': '223.97080499999998',
      'addressTo': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
      'rate': '2245.3',
      'actualRate': '2239.7080499999997',
      'income': '2.23970805',
      'source': null,
      'createdAt': '2024-01-26T13:42:26.555Z',
      'updatedAt': '2024-01-26T13:52:00.330Z',
      'transactions': [
        {
          'id': 40,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '220.73109695',
          'fee': '3.23970805',
          'txId': 'a61f965ffd0a274446c4d6352c64b1bbb98bfce4d5b9f67c0248a5209f72892a',
          'address': 'TKutD79p2fEwjxZeDDNat3dyG2d8WbbGgZ',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': '4b4c2ce7-14e4-4fc9-a278-173d7334e55d',
          'errorText': null,
          'exchangeId': 48,
          'createdAt': '2024-01-26T13:49:37.240Z',
          'updatedAt': '2024-01-26T13:52:00.314Z',
        },
        {
          'id': 39,
          'currency': 'ETH',
          'network': 'ERC20',
          'amount': '0.1',
          'fee': '0',
          'txId': '0x16584ef02355a7c68be587162c4069632571e642b71609ef5b895cd63fb417ac',
          'address': '0x486c5c7a8fb8CeCE3a38486C54674855F3128d99',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '8489356f-08f5-4b09-9724-5f2c330792b5',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 48,
          'createdAt': '2024-01-26T13:43:30.302Z',
          'updatedAt': '2024-01-26T13:49:37.247Z',
        },
      ],
    },
    {
      'id': 47,
      'currencyFrom': 'BTC',
      'networkFrom': 'Bitcoin',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '0.00475',
      'depositAddress': 'bc1qfs63wvazyfqkwpfpnmg8egtc3kt3edcmep87nl',
      'receivedAmount': '195.5009308725',
      'addressTo': 'TKrcU2NnCYeroGtdKJx9Sq7yijHTTW6Jmt',
      'rate': '41154.79',
      'actualRate': '41158.09071',
      'income': '1.955009308725',
      'source': null,
      'createdAt': '2024-01-26T13:00:26.715Z',
      'updatedAt': '2024-01-26T13:15:30.168Z',
      'transactions': [
        {
          'id': 38,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '192.54592156377498',
          'fee': '2.955009308725',
          'txId': '9c9d59dd44d562d60609d28bbe4d43ab36cac311af5852ecc6c15be95e282de9',
          'address': 'TKrcU2NnCYeroGtdKJx9Sq7yijHTTW6Jmt',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': '80cd4850-c94b-4d22-a152-1b07902661d2',
          'errorText': null,
          'exchangeId': 47,
          'createdAt': '2024-01-26T13:14:07.335Z',
          'updatedAt': '2024-01-26T13:15:30.164Z',
        },
        {
          'id': 37,
          'currency': 'BTC',
          'network': null,
          'amount': '0.00475',
          'fee': '0',
          'txId': '6d306364c2ad38ee40407ef978344084d39faa443a6b39898909f884ba090521',
          'address': 'bc1qfs63wvazyfqkwpfpnmg8egtc3kt3edcmep87nl',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': 'b205cdff-14b9-41be-b071-32bfb5dbb339',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 47,
          'createdAt': '2024-01-26T13:01:00.275Z',
          'updatedAt': '2024-01-26T13:14:07.347Z',
        },
      ],
    },
    {
      'id': 46,
      'currencyFrom': 'USDT',
      'networkFrom': 'ERC20',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '200',
      'depositAddress': '0xEA5ED7fA3A415bcF66271EdEECea0983Ad0C2279',
      'receivedAmount': '198',
      'addressTo': 'TYtiJfdhMG4dMvVi8qKGZSy8ERnA8B2nXL',
      'rate': '1',
      'actualRate': '0.99',
      'income': '2',
      'source': null,
      'createdAt': '2024-01-25T13:55:51.638Z',
      'updatedAt': '2024-01-25T14:09:30.159Z',
      'transactions': [
        {
          'id': 35,
          'currency': 'USDT',
          'network': 'ERC20',
          'amount': '200',
          'fee': '0',
          'txId': '0xbfd09567d5def4dcb37e9a7c6d1d2d396348da000e393c27597151324db5fa09',
          'address': '0xEA5ED7fA3A415bcF66271EdEECea0983Ad0C2279',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '8894176a-0e90-4f3b-8b24-bd74bf5f7cfb',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 46,
          'createdAt': '2024-01-25T13:56:30.154Z',
          'updatedAt': '2024-01-25T14:03:02.584Z',
        },
        {
          'id': 36,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '197',
          'fee': '3',
          'txId': '9277a2dcf564484f6112a5485a2d7c388cffddcc3a754cbf260d730f108e01d5',
          'address': 'TYtiJfdhMG4dMvVi8qKGZSy8ERnA8B2nXL',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': '33a17876-7b55-4218-b192-476f687e6b74',
          'errorText': null,
          'exchangeId': 46,
          'createdAt': '2024-01-25T14:03:02.572Z',
          'updatedAt': '2024-01-25T14:09:30.152Z',
        },
      ],
    },
    {
      'id': 45,
      'currencyFrom': 'USDT',
      'networkFrom': 'TRC20',
      'currencyTo': 'USDT',
      'networkTo': 'ERC20',
      'status': 'Completed',
      'depositAmount': '200',
      'depositAddress': 'TYmeFPvTmK3Mmspaps7FBMKvGyxtYDqS6v',
      'receivedAmount': '198',
      'addressTo': '0xb7Bc1eb260f8A000f3e102AaC4B2623F402CA583',
      'rate': '1',
      'actualRate': '0.99',
      'income': '2',
      'source': null,
      'createdAt': '2024-01-25T13:23:36.888Z',
      'updatedAt': '2024-01-25T13:40:00.182Z',
      'transactions': [
        {
          'id': 34,
          'currency': 'USDT',
          'network': 'ERC20',
          'amount': '185.13',
          'fee': '14.87',
          'txId': '0x3332d0cb5b2358eb5b6783d1d7d06c1ef30cc420fbe88f80a65ffb52aa08b66f',
          'address': '0xb7Bc1eb260f8A000f3e102AaC4B2623F402CA583',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': 'd2ee0a0f-fcc4-4e9c-a421-bbdd4b8f7be9',
          'errorText': null,
          'exchangeId': 45,
          'createdAt': '2024-01-25T13:28:32.429Z',
          'updatedAt': '2024-01-25T13:40:00.171Z',
        },
        {
          'id': 33,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '200',
          'fee': '0',
          'txId': '8cf2be76a30563bd62a219bfb73ff6eb32bfaab8821a031a69f930056ea39d3d',
          'address': 'TYmeFPvTmK3Mmspaps7FBMKvGyxtYDqS6v',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': 'dc95d6bb-6e0e-4dcf-bada-c4236608c3cd',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 45,
          'createdAt': '2024-01-25T13:25:00.154Z',
          'updatedAt': '2024-01-25T13:28:32.433Z',
        },
      ],
    },
    {
      'id': 44,
      'currencyFrom': 'TRX',
      'networkFrom': 'TRC20',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '2000.9',
      'depositAddress': 'TS9ZjyqPWr6wHm595wZbKMZzrdhYBtRkhM',
      'receivedAmount': '222.40542932262',
      'addressTo': 'TYtiJfdhMG4dMvVi8qKGZSy8ERnA8B2nXL',
      'rate': '0.111259',
      'actualRate': '0.11115269594813333',
      'income': '2.2240542932262',
      'source': null,
      'createdAt': '2024-01-25T10:49:26.049Z',
      'updatedAt': '2024-01-25T10:59:30.127Z',
      'transactions': [
        {
          'id': 32,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '219.1813750293938',
          'fee': '3.2240542932262',
          'txId': '7083ae9c45cc69f5dd2610227074d0e5a0236ab5f2f6a4e0a12da523c63dd5bd',
          'address': 'TYtiJfdhMG4dMvVi8qKGZSy8ERnA8B2nXL',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': '1cb501ca-bac3-4aec-9d8a-ae9311921ea5',
          'errorText': null,
          'exchangeId': 44,
          'createdAt': '2024-01-25T10:52:07.395Z',
          'updatedAt': '2024-01-25T10:59:30.124Z',
        },
        {
          'id': 31,
          'currency': 'TRX',
          'network': null,
          'amount': '2000.9',
          'fee': '0',
          'txId': '30d7134262f0bd3fa2d9f4bf4c5e056877e87079e3478abb5575e38078b87166',
          'address': 'TS9ZjyqPWr6wHm595wZbKMZzrdhYBtRkhM',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '307430a1-3cfb-46db-8a5e-2367427fcd3b',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 44,
          'createdAt': '2024-01-25T10:51:00.258Z',
          'updatedAt': '2024-01-25T10:52:07.399Z',
        },
      ],
    },
    {
      'id': 43,
      'currencyFrom': 'USDT',
      'networkFrom': 'TRC20',
      'currencyTo': 'USDT',
      'networkTo': 'ERC20',
      'status': 'Completed',
      'depositAmount': '220',
      'depositAddress': 'TA3rPekfZP8b4vDsXxJoYtuDtBjeeLzJHK',
      'receivedAmount': '217.8',
      'addressTo': '0xb7Bc1eb260f8A000f3e102AaC4B2623F402CA583',
      'rate': '1',
      'actualRate': '0.9900000000000001',
      'income': '2.2',
      'source': null,
      'createdAt': '2024-01-25T08:37:34.492Z',
      'updatedAt': '2024-01-25T08:46:30.164Z',
      'transactions': [
        {
          'id': 29,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '220',
          'fee': '0',
          'txId': 'c872928893e95c51a4918e09880c2c6f1e2d298febe271d8b09b725a356e92a8',
          'address': 'TA3rPekfZP8b4vDsXxJoYtuDtBjeeLzJHK',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '333f29f6-a864-462d-b881-663857343f6b',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 43,
          'createdAt': '2024-01-25T08:39:30.132Z',
          'updatedAt': '2024-01-25T08:41:32.447Z',
        },
        {
          'id': 30,
          'currency': 'USDT',
          'network': 'ERC20',
          'amount': '211.96',
          'fee': '8.04',
          'txId': '0x2c769c8dacc8b409905109ffbaaa4a119a213b824e6937601e9be0470085f479',
          'address': '0xb7Bc1eb260f8A000f3e102AaC4B2623F402CA583',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': 'cbfc553b-cd4d-491d-abd6-ded7b89d878e',
          'errorText': null,
          'exchangeId': 43,
          'createdAt': '2024-01-25T08:41:32.442Z',
          'updatedAt': '2024-01-25T08:46:30.160Z',
        },
      ],
    },
    {
      'id': 42,
      'currencyFrom': 'ETH',
      'networkFrom': 'ERC20',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Pending',
      'depositAmount': '0.007',
      'depositAddress': '0xEE98d64B0E2Ab9fe81b661E787508d22Ef6EF568',
      'receivedAmount': null,
      'addressTo': 'TRahzQUumvyBemLtfhiN4KHudPLSspWjkT',
      'rate': '2550.39',
      'actualRate': null,
      'income': null,
      'source': null,
      'createdAt': '2024-01-17T14:26:43.686Z',
      'updatedAt': '2024-01-17T14:26:43.686Z',
      'transactions': [],
    },
    {
      'id': 41,
      'currencyFrom': 'USDT',
      'networkFrom': 'TRC20',
      'currencyTo': 'USDT',
      'networkTo': 'ERC20',
      'status': 'Completed',
      'depositAmount': '30',
      'depositAddress': 'TDn8vqjn1s11swwJQ4QfWsVTQnP2kjZWqf',
      'receivedAmount': '29.7',
      'addressTo': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
      'rate': '1',
      'actualRate': '0.99',
      'income': '0.3',
      'source': null,
      'createdAt': '2024-01-16T14:19:31.438Z',
      'updatedAt': '2024-01-16T14:29:30.163Z',
      'transactions': [
        {
          'id': 28,
          'currency': 'USDT',
          'network': 'ERC20',
          'amount': '9.23',
          'fee': '20.77',
          'txId': '0x9e4b8d06793d9419b80d22ffba3ca9bb0307f5572c4172fc2014ac4120c63871',
          'address': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': 'de47e167-16ec-4f82-b473-866a4fb2a9d5',
          'errorText': null,
          'exchangeId': 41,
          'createdAt': '2024-01-16T14:23:32.500Z',
          'updatedAt': '2024-01-16T14:29:30.157Z',
        },
        {
          'id': 27,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '30',
          'fee': '0',
          'txId': '48e97fad72643261e3fd5bb00593de48988bb949cce7200f111897cea369d7c6',
          'address': 'TDn8vqjn1s11swwJQ4QfWsVTQnP2kjZWqf',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': 'ba692c4c-917a-4931-b627-9819cdaa6b70',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 41,
          'createdAt': '2024-01-16T14:22:30.177Z',
          'updatedAt': '2024-01-16T14:23:32.506Z',
        },
      ],
    },
    {
      'id': 40,
      'currencyFrom': 'USDT',
      'networkFrom': 'TRC20',
      'currencyTo': 'USDT',
      'networkTo': 'ERC20',
      'status': 'Completed',
      'depositAmount': '30',
      'depositAddress': 'TJH35JpbmsFHYLAJR34dt6ZELsdUqfmadH',
      'receivedAmount': '29.7',
      'addressTo': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
      'rate': '1',
      'actualRate': '0.99',
      'income': '0.3',
      'source': null,
      'createdAt': '2024-01-16T13:35:34.301Z',
      'updatedAt': '2024-01-16T14:15:00.303Z',
      'transactions': [
        {
          'id': 24,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '30',
          'fee': '0',
          'txId': 'e31a07113fbae5cde57a345476f45ce553f8f206ba449ab41b7ca3e192c7d7bb',
          'address': 'TJH35JpbmsFHYLAJR34dt6ZELsdUqfmadH',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '8009b286-9481-46db-9c99-ac77214a3434',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 40,
          'createdAt': '2024-01-16T13:38:00.285Z',
          'updatedAt': '2024-01-16T13:39:32.514Z',
        },
        {
          'id': 25,
          'currency': 'USDT',
          'network': 'ERC20',
          'amount': '13.01',
          'fee': '16.990000000000002',
          'txId': '0x159c0cb217fc379e9dc2674a986dcf307adccea2cfedf779c9b137b5c5cc4660',
          'address': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': 'a616a366-0339-422d-8c5a-d04e114abf30',
          'errorText': null,
          'exchangeId': 40,
          'createdAt': '2024-01-16T13:39:32.509Z',
          'updatedAt': '2024-01-16T14:15:00.294Z',
        },
      ],
    },
    {
      'id': 39,
      'currencyFrom': 'USDT',
      'networkFrom': 'TRC20',
      'currencyTo': 'USDT',
      'networkTo': 'ERC20',
      'status': 'Completed',
      'depositAmount': '31',
      'depositAddress': 'TBNdRA4UmsczApyZpVnSWdovCMqSRPYSR3',
      'receivedAmount': '30.69',
      'addressTo': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
      'rate': '1',
      'actualRate': '0.99',
      'income': '0.31',
      'source': null,
      'createdAt': '2024-01-16T13:34:55.831Z',
      'updatedAt': '2024-01-16T14:15:00.279Z',
      'transactions': [
        {
          'id': 26,
          'currency': 'USDT',
          'network': 'ERC20',
          'amount': '14',
          'fee': '17',
          'txId': '0x6f51792da481a5e2d958f0128458a5be7b6c9484636f3e7bbb59bf558575b6b9',
          'address': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': 'e87a7e5c-aa37-4674-911a-04b29672317f',
          'errorText': null,
          'exchangeId': 39,
          'createdAt': '2024-01-16T13:39:34.814Z',
          'updatedAt': '2024-01-16T14:15:00.257Z',
        },
        {
          'id': 23,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '31',
          'fee': '0',
          'txId': '70cf5b6b691f0c0c0fb1ff793fb4f8caf3e68a75e6f04a0afdea5901571267a8',
          'address': 'TBNdRA4UmsczApyZpVnSWdovCMqSRPYSR3',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '00b0b155-3fd5-4826-b319-223e445c0dcf',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 39,
          'createdAt': '2024-01-16T13:37:30.188Z',
          'updatedAt': '2024-01-16T13:39:34.821Z',
        },
      ],
    },
    {
      'id': 38,
      'currencyFrom': 'USDT',
      'networkFrom': 'ERC20',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Completed',
      'depositAmount': '34',
      'depositAddress': '0x6587358b9B06a30CD8cdD2Cc83b454b6ead3B2d0',
      'receivedAmount': '33.66',
      'addressTo': 'TNNBG27AGzwudS4wCQNLWodogqHhXv2N1r',
      'rate': '1',
      'actualRate': '0.9899999999999999',
      'income': '0.34',
      'source': null,
      'createdAt': '2024-01-16T13:02:29.716Z',
      'updatedAt': '2024-01-16T14:15:00.341Z',
      'transactions': [
        {
          'id': 22,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '32.66',
          'fee': '1.34',
          'txId': '24f9c407788df11f20f2f705e7b00d14e189b43a1b0df92186d4f204553749b7',
          'address': 'TNNBG27AGzwudS4wCQNLWodogqHhXv2N1r',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': '3890808b-a0ff-4ad8-a198-f82432af24e8',
          'errorText': null,
          'exchangeId': 38,
          'createdAt': '2024-01-16T13:10:02.555Z',
          'updatedAt': '2024-01-16T14:15:00.331Z',
        },
        {
          'id': 21,
          'currency': 'USDT',
          'network': 'ERC20',
          'amount': '34',
          'fee': '0',
          'txId': '0x146c4c6455f87a27274d6a375af5e503a5d067c3c1b4e7e4c8fc04ebf0fa9d92',
          'address': '0x6587358b9B06a30CD8cdD2Cc83b454b6ead3B2d0',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '9cfb280b-15e3-4d16-b84e-a71d3dfcc915',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 38,
          'createdAt': '2024-01-16T13:04:00.253Z',
          'updatedAt': '2024-01-16T13:10:02.563Z',
        },
      ],
    },
    {
      'id': 37,
      'currencyFrom': 'USDT',
      'networkFrom': 'TRC20',
      'currencyTo': 'USDT',
      'networkTo': 'ERC20',
      'status': 'Completed',
      'depositAmount': '30',
      'depositAddress': 'TZBfFLtE4iwB1PXFnMfgtvyFqCTSzJZXXi',
      'receivedAmount': '29.7',
      'addressTo': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
      'rate': '1',
      'actualRate': '0.99',
      'income': '14.010000000000002',
      'source': null,
      'createdAt': '2024-01-16T11:08:16.287Z',
      'updatedAt': '2024-01-16T11:14:00.159Z',
      'transactions': [
        {
          'id': 20,
          'currency': 'USDT',
          'network': 'ERC20',
          'amount': '15.99',
          'fee': '13.71',
          'txId': '0x7fb5d3db53d7f5ad49cce3640ccdfcb0608efa16733c37155d095c7ef28484e2',
          'address': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
          'type': 'Withdraw',
          'status': 'Finished',
          'transactionId': null,
          'uniqueId': '1359ad06-f3e8-4735-95f6-073cb608de22',
          'errorText': null,
          'exchangeId': 37,
          'createdAt': '2024-01-16T11:12:32.539Z',
          'updatedAt': '2024-01-16T11:14:00.152Z',
        },
        {
          'id': 19,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '30',
          'fee': '0',
          'txId': 'e4b9b0f0f7d2e0718b7009e1bc9b8a4488a461f5fd1d664b6a45445c6f751612',
          'address': 'TZBfFLtE4iwB1PXFnMfgtvyFqCTSzJZXXi',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': 'f01ed187-6ed9-48f7-95ab-49598345bb50',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 37,
          'createdAt': '2024-01-16T11:11:00.301Z',
          'updatedAt': '2024-01-16T11:12:32.569Z',
        },
      ],
    },
    {
      'id': 36,
      'currencyFrom': 'USDT',
      'networkFrom': 'TRC20',
      'currencyTo': 'USDT',
      'networkTo': 'ERC20',
      'status': 'Transferring',
      'depositAmount': '30',
      'depositAddress': 'TQkTWFgowpqdHLtqQ6jiWgpBq1PqAkUw7U',
      'receivedAmount': null,
      'addressTo': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
      'rate': '1',
      'actualRate': null,
      'income': null,
      'source': null,
      'createdAt': '2024-01-16T10:49:28.620Z',
      'updatedAt': '2024-01-16T10:53:32.393Z',
      'transactions': [
        {
          'id': 17,
          'currency': 'USDT',
          'network': 'TRC20',
          'amount': '30',
          'fee': '0',
          'txId': '13ee4231b6ac9e018d814f3e131a8a904e2c5d63edb8a8e2cbb039add51e6361',
          'address': 'TQkTWFgowpqdHLtqQ6jiWgpBq1PqAkUw7U',
          'type': 'Deposit',
          'status': 'Finished',
          'transactionId': '542dbc4f-85dd-4ce2-bf21-66ffbcdbbde0',
          'uniqueId': null,
          'errorText': null,
          'exchangeId': 36,
          'createdAt': '2024-01-16T10:52:00.295Z',
          'updatedAt': '2024-01-16T10:53:32.390Z',
        },
        {
          'id': 18,
          'currency': 'USDT',
          'network': 'ERC20',
          'amount': null,
          'fee': '0',
          'txId': null,
          'address': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
          'type': 'Withdraw',
          'status': 'WithdrawalError',
          'transactionId': null,
          'uniqueId': '0510c9c2-557b-4ddc-833f-dff938c166b7',
          'errorText': 'Please contact support. seems the amount is too small to transfer',
          'exchangeId': 36,
          'createdAt': '2024-01-16T10:53:32.383Z',
          'updatedAt': '2024-01-16T10:53:32.383Z',
        },
      ],
    },
    {
      'id': 35,
      'currencyFrom': 'USDT',
      'networkFrom': 'tRC20',
      'currencyTo': 'USDT',
      'networkTo': 'ERC20',
      'status': 'Pending',
      'depositAmount': '30',
      'depositAddress': 'THWZreHb1ja3LaEF2xLMV2Ku3741DRxmck',
      'receivedAmount': null,
      'addressTo': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
      'rate': '1',
      'actualRate': null,
      'income': null,
      'source': null,
      'createdAt': '2024-01-16T10:01:50.797Z',
      'updatedAt': '2024-01-16T10:01:50.797Z',
      'transactions': [],
    },
    {
      'id': 34,
      'currencyFrom': 'USDT',
      'networkFrom': 'tRC20',
      'currencyTo': 'USDT',
      'networkTo': 'BEP20',
      'status': 'Pending',
      'depositAmount': '30',
      'depositAddress': 'TP1Z6kkh9NDkjWuQhTgoCmo3vT9cP91jxD',
      'receivedAmount': null,
      'addressTo': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
      'rate': '1',
      'actualRate': null,
      'income': null,
      'source': null,
      'createdAt': '2024-01-16T10:01:31.495Z',
      'updatedAt': '2024-01-16T10:01:31.495Z',
      'transactions': [],
    },
    {
      'id': 33,
      'currencyFrom': 'USDT',
      'networkFrom': 'ERC20',
      'currencyTo': 'USDT',
      'networkTo': 'BEP20',
      'status': 'Pending',
      'depositAmount': '30',
      'depositAddress': '0x3110724d32C2780459278D9C191bd93D3429eA4e',
      'receivedAmount': null,
      'addressTo': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
      'rate': '1',
      'actualRate': null,
      'income': null,
      'source': null,
      'createdAt': '2024-01-16T10:01:16.886Z',
      'updatedAt': '2024-01-16T10:01:16.886Z',
      'transactions': [],
    },
    {
      'id': 32,
      'currencyFrom': 'USDT',
      'networkFrom': 'ERC20',
      'currencyTo': 'USDT',
      'networkTo': 'TRC20',
      'status': 'Pending',
      'depositAmount': '30',
      'depositAddress': '0xf6d4CA979c448307c096E86F4E6EbBb3b1E7B0F0',
      'receivedAmount': null,
      'addressTo': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
      'rate': '1',
      'actualRate': null,
      'income': null,
      'source': null,
      'createdAt': '2024-01-16T10:00:57.855Z',
      'updatedAt': '2024-01-16T10:00:57.855Z',
      'transactions': [],
    },
    {
      'id': 31,
      'currencyFrom': 'Btc',
      'networkFrom': 'BTC',
      'currencyTo': 'USDC',
      'networkTo': 'ERC20',
      'status': 'Pending',
      'depositAmount': '30',
      'depositAddress': 'bc1qttg66c5qdmgejcza0l7mpndt3yjpe2mpj5qhzq',
      'receivedAmount': null,
      'addressTo': '0x095Ea7AE11D5cB8d399731d7C6048CEF2A044799',
      'rate': '42989.64',
      'actualRate': null,
      'income': null,
      'source': null,
      'createdAt': '2024-01-16T09:59:53.817Z',
      'updatedAt': '2024-01-16T09:59:53.817Z',
      'transactions': [],
    },
  ],
  'totalCount': 55,
};

// export const exchangeHistoryData = [
//   {
//     id: 86,
//     exchanged: 'BTC',
//     recieved: 'TRX',
//     rate: 0.06,
//     actualRate: 0.06,
//     depositAmount: 0.0011,
//     recievedAmount: 1.0291,
//     addressFrom: 'TRBkyJxvsdjfDpPd03DksoPJSFyaQ6SSDsdaSFLlsfeZ',
//     addressTo: 'PJSFyaQ6SSDsdaSFLlsfeZTRBkyJxvsdjfDpPd03Dkso',
//     status: StatusEnum.COMPLETE,
//     income: 0.4,
//     source: 'sunblind.cloud',
//     created: '2024.01.23 16:45:03',
//     updated: '2024.02.21 09:11:22',
//   },
//   {
//     id: 87,
//     exchanged: 'BTC',
//     recieved: 'TRX',
//     rate: 0.06,
//     actualRate: 0.06,
//     depositAmount: 0.0011,
//     recievedAmount: 1.0291,
//     addressFrom: 'TRBkyJxvsdjfDpPd03DksoPJSFyaQ6SSDsdaSFLlsfeZ',
//     addressTo: 'PJSFyaQ6SSDsdaSFLlsfeZTRBkyJxvsdjfDpPd03Dkso',
//     status: StatusEnum.COMPLETE,
//     income: 0.4,
//     source: 'sunblind.cloud',
//     created: '2024.01.23 16:45:03',
//     updated: '2024.02.21 09:11:22',
//   },
//   {
//     id: 88,
//     exchanged: 'BTC',
//     recieved: 'TRX',
//     rate: 0.06,
//     actualRate: 0.06,
//     depositAmount: 0.0011,
//     recievedAmount: 1.0291,
//     addressFrom: 'TRBkyJxvsdjfDpPd03DksoPJSFyaQ6SSDsdaSFLlsfeZ',
//     addressTo: 'PJSFyaQ6SSDsdaSFLlsfeZTRBkyJxvsdjfDpPd03Dkso',
//     status: StatusEnum.COMPLETE,
//     income: 0.4,
//     source: 'sunblind.cloud',
//     created: '2024.01.23 16:45:03',
//     updated: '2024.02.21 09:11:22',
//   },
// ];

// export const exchangeHistoryNestedData = [
//   {
//     key: 11,
//     id: 11,
//     type: 'inflow',
//     amount: 300,
//     fee: 0.4,
//     txId: '3fe9e880da650bf588050bf5',
//     created: '2014-12-24 23:12:00',
//     status: StatusEnum.UNEXPECTED_EXCHANGE_ERR,
//     error: true,
//   },
//   {
//     key: 12,
//     id: 12,
//     type: 'outflow',
//     amount: 200,
//     fee: 0.4,
//     txId: '3fe9e880da650bf588050bf5',
//     created: '2014-12-24 23:12:00',
//     status: StatusEnum.FINISHED,
//     error: false,
//   },
//   {
//     key: 13,
//     id: 13,
//     type: 'inflow',
//     amount: 3000,
//     fee: 0.4,
//     txId: '3fe9e880da650bf588050bf5',
//     created: '2014-12-24 23:12:00',
//     status: StatusEnum.FINISHED,
//     error: false,
//   },
// ];

export const activityLogs = [
  {
    id: 333,
    name: 'Clakr Handsome',
    email: 'clark.handsome@gmail.com',
    ipAddress: '176.38.16.169',
    action: 'CREATE_PAYMENT_ORDER',
    created: '28.02.2024 03:25:24',
    updated: '28.02.2024 05:25:24',
    group: 164,
    feeValue: 0.0002262315,
    totalFee: 0.0002262315,
    gasPrice: 5500000000,
    currency: 'USDC',
    amount: '10',
    category: 215,
    walletAddress: '0xdF82145309226a187a98A6CdB025a1f10A7037E0',
    payway: 'BEP20',
    uuid: '63df7475-111e-464b-b05d-40f5fc0776bb',
  },
];
