import { MouseEvent, useMemo } from 'react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Button, Collapse, Divider, Dropdown } from 'antd';
import { IBusiness, PERMISSIONS } from 'models';
import { useAuthorisationContext } from 'services/contexts';
import { BusinessOverview } from '../BusinessOverview';
import { DeleteBusinessAction } from '../DeleteBusinessAction';
import { EditBusinessAction } from '../EditBusinessAction';
import { LogsViewer } from '../LogsViewer';
import { MoreOutlined } from '@ant-design/icons';
import styles from './BusinessCollapse.module.scss';

export const BusinessCollapseButton = ({ isActive }: { isActive?: boolean; }) => (
  <Button
    type="primary"
    className={styles.collapseToggleButton}
    icon={isActive ? <CaretUp style={{ marginTop: 2 }} /> : <CaretDown />}
  >
    Show dashboard
  </Button>
);

export const BusinessCollapse = ({
  title,
  business,
  description,
}: {
  title: string;
  business: IBusiness;
  description?: string;
}) => {
  const { user, checkPermissions } = useAuthorisationContext();
  const isCreate = checkPermissions([PERMISSIONS.business.create]);
  const isDelete = checkPermissions([PERMISSIONS.business.delete]);

  const imRoot = useMemo(() => user?.id === 1, [user?.id]);

  const menuDropdownJsx = (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      dropdownRender={() => (
        <div className={styles.dropdownMenu}>
          {isCreate && <EditBusinessAction defaultData={business} />}
          {imRoot && isDelete && (
            <DeleteBusinessAction id={business.id?.toString()} />
          )}
          <LogsViewer business={business} disabled />
        </div>
      )}
    >
      <MoreOutlined
        className={styles.collapsePanelMenuButton}
        onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      />
    </Dropdown>
  );

  const headerJsx = (
    <>
      <span className={styles.title}>{title}</span>
      {description && (
        <>
          <Divider className={styles.divider} />
          <span className={styles.description}>{description}</span>
        </>
      )}
    </>
  );

  return (
    <Collapse
      expandIconPosition="end"
      expandIcon={({ isActive }) => <BusinessCollapseButton isActive={isActive} />}
      className={styles.collapse}
      items={[{
        label: headerJsx,
        extra: menuDropdownJsx,
        children: <BusinessOverview businessId={business.id} />,
      }]}
    />
  );
};

// header={
// <Space.Compact block direction="vertical">
//   <Typography.Text className={styles.collapseTitle}>
//     {_.name}
//   </Typography.Text>
//   <Divider style={{ margin: '16px 0px' }} />
//   <Typography.Text className={styles.collapseDescription}>
//     {/* TODO: get text from BA */}
//   </Typography.Text>
//   <Dropdown
//     trigger={['hover', 'click']}
//     dropdownRender={() => {
//       return (
//         <div className={styles.dropdownMenu}>
//           <EditBusinessAction defaultData={_} />
//           <DeleteBusinessAction id={_.id.toString()} />
//           <LogsViewer business={_} />
//         </div>
//       );
//     }}
//     placement="bottomRight"
//   >
//     <MoreOutlined
//       className={styles.collapsePanelMenuButton}
//       onClick={(e: MouseEvent<HTMLDivElement>) =>
//         e.stopPropagation()
//       }
//     />
//   </Dropdown>
// </Space.Compact>
// }
