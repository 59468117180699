import React, { ReactElement } from 'react';
import { useReactPortal } from './hooks';
import { ModalLoader } from './components';
import './PageLoader.scss';

const PageLoader = (): ReactElement => {
  const { Portal } = useReactPortal({ className: 'page-loader' });

  return (
    <Portal>
      <ModalLoader />
    </Portal>
  );
};

export default PageLoader;
