import React, { ReactElement } from 'react';
import { Typography } from 'antd';
import clsx from 'clsx';
import {
  DeleteOutlined,
  DollarOutlined,
  ExceptionOutlined,
  LoginOutlined,
  LogoutOutlined,
  SendOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import styles from './ActionBadge.module.scss';

export enum ActionEnum {
  LOGIN = 'login',
  LOGOUT = 'logout',
  SEND_NOTIFICATION = 'Send notification',
  CREATE_USER = 'Create user',
  CREATE_GROUP = 'Create group',
  UPDATE_GROUP = 'Update group',
  UPDATE_USER = 'Update user',
  UPDATE_BUSINESS = 'Update business',
  CREATE_BUSINESS = 'Create business',
  DELETE_BUSINESS = 'Delete business',
  UPDATE_COMMISSION = 'Update commission',
  DELETE_USER = 'Delete user',
  DELETE_GROUP = 'Delete group',
}

interface ActionProps {
  action: ActionEnum;
}

interface TypeObject {
  title: ActionEnum;
  icon: ReactElement;
}

const Types = new Map<string, TypeObject>([
  [ActionEnum.LOGIN, { title: ActionEnum.LOGIN, icon: <LoginOutlined /> }],
  [ActionEnum.LOGOUT, { title: ActionEnum.LOGOUT, icon: <LogoutOutlined /> }],
  ['sendNotification', { title: ActionEnum.SEND_NOTIFICATION, icon: <SendOutlined /> }],
  ['createUser', { title: ActionEnum.CREATE_USER, icon: <UserAddOutlined /> }],
  ['createGroup', { title: ActionEnum.CREATE_GROUP, icon: <UserAddOutlined /> }],
  ['updateBusiness', { title: ActionEnum.UPDATE_BUSINESS, icon: <ExceptionOutlined /> }],
  ['updateGroup', { title: ActionEnum.UPDATE_GROUP, icon: <ExceptionOutlined /> }],
  ['updateUser', { title: ActionEnum.UPDATE_USER, icon: <ExceptionOutlined /> }],
  ['createBusiness', { title: ActionEnum.CREATE_BUSINESS, icon: <ExceptionOutlined /> }],
  ['deleteBusiness', { title: ActionEnum.DELETE_BUSINESS, icon: <DeleteOutlined /> }],
  ['updateCommissions', { title: ActionEnum.UPDATE_COMMISSION, icon: <DollarOutlined /> }],
  ['deleteUser', { title: ActionEnum.DELETE_USER, icon: <DeleteOutlined /> }],
  ['deleteGroup', { title: ActionEnum.DELETE_GROUP, icon: <DeleteOutlined /> }],
]);

const generateActionBadgeColor = (action: string) => {
  switch (action) {
    case ActionEnum.LOGIN:
    case ActionEnum.LOGOUT:
      return 'blue';
    case 'sendNotification':
    case 'updateBusiness':
    case 'updateCommissions':
    case 'updateGroup':
    case 'updateUser':
      return 'orange';
    case 'createUser':
    case 'createGroup':
    case 'createBusiness':
      return 'green';
    default:
      return 'red';
  }
};

export const ActionBadge: React.FC<ActionProps> = ({ action }: ActionProps): ReactElement => {
  const selectedAction = Types.get(action);
  const colorClass = styles[generateActionBadgeColor(action)];

  if (!selectedAction) {
    return (
      <div className={clsx(styles.action, colorClass)}>
        <span>Invalid Action</span>
      </div>
    );
  }

  return (
    <div className={clsx(styles.action, colorClass)}>
      {selectedAction.icon}
      <Typography.Text className={styles.title}>
        {selectedAction.title}
      </Typography.Text>
    </div>
  );
};
