import React, { ReactNode } from 'react';
import { Collapse as AntCollapse } from 'antd';
import clsx from 'clsx';
import styles from './Collapse.module.scss';

const { Panel } = AntCollapse;

export type CollapseProps = {
  items: { header: string | JSX.Element; content: ReactNode | JSX.Element; }[];
  activeKey?: string | string[];
  onChange?: (key: string | string[]) => void;
  isInner?: boolean;
};

export const Collapse = ({
  items,
  activeKey,
  onChange,
  isInner,
}: CollapseProps): React.ReactElement => {
  const defaultActiveKey = activeKey || ['0'];

  const handleOnChange = (key: string | string[]) => {
    if (onChange) {
      onChange(key);
    }
  };

  return (
    <div className={styles.collapseList}>
      {items.map((item, index) => (
        <AntCollapse
          defaultActiveKey={defaultActiveKey}
          onChange={handleOnChange}
          className={clsx(styles.collapse, { [styles.collapseInner]: isInner })}
          expandIconPosition="end"
        >
          <Panel header={item.header} key={index}>
            {item.content}
          </Panel>
        </AntCollapse>
      ))}
    </div>
  );
};
