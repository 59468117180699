import React, { ReactElement } from 'react';
import { Layout } from 'antd';
import packageJson from '../../../../package.json';
import styles from './Footer.module.scss';

export const Footer = (): ReactElement => {
  return (
    <Layout.Footer className={styles.footer}>
      v{packageJson.version}
    </Layout.Footer>
  );
};
