import { ReactNode, ReactPortal, useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

export interface IUseReactPortalProps {
  tagName?: string;
  className?: string;
}

export interface IUseReactPortalData {
  Portal: ({ children }: { children: ReactNode; }) => ReactPortal | null;
}

export const useReactPortal = ({
  tagName = 'div',
  className,
}: IUseReactPortalProps): IUseReactPortalData => {
  const element = useMemo(() => {
    const htmlElement = document.createElement(tagName);

    if (className) {
      htmlElement.classList.add(className);
    }

    return htmlElement;
  }, [tagName, className]);

  const Portal = useCallback(
    ({ children }: { children: ReactNode; }) => {
      if (element != null) return createPortal(children, element);
      return null;
    },
    [element],
  );

  useEffect(() => {
    const rootNode = document.getElementById('root');

    if (rootNode) {
      rootNode.appendChild(element);

      return () => {
        rootNode.removeChild(element);
      };
    }

    return () => null;
  }, [element]);

  return { Portal };
};
