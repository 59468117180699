import { useMutation } from 'react-query';
import axios from 'axios';
import { ApiPath, IBusinessPost, IBusinessPostWithId } from 'models';
import { LocalStorage, LocalStorageKey } from 'utils';

export const useBusinessesMutate = () => {
  return useMutation({
    mutationFn: async (values: IBusinessPost | IBusinessPostWithId) => {
      const { name, ip, domain, apiKey, apiGateway, userIds } = values;
      const payload = { name, ip, domain, apiKey, apiGateway, userIds };

      const config = {
        headers: {
          Authorization: 'Bearer ' + LocalStorage.get(LocalStorageKey.ACCESS_TOKEN),
        },
      };
      const url = process.env.REACT_APP_AUTH_URI + '/' + ApiPath.BUSINESSES;

      if ('id' in values) {
        const response = await axios.patch(`${url}/${values.id}`, payload, config);
        return response.data;
      } else {
        const response = await axios.post(url, payload, config);
        return response.data;
      }
    },
  });
};
