import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { axios } from 'config';
import { ApiPath, IBusiness } from 'models';
import { LocalStorage, LocalStorageKey } from 'utils';

export const getBusinesses = async (id?: number) => {
  const PATH = process.env.REACT_APP_AUTH_URI + '/' + ApiPath.BUSINESSES;

  const { data } = await axios.get(!id ? PATH : PATH + '/' + id, {
    headers: {
      Authorization: 'Bearer ' + LocalStorage.get(LocalStorageKey.ACCESS_TOKEN),
    },
  });
  return data;
};

export const useBusinessesQuery = (id?: number) => {
  return useQuery<{ nodes: IBusiness[], totalCount: number }, AxiosError>({
    retryOnMount: false,
    queryFn: () => getBusinesses(id),
    queryKey: [!id ? 'businesses' : `business-${id}`],
  });
};
