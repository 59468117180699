import React from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import styles from './DateTime.module.scss';

const { Text } = Typography;

const convertDateFormat = (inputDate: Date | string) => {
  const date = dayjs(inputDate).format('YYYY.MM.DD');
  const time = dayjs(inputDate).format('HH:mm:ss');
  return { date, time };
};

export const DateTime = ({
  dateTime,
}: {
  dateTime: Date | string;
}) => {
  return (
    <div className={styles.dateTime}>
      <Text className={styles.date}>{convertDateFormat(dateTime).date}</Text>
      <Text className={styles.time}>{convertDateFormat(dateTime).time}</Text>
    </div>
  );
};
