import { useState } from 'react';

export type Value<T> = T | boolean;

export const useHandleState = <T>(initialValue: Value<T> = false as Value<T>) => {
  const [value, setValue] = useState<Value<T>>(initialValue);

  const handleValue = (val: Value<T>) => {
    if (val) {
      setValue(val);
    } else {
      setValue((prev: Value<T>) => !prev);
    }
  };

  return [value, handleValue] as const;
};
