import {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Input, notification } from 'antd';
import clsx from 'clsx';
import { queryClient } from 'config';
import { IUser, UnknownType } from 'models';
import useCreateUser from 'services/api/users/useCreateUser';
import useUpdateUser from 'services/api/users/useUpdateUser';
import * as yup from 'yup';
import { generatePassword } from 'utils';
import { BusinessSelect, FormItem, RoleSelect } from 'components';
import { ReloadOutlined } from '@ant-design/icons';
import styles from './UserForm.module.scss';

export type FormValues = {
  isActive?: boolean;
  name: string;
  email: string;
  password?: string;
  groupId: number;
  businessIds: { label: string; value: number; }[];
};

export const generateUserValidationSchema = (id: number) => {
  return yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    ...(!id
      ? {
        password: yup
          .string()
          .min(8, 'Password must be 8 characters long')
          .max(32, "Password shouldn't be longer than 32 characters long")
          .matches(/[0-9]/, 'Password requires a number')
          .matches(/[a-z]/, 'Password requires a lowercase letter')
          .matches(/[A-Z]/, 'Password requires an uppercase letter')
          .required(),
      }
      : {
        isActive: yup.string().required('Please select status'),
      }),
    groupId: yup.object().shape({ label: yup.string(), value: yup.string() }),
    // businessIds: yup
    //   .array()
    //   .of(yup.object().shape({ label: yup.string(), value: yup.string() })),
  });
};

export type FormProps<T> = {
  id?: number;
  initialValues?: T;
  handleDrawer: (state: boolean) => void;
};

export const UserForm = forwardRef<unknown, FormProps<IUser & UnknownType>>(
  ({ id, initialValues, handleDrawer }, ref): ReactElement => {
    const [password, setPassword] = useState('');
    const { mutate: createUser } = useCreateUser({
      onSuccess: async () => {
        notification.success({ message: 'User created' });
        await queryClient.resetQueries('users');
        handleDrawer(false);
      },
      onError: (e) => {
        if (e.response?.status === 403) {
          notification.error({
            message: 'User with this email already exists',
          });
        } else {
          notification.error({
            message: e.response?.data?.message,
          });
        }
      },
    });

    const { mutate: updateUser } = useUpdateUser({
      onSuccess: async () => {
        notification.success({ message: 'User updated successfully' });
        await queryClient.resetQueries('users');
      },
      onError: (e) => {
        notification.error({ message: e.response?.data?.message });
      },
    });

    const { control, handleSubmit, setValue } = useForm({
      defaultValues: initialValues,
      resolver: yupResolver(generateUserValidationSchema(id!)),
    });

    const onGeneratePassword = () => setPassword(generatePassword());

    useEffect(() => {
      setValue('password', password);
    }, [password, setValue]);

    const createUserHandler = handleSubmit((values: UnknownType) => {
      createUser({
        name: values.name.trim(),
        email: values.email,
        password: values.password,
        groupId: Number(values.groupId.value),
        businessIds: values.businessIds,
      });
    });

    const updateUserHandler = handleSubmit((values: UnknownType) => {
      updateUser({
        id: id!,
        name: values.name.trim(),
        email: values.email,
        isActive: values.isActive,
        groupId: Number(values.groupId.value),
        businessIds: values.businessIds,
      });
    });

    const onSubmit = !id ? createUserHandler : updateUserHandler;

    useImperativeHandle(ref, () => ({ submitHandler: onSubmit }));

    return (
      <Form>
        <div className={styles.formContainer}>
          <FormItem control={control} name="name" label="Name and surname">
            <Input placeholder="Enter name and surname" />
          </FormItem>
          <FormItem control={control} name="email" label="Email">
            <Input placeholder="Enter email" />
          </FormItem>
          {!id ? (
            <div className={clsx(styles.formItem, styles.withAddonBtn)}>
              <FormItem control={control} name="password" label="Password">
                <Input.Password
                  autoComplete="new-password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="link"
                  onClick={onGeneratePassword}
                  className={styles.generatePassBtn}
                >
                  <ReloadOutlined />
                  Generate password
                </Button>
              </FormItem>
            </div>
          ) : (
            <FormItem name="isActive" label="Status" control={control}>
              <ReactSelect
                options={[
                  { value: true, label: 'Activated' },
                  { value: false, label: 'Deactivated' },
                ]}
              />
            </FormItem>
          )}

          <RoleSelect
            control={control}
            name="groupId"
            initialValues={initialValues?.groupId}
          />
          <BusinessSelect
            control={control}
            name="businessIds"
            initialValues={initialValues?.businesses}
          />
        </div>
      </Form>
    );
  },
);
