import { forwardRef, ReactElement, useImperativeHandle, useState } from 'react';
import { Button, Drawer } from 'antd';
import {
  CARDS_WITHOUT_DRAWER,
  CardTitles,
  CardTitlesEnum,
  CurrencyEnum,
  IStatistics,
  UnknownType,
} from 'models';
import { overviewCardIcons } from '../Card/utils';
import {
  generateCardCollapseContent,
} from '../CardNew/utils';
import { Collapse } from 'components';
import styles from './CardDrawer.module.scss';

export type CardDrawerProps = {
  title: CardTitlesEnum;
  data: IStatistics;
  currency: CurrencyEnum;
};

export const CardDrawer = forwardRef(
  ({ title, currency, data }: CardDrawerProps, ref): ReactElement => {
    const [open, setOpen] = useState(false);
    const Icon = overviewCardIcons[title as keyof typeof overviewCardIcons];
    const {
      incomeCard,
      expensesCard,
      averageTransactionsTimeCard,
      totalInflowCard,
      totalOutflowCard,
      averageTransactionsVolumeCard,
      transactionCountCard,
      currencyExchangeCard,
    } = generateCardCollapseContent({ currency, data } as UnknownType);
    const drawerHandler = (state: boolean) => {
      if (state === true && CARDS_WITHOUT_DRAWER.includes(title)) {
        return;
      }
      setOpen(state);
    };

    const onClose = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      drawerHandler,
    }));

    const footerJsx = (
      <Button
        className={styles.actionButton}
        type="primary"
        onClick={() => drawerHandler(false)}
      >
        Close
      </Button>
    );

    const headerJsx = (
      <div className={styles.header}>
        <div className={styles.iconWrapper}>{Icon}</div>
        <span className={styles.title}>
          {CardTitles[title]}
        </span>
      </div>
    );
    return (
      <Drawer
        className={styles.drawer}
        title={headerJsx}
        footer={footerJsx}
        onClose={onClose}
        open={open}
        width={850}
      >
        {title === CardTitlesEnum.INCOME && <Collapse items={incomeCard} />}
        {title === CardTitlesEnum.EXPENSES && <Collapse items={expensesCard} />}
        {/* TOTAL VOLUME CARD */}
        {title === CardTitlesEnum.TRANSACTION_COUNT && (
          <Collapse items={transactionCountCard} />
        )}
        {title === CardTitlesEnum.AVERAGE_TRANSACTION_TIME && (
          <Collapse items={averageTransactionsTimeCard} />
        )}
        {title === CardTitlesEnum.TOTAL_INFLOW && <Collapse items={totalInflowCard} />}
        {title === CardTitlesEnum.TOTAL_OUTFLOW && <Collapse items={totalOutflowCard} />}
        {title === CardTitlesEnum.AVERAGE_TRANSACTION_VOLUME && (
          <Collapse items={averageTransactionsVolumeCard} />
        )}
        {title === CardTitlesEnum.CURRENCY_EXCHANGE_VOLUME && (
          <Collapse items={currencyExchangeCard} />
        )}
      </Drawer>
    );
  },
);
