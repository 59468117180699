import React, { ReactElement } from 'react';
import { GearSix } from '@phosphor-icons/react';
import _ from 'lodash';
import { CardTitlesEnum } from 'models';
import { DropdownWithCheckbox } from 'components';

export type OverviewSettingsButtonProps = {
  selected: string[];
  handler?: (id: string | string[]) => void;
};

export const overviewBusinessCardMap = Object.keys(CardTitlesEnum);

export const OverviewSettingsButton = ({
  handler,
}: OverviewSettingsButtonProps): ReactElement => {
  return (
    <DropdownWithCheckbox
      items={overviewBusinessCardMap.map((item) => ({
        label: _.upperFirst(_.lowerCase(item)),
        value: item,
      }))}
      selected={overviewBusinessCardMap}
      handler={handler}
      btnIcon={<GearSix />}
    />
  );
};
