const operationNames = ['Create', 'Read', 'Update', 'Delete'];
const operations = ['c', 'r', 'u', 'd'];
const objectTypes = [
  'business',
  'monetization',
  'exchange',
  'user',
  'group',
  'logs',
  'notification',
];

const permissionsMap = new Map();

objectTypes.forEach((type) => {
  operations.forEach((operation, index) => {
    permissionsMap.set(`${type}.${operation}`, `${operationNames[index]} ${type}`);
  });
});

export default permissionsMap;

export const PERMISSIONS = {
  business: {
    create: 'business.c',
    read: 'business.r',
    update: 'business.u',
    delete: 'business.d',
  },
  monetization: {
    read: 'monetization.r',
    update: 'monetization.u',
  },
  exchange: {
    read: 'exchange.r',
    update: 'exchange.u',
  },
  user: {
    create: 'user.c',
    read: 'user.r',
    update: 'user.u',
    delete: 'user.d',
  },
  group: {
    create: 'group.c',
    read: 'group.r',
    update: 'group.u',
    delete: 'group.d',
  },
  logs: {
    read: 'logs.r',
  },
  notification: {
    create: 'notification.c',
    read: 'notification.r',
  },
};
