import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Input, Space } from 'antd';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { validationSchema } from './FormSignin.schema';
import { FormItem } from 'components';
import styles from './FormSignin.module.scss';

export type SigninType = {
  email: string;
  password: string;
};

type FormSigninProps = {
  onSubmit: (data: SigninType) => void;
  isLoading: boolean;
};

export const FormSignin = ({ onSubmit: login, isLoading }: FormSigninProps) => {
  const { handleSubmit, control } = useForm<SigninType>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<SigninType> = (data) => {
    login(data);
  };

  return (
    <Form className={styles.form} onFinish={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <FormItem control={control} name="email" label="Email" required>
          <Input type="email" placeholder="Enter email" />
        </FormItem>

        <FormItem control={control} name="password" label="Password" required>
          <Input.Password type="password" placeholder="Enter password" />
        </FormItem>

        <Space className={styles.extraActions}>
          {/* <Controller
            name='rememberMe'
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox {...field} checked={field.value} className={styles.formCheckbox}>
                Remember me
              </Checkbox>
            )}
          /> */}
          {/* <Link to='/forgot-password' className={styles.formForgot}>
            Forgot password?
          </Link> */}
        </Space>

        <Button
          className={styles.buttonSubmit}
          type="primary"
          htmlType="submit"
          block
          loading={isLoading}
        >
          Sign in
        </Button>
      </div>
    </Form>
  );
};
