import React, { ReactElement, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Button, Checkbox, Dropdown, Spin } from 'antd';
import styles from './DropdownWithCheckbox.module.scss';

export type DropdownOptions = {
  label: string;
  value: number | string;
};

export type DropdownWithCheckboxProps<T> = {
  items: T[];
  btnIcon?: React.ReactNode;
  btnText?: string;
  loading?: boolean;
  selected: string[];
  handler?: (id: string | string[]) => void;
  trigger?: ('click' | 'hover' | 'contextMenu')[]
};

export const DropdownWithCheckbox = ({
  handler = () => {
  },
  selected = [],
  items,
  loading,
  btnIcon,
  btnText,
  trigger = ['hover'],
}: DropdownWithCheckboxProps<DropdownOptions>): ReactElement => {
  const [selectedItems, setSelectedItems] = React.useState(selected || []);
  const queryClient = useQueryClient();

  const handleToggleSelection = (itemId: string) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter((id) => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
  };

  useEffect(() => {
    handler(selectedItems);
    queryClient.invalidateQueries({ queryKey: ['businesses-statistics'] });
  }, [selectedItems, queryClient, handler]);

  const dropdownMenu = (
    <div className={styles.dropdownMenu}>
      {items.map((item) => {
        return (
          <Checkbox
            checked={selectedItems.includes(item.value as string)}
            key={item.value}
            onChange={() => handleToggleSelection(item?.value as string)}
          >
            {item.label}
          </Checkbox>
        );
      })}
    </div>
  );

  return (
    <Dropdown
      dropdownRender={() => (loading ? <Spin /> : dropdownMenu)}
      placement="bottomRight"
      className={styles.dropdown}
      arrow
      trigger={trigger}
    >
      <Button className={styles.button} size="large" icon={btnIcon}>
        {btnText}
      </Button>
    </Dropdown>
  );
};
