import React, { Dispatch } from 'react';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IStat } from 'models';
import { convertStringFromKebab } from 'utils';
import { LogsDrawer } from '../../LogsDrawer/LogsDrawer';
import { CopyToClipboard } from 'components';
import { DownloadLogs } from 'components';
import { RedoOutlined } from '@ant-design/icons';
import styles from '../LogsViewerTable.module.scss';

export type LogType = IStat[];

export const generateColumns = ({
  id,
  onRestartContainer,
  setContainerLogs,
}: {
  id: number;
  onRestartContainer: (values: IStat) => void;
  setContainerLogs: Dispatch<React.SetStateAction<string[]>>;
}): ColumnsType<IStat> => {
  const columns: ColumnsType<IStat> = [
    {
      title: 'Name | ID',
      dataIndex: 'name',
      key: 'name',
      render: (td, row) => {
        return (
          <div className={styles.container}>
            <LogsDrawer
              name={convertStringFromKebab(td)}
              businessId={id}
              setContainerLogs={setContainerLogs}
            />
            <CopyToClipboard output={row.id} />
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Port',
      dataIndex: 'ports',
      key: 'ports',
      render: (_) => _[_.length - 1],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (td, row) => {
        return (
          <Space size={0}>
            <DownloadLogs id={id} container={row.name} />
            <Button
              type="text"
              onClick={() => onRestartContainer(row)}
              icon={<RedoOutlined />}
              className={styles.actionButton}
            />
          </Space>
        );
      },
    },
  ];

  return columns;
};
