import { MouseEvent, useRef, useState } from 'react';
import { Button, Drawer } from 'antd';
import { FormWithRefReturn, UnknownType } from 'models';
import { WithDrawerProps } from './models';
import styles from './withDrawer.module.scss';

export function withDrawer<T extends WithDrawerProps>(
  WrappedComponent: UnknownType,
) {
  return (props: T) => {
    const {
      drawerProps: { button, title, actions },
      ...otherProps
    } = props;
    const childRed = useRef<FormWithRefReturn>(null);
    const [open, setOpen] = useState(false);
    const handleDrawer = (state: boolean) => setOpen(state);

    const invokeButton =
      typeof button === 'string' ? (
        <Button type="primary" onClick={() => handleDrawer(true)}>
          {button}
        </Button>
      ) : (
        <div onClick={() => handleDrawer(true)}>{button}</div>
      );

    const handleSubmit = () => {
      if (childRed.current) {
        childRed.current.submitHandler();
      }
    };

    const actionsJsx = actions ? (
      actions
    ) : (
      <div className={styles.actions}>
        <Button
          danger
          type="text"
          onClick={() => handleDrawer(false)}
        >
          Cancel
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    );

    return (
      <>
        {invokeButton}
        <Drawer
          className={styles.drawer}
          title={title}
          footer={actionsJsx}
          onClose={() => handleDrawer(false)}
          open={open}
          destroyOnClose={true}
          rootClassName={styles.drawerRoot}
          width={480}
          onClick={(e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.wrappedComponent}>
            <WrappedComponent ref={childRed} handleDrawer={handleDrawer} {...otherProps} />
          </div>
        </Drawer>
      </>
    );
  };
}
