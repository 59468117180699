import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { getPagesArray } from './utils';
import { ElementsSize } from '../../Pagination';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './Pages.module.scss';

export interface IPagesProps {
  page: number;
  count: number;
  disabled: boolean;
  onPageSelect: (page: number) => void;
  size: ElementsSize;
}

const Pages = ({
  page, count, onPageSelect, disabled, size,
}: IPagesProps): ReactElement => {
  const prevPageButtonDisabled = disabled || page <= 1;
  const nextPageButtonDisabled = disabled || page >= count;

  const pagesCls = clsx(styles.pages, {
    [styles.large]: size === 'large',
    [styles.medium]: size === 'medium',
    [styles.small]: size === 'small',
    [styles.xs]: size === 'xs',
  });

  return (
    <div className={pagesCls}>
      <button
        type="button"
        disabled={prevPageButtonDisabled}
        onClick={() => onPageSelect(page - 1)}
        className={clsx(styles.swipeButton, {
          [styles.disabled]: prevPageButtonDisabled,
        })}
      >
        <LeftOutlined />
      </button>
      {getPagesArray(page, count).map((pageNumber) => (typeof pageNumber !== 'string' ? (
        <button
          type="button"
          key={pageNumber}
          disabled={disabled}
          onClick={() => onPageSelect(pageNumber)}
          className={clsx(styles.pageButton, {
            [styles.selected]: pageNumber === page,
            [styles.disabled]: disabled,
          })}
        >
          {pageNumber}
        </button>
      ) : (
        <span key={pageNumber} className={styles.separator}>...</span>
      )))}
      <button
        type="button"
        disabled={nextPageButtonDisabled}
        onClick={() => onPageSelect(page + 1)}
        className={clsx(styles.swipeButton, {
          [styles.disabled]: nextPageButtonDisabled,
        })}
      >
        <RightOutlined />
      </button>
    </div>
  );
};

export default Pages;
