import { SortOrder } from 'antd/es/table/interface';
import { Sort, UnknownType } from 'models';
import { adaptSortOrder } from 'utils';

const adaptSort = <T extends Record<string, UnknownType>>(sortKey: keyof T | string, sortOrder: SortOrder): Sort<T>[] => {
  const direction = adaptSortOrder(sortOrder);

  return [
    { field: sortKey, direction },
  ];
};

export default adaptSort;
