import { ReactElement, SVGProps, useRef } from 'react';
import { Divider, Skeleton } from 'antd';
import clsx from 'clsx';
import { Dayjs } from 'dayjs';
import { CARDS_WITHOUT_DRAWER, CardTitles, CardTitlesEnum, CurrencyEnum, IStatistics } from 'models';
import { getCorrectDate } from 'utils';
import { overviewCardIcons } from './utils';
import { CardDrawer, CountUp } from 'components';
import { generateCountupType } from 'components/ui/CountUp/CountUp';
import styles from './Card.module.scss';

export type CardProps = {
  title: CardTitlesEnum;
  icon?: ReactElement<SVGProps<SVGSVGElement>>;
  currency?: CurrencyEnum;
  amount?: number;
  dateRange: [Dayjs | null, Dayjs | null];
  data: IStatistics;
  isLoading?: boolean;
};

export const CardNew = ({
  title,
  currency,
  amount,
  dateRange,
  data,
  isLoading,
}: CardProps): ReactElement => {
  const Icon = overviewCardIcons[title];
  const drawerRef = useRef<{ drawerHandler: (state: boolean) => void; }>();

  return isLoading ? <Skeleton className="__sk_card" /> : (
    <>
      <div
        className={clsx(styles.card, { [styles.cardWithoutDrawer]: CARDS_WITHOUT_DRAWER.includes(title) })}
        onClick={() => drawerRef.current?.drawerHandler(true)}
      >
        <div className={styles.header}>
          <div className={styles.iconWrapper}>{Icon}</div>
          <span className={styles.title}>
            {CardTitles[title]}
          </span>
        </div>
        <div className={styles.body}>
          <div className={styles.amountRow}>
            {/* <RateBadge percentage={35} />  */}
            <CountUp
              className={styles.cardAmount}
              currency={currency}
              start={0}
              end={amount!}
              type={generateCountupType(title)}
              withText
            />
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.footer}>
          <span className={styles.date}>{getCorrectDate(dateRange)}</span>
        </div>
      </div >
      <CardDrawer
        currency={currency as CurrencyEnum}
        data={data as unknown as IStatistics}
        title={title}
        ref={drawerRef}
      />
    </>
  );
};
