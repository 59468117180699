import React from 'react';
import { Button } from 'antd';
import { useUpdateImage } from 'services';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';

export type DownloadLogsProps = {
  id: number,
  container: string,
};

export const DownloadLogs = ({ id, container }: DownloadLogsProps) => {
  const { mutate } = useUpdateImage(id, container);

  const handleClick = () => mutate();

  return (
    <div>
      <Button disabled onClick={(handleClick)} type="text" icon={<VerticalAlignBottomOutlined />} />
    </div>
  );
};
