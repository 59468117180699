import { useQuery } from 'react-query';
import { axios } from 'config';
import { ApiResponseType, Filter, INotification, IUser, Sort } from 'models';
import { ApiPath } from 'models';
import { LocalStorage, LocalStorageKey } from 'utils';

type GetNotificationParams = {
  filter: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset?: number };
  sorting?: (Sort<IUser>)[];
};

export const getNotifications = (params?: GetNotificationParams) => async () => {
  const PATH = process.env.REACT_APP_AUTH_URI + '/' + ApiPath.NOTIFICATIONS;

  const { data } = await axios.get<ApiResponseType<INotification>>(PATH, {
    params,
    headers: {
      Authorization: 'Bearer ' + LocalStorage.get(LocalStorageKey.ACCESS_TOKEN),
    },
  });

  return data;
};

export const useNotificationsQuery = (params?: GetNotificationParams) => {
  return useQuery({
    queryFn: getNotifications(params),
    queryKey: ['notifications', params],
  });
};
