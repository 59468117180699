export const truncateNumber = (num: number = 0, decimals: number): number => {
  const numStr = num.toString();
  const decimalIndex = numStr.indexOf('.');

  if (decimalIndex === -1) {
    return Number(numStr);
  }

  const truncatedStr = numStr.slice(0, decimalIndex + decimals + 1);
  return Number(truncatedStr);
};
