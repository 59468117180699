import { CardTitlesEnum, CurrencyEnum, IStatistics } from 'models';
import { countIncome } from 'utils';

export const generateStatisticCardMap = (data: IStatistics, currency: CurrencyEnum, key?: string) => {
  const STATISTIC_CARD_MAP = {
    [CardTitlesEnum.INCOME]: countIncome(data, currency),
    [CardTitlesEnum.EXPENSES]: data?.totalsAmounts?.expenses ?? 0,
    [CardTitlesEnum.TOTAL_VOLUME]: data?.totalsGroups?.totalVolumeOfTransaction ?? 0,
    [CardTitlesEnum.TRANSACTION_COUNT]: data?.transactionsCount ?? 0,
    [CardTitlesEnum.AVERAGE_TRANSACTION_TIME]: data?.totalsGroups?.averageTransactionsTime ?? 0,
    [CardTitlesEnum.AVERAGE_TRANSACTION_VOLUME]:
      data?.totalsGroups?.averageTransactionsVolume?.convertedAmount ?? 0,
    [CardTitlesEnum.TOTAL_INFLOW]: data?.totalsAmounts?.totalInflow ?? 0,
    [CardTitlesEnum.TOTAL_OUTFLOW]: data?.totalsAmounts?.totalOutflow ?? 0,
    [CardTitlesEnum.CURRENCY_EXCHANGE_VOLUME]: data?.totalsAmounts?.exchangeVolume ?? 0,
  };

  if (key) {
    return STATISTIC_CARD_MAP[key as keyof typeof STATISTIC_CARD_MAP];
  }

  return STATISTIC_CARD_MAP;
};
