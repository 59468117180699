export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_SHORT = 'MM-DD';
export const DATE_FORMAT_FULL = 'YYYY-MM-DDTHH:mm:ss[Z]';

export enum SegmentedDateEnum {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  CUSTOM = 'custom',
}

export const SEGMENTED_DATE_MAP = {
  week: 7,
  month: 30,
  year: 365,
};

export const SEGMENTED_DATE_OPTIONS = Object.keys(SEGMENTED_DATE_MAP);
export const DEFAULT_DATE_RANGE = SEGMENTED_DATE_MAP.week;

export const segmentedOptions = [
  {
    label: SegmentedDateEnum.WEEK,
    value: SegmentedDateEnum.WEEK,
  },
  {
    label: SegmentedDateEnum.MONTH,
    value: SegmentedDateEnum.MONTH,
  },
  {
    label: SegmentedDateEnum.YEAR,
    value: SegmentedDateEnum.YEAR,
  },
  {
    label: SegmentedDateEnum.CUSTOM,
    value: SegmentedDateEnum.CUSTOM,
  },
];
