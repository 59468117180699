import { useQuery } from 'react-query';
import { axios } from 'config';
import { ApiPath, ApiResponseType, Filter, ILog, IUser, Sort } from 'models';

type GetUsersParams = {
  filter: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset?: number };
  sorting?: (Sort<IUser>)[];
};

export const getLogs = (params?: GetUsersParams) => async () => {
  const PATH = process.env.REACT_APP_AUTH_URI + '/' + ApiPath.LOGS;

  const { data } = await axios.get<ApiResponseType<ILog>>(PATH, { params });

  return data;
};

export const useLogsQuery = (params?: GetUsersParams) => {
  return useQuery({
    queryFn: getLogs(params),
    queryKey: ['logs', params],
  });
};
