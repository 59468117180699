import React, { ReactElement, useEffect, useRef } from 'react';
import { CalendarPlus } from '@phosphor-icons/react';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT, DEFAULT_DATE_RANGE } from 'models';
import styles from './RangePicker.module.scss';

export type RangePickerProps = {
  handler: (range: [Dayjs | null, Dayjs | null]) => void;
  values: [Dayjs | null, Dayjs | null];
  daysBefore?: number;
  isDisabled: boolean;
};

export const RangePicker = ({
  handler,
  values,
  isDisabled,
  daysBefore = DEFAULT_DATE_RANGE,
}: RangePickerProps): ReactElement => {
  const prevDaysBefore = useRef(daysBefore);

  const onChangeHandler = (range: [Dayjs, Dayjs]) => {
    if (range[0] && range[1]) {
      const date1 = dayjs.utc(range[0]);
      const date2 = dayjs.utc(range[1]).add(1, 'day').subtract(1, 'second');

      handler([date1, date2]);
    }
  };

  useEffect(() => {
    if (
      values[0] === null ||
      values[1] === null ||
      prevDaysBefore.current !== daysBefore
    ) {
      const today = dayjs()
        .utc()
        .startOf('day')
        .add(1, 'day')
        .subtract(1, 'second');
      const countDaysBefore = today
        .subtract(daysBefore + 1, 'day')
        .add(1, 'second');

      handler([countDaysBefore, today]);
      prevDaysBefore.current = daysBefore;
    }
  }, [values, handler, daysBefore]);

  return (
    <DatePicker.RangePicker
      value={values}
      onChange={(_) => onChangeHandler(_ as [Dayjs, Dayjs])}
      format={DATE_FORMAT}
      separator="-"
      placement="bottomRight"
      allowClear={false}
      suffixIcon={<CalendarPlus />}
      className={styles.datePicker}
      disabled={isDisabled}
    />
  );
};
