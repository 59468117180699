export enum LocalStorageKey {
  SYSTEM_TASKS_COLUMNS = 'SYSTEM_TASKS_COLUMNS',
  SYSTEM_FIREWALL_COLUMNS = 'SYSTEM_FIREWALL_COLUMNS',
  SYSTEM_ACTIVITY_LOG_COLUMNS = 'SYSTEM_ACTIVITY_LOG_COLUMNS',
  PROCESSING_PAYMENT_ORDERS_COLUMNS = 'PROCESSING_PAYMENT_ORDERS_COLUMNS',
  PROCESSING_TRANSACTIONS_COLUMNS = 'PROCESSING_TRANSACTIONS_COLUMNS',
  PROCESSING_TRANSACTIONS_FIAT_COLUMNS = 'PROCESSING_TRANSACTIONS_FIAT_COLUMNS',
  FINANCIAL_STATEMENT_COLUMNS = 'FINANCIAL_STATEMENT_COLUMNS',
  USERS_EMPLOYEES_CLIENTS_COLUMNS = 'USERS_EMPLOYEES_CLIENTS_COLUMNS',
  USERS_USERS_TRANSACTIONS = 'USERS_USERS_TRANSACTIONS',
  UNIT_COLUMNS = 'UNIT_COLUMNS',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  USER = 'USER',
}

interface KeyValueMap {
  readonly [LocalStorageKey.SYSTEM_TASKS_COLUMNS]: string[];
  readonly [LocalStorageKey.SYSTEM_FIREWALL_COLUMNS]: string[];
  readonly [LocalStorageKey.SYSTEM_ACTIVITY_LOG_COLUMNS]: string[];
  readonly [LocalStorageKey.FINANCIAL_STATEMENT_COLUMNS]: string[];
  readonly [LocalStorageKey.PROCESSING_PAYMENT_ORDERS_COLUMNS]: string[];
  readonly [LocalStorageKey.PROCESSING_TRANSACTIONS_COLUMNS]: string[];
  readonly [LocalStorageKey.PROCESSING_TRANSACTIONS_FIAT_COLUMNS]: string[];
  readonly [LocalStorageKey.USERS_EMPLOYEES_CLIENTS_COLUMNS]: string[];
  readonly [LocalStorageKey.USERS_USERS_TRANSACTIONS]: string[];
  readonly [LocalStorageKey.UNIT_COLUMNS]: string[];
  readonly [LocalStorageKey.ACCESS_TOKEN]: string;
  readonly [LocalStorageKey.REFRESH_TOKEN]: string;
  readonly [LocalStorageKey.USER]: string;
}

export class LocalStorage {
  static get<T extends LocalStorageKey, R extends KeyValueMap[T]>(key: T): R | null {
    const value = localStorage.getItem(key);

    if (value === null) {
      return null;
    }

    return LocalStorage.parseValue(key, value) as R;
  }

  static set<T extends LocalStorageKey, R extends KeyValueMap[T]>(key: T, value: R): void {
    localStorage.setItem(key, LocalStorage.stringifyValue(key, value));
  }

  static remove<T extends LocalStorageKey>(key: T): void {
    localStorage.removeItem(key);
  }

  private static stringifyValue<
    T extends LocalStorageKey,
    R extends KeyValueMap[T],
  >(key: T, value: R): string {
    switch (key) {
      case LocalStorageKey.USERS_EMPLOYEES_CLIENTS_COLUMNS:
      case LocalStorageKey.SYSTEM_TASKS_COLUMNS:
      case LocalStorageKey.SYSTEM_ACTIVITY_LOG_COLUMNS:
      case LocalStorageKey.PROCESSING_PAYMENT_ORDERS_COLUMNS:
      case LocalStorageKey.PROCESSING_TRANSACTIONS_COLUMNS:
      case LocalStorageKey.PROCESSING_TRANSACTIONS_FIAT_COLUMNS:
      case LocalStorageKey.USERS_USERS_TRANSACTIONS:
      case LocalStorageKey.UNIT_COLUMNS:
        return JSON.stringify(value);
      default:
        return value?.toString();
    }
  }

  private static parseValue<
    T extends LocalStorageKey,
    R extends KeyValueMap[T],
  >(key: LocalStorageKey, value: string): R | string {
    switch (key) {
      case LocalStorageKey.USERS_EMPLOYEES_CLIENTS_COLUMNS:
      case LocalStorageKey.SYSTEM_TASKS_COLUMNS:
      case LocalStorageKey.SYSTEM_ACTIVITY_LOG_COLUMNS:
      case LocalStorageKey.PROCESSING_PAYMENT_ORDERS_COLUMNS:
      case LocalStorageKey.PROCESSING_TRANSACTIONS_COLUMNS:
      case LocalStorageKey.PROCESSING_TRANSACTIONS_FIAT_COLUMNS:
      case LocalStorageKey.USERS_USERS_TRANSACTIONS:
      case LocalStorageKey.UNIT_COLUMNS:
        return LocalStorage.parseJSON(key, value) as R;
      default:
        return value;
    }
  }

  private static parseJSON<T extends LocalStorageKey, R = KeyValueMap[T]>(
    key: T,
    value: string,
  ): R | null {
    try {
      return JSON.parse(value);
    } catch {
      localStorage.removeItem(key);

      return null;
    }
  }
}
