import { addYears, subYears } from 'date-fns';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { Filter, UnknownType } from 'models';
import { Moment } from 'moment/moment';
import { BetweenComparisonType } from '../types/BetweenComparisonType';
import { ComparisonType } from '../types/ComparisonType';
import { FilterField } from '../types/FilterField';
import { FilterRelation } from '../types/FilterRelation';

const convertFiltersToGraphQLObject = <T>(
  filterFields: Map<string, FilterField>,
  filterRelation: FilterRelation,
  filters: Record<string, unknown>,
): Filter<T> => {
  const search: Record<string, unknown>[] = [];

  Object.keys(filters).forEach((filterKey) => {
    const value = filters[filterKey];
    const {
      field,
      comparisonType,
      betweenComparisonType,
      searchFullName,
      monthOnly,
    } = filterFields.get(filterKey) || {};

    switch (comparisonType) {
      case ComparisonType.BETWEEN:
      case ComparisonType.NOT_BETWEEN: {
        const oppositeType = betweenComparisonType === BetweenComparisonType.LOWER
          ? BetweenComparisonType.UPPER
          : BetweenComparisonType.LOWER;

        const oppositeFieldKey = `${field}.${comparisonType}.${oppositeType}`;

        const oppositeField = search.find((item) => _get(item, oppositeFieldKey));

        const path = `${field}.${comparisonType}.${betweenComparisonType}`;

        const dateValue = (value as Moment).utcOffset(0, true).format();

        if (oppositeField) {
          return _set(oppositeField, path, dateValue);
        }

        const betweenSearch: Record<string, UnknownType> = {};

        _set(betweenSearch, path, dateValue);

        const defaultValue = oppositeType === BetweenComparisonType.LOWER
          ? subYears(new Date(), 50)
          : addYears(new Date(), 1);

        _set(betweenSearch, oppositeFieldKey, defaultValue);

        if (monthOnly === true) {
          const lowerDate = new Date(betweenSearch.createdAt.between.lower);
          const defaultValueDate = new Date(lowerDate.getFullYear(), lowerDate.getMonth() + 1, 0);
          defaultValueDate.setHours(25);
          defaultValueDate.setMinutes(59);
          defaultValueDate.setSeconds(59);
          defaultValueDate.setMilliseconds(999);

          _set(betweenSearch, oppositeFieldKey, defaultValueDate);
        }

        return search.push(betweenSearch);
      }
      case ComparisonType.LIKE:
      case ComparisonType.I_LIKE:
      case ComparisonType.NOT_LIKE: {
        const path = `${field}.${comparisonType}`;

        if (searchFullName) {
          const fullValue = String(value).trim().split(' ');
          fullValue.forEach((i) => {
            search.push({
              [String(field)]: {
                [FilterRelation.OR]: [
                  { firstName: { [comparisonType]: `%${String(i).trim()}%` } },
                  { lastName: { [comparisonType]: `%${String(i).trim()}%` } },
                ],
              },
            });
          });

          return search;
        }

        const formattedValue = String(value).trim();

        if (formattedValue) {
          search.push(_set({}, path, `%${String(value).trim()}%`));
        }

        return search;
      }
      default: {
        const path = `${field}.${comparisonType}`;

        return search.push(_set({}, path, value));
      }
    }
  });

  return {
    [filterRelation]: [...search],
  } as Filter<T>;
};

export default convertFiltersToGraphQLObject;
