import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UnknownType } from 'models';
import qs from 'qs';
import { Sort } from './types/Sort';
import { parseSortFromQueryParams } from './utils';

export type UseTableSortProps<Field extends string> = {
  defaultSort?: Sort<Field>;
  allowedFields: Field[];
};

export type UseTableSortResult<Field extends string> = {
  sort: Sort<Field> | null;
  setSort: (value: Sort<Field> | null) => void;
};

const useTableSort = <Field extends string>({
  defaultSort,
  allowedFields,
}: UseTableSortProps<Field>): UseTableSortResult<Field> => {
  const navigate = useNavigate();
  const location: UnknownType = useLocation();
  const [sort, setSort] = useState<Sort<Field> | null>(parseSortFromQueryParams<Field>({
    location,
    allowedFields,
  }) || defaultSort || null);

  const setSortHandler = (value: Sort<Field> | null) => {
    setSort(value);

    const {
      sort: sortField,
      order: sortOrder,
      ...rest
    } = qs.parse(location.search, { ignoreQueryPrefix: true });

    navigate({
      pathname: location.pathname,
      search: qs.stringify(value ? { ...rest, sort: value.field, order: value.order } : rest),
    }, { replace: true });
  };

  return {
    sort,
    setSort: setSortHandler,
  };
};

export default useTableSort;
