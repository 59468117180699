export enum PathEnum {
  OVERVIEW = '/overview',
  OVERVIEW_V2 = '/overview/v2',
  BUSINESSES = '/businesses',
  MONETIZATION = '/monetization',
  NOTIFICATIONS = '/notifications',
  EXCHANGE = '/exchange',
  USERS = '/users',
  ROLES = '/roles',
  LOGS = '/logs',
  SIGN_IN = '/sign-in',
}
