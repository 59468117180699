import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import {
  AntDesignConfigProvider,
  ReactQueryClientProvider,
  router,
} from 'config';
import { AuthorisationProvider } from 'services';
import './config/dayjs';
import reportWebVitals from './reportWebVitals';
import { PageLoader } from 'components';
import './App.css';
import './App.scss';
import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <AntDesignConfigProvider>
    <ReactQueryClientProvider>
      <AuthorisationProvider>
        <Suspense fallback={<PageLoader />}>
          <RouterProvider router={router} />
        </Suspense>
      </AuthorisationProvider>
    </ReactQueryClientProvider>
  </AntDesignConfigProvider>,
);

reportWebVitals();
