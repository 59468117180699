import React, { Dispatch, useEffect, useState } from 'react';
import { Switch, Typography } from 'antd';
import { CurrencyEnum } from 'models';
import { CurrencySelect } from 'components';
import styles from './ReduceToCurrency.module.scss';

export type ReduceToCurrencyProps = {
  currency: CurrencyEnum;
  reduceHandler: Dispatch<React.SetStateAction<boolean>>;
  currencyHandler: (currency: CurrencyEnum) => void;
};

export const ReduceToCurrency = ({
  currency,
  reduceHandler,
  currencyHandler,
}: ReduceToCurrencyProps) => {
  const [enableSwitch, setEnableSwitch] = useState(false);

  const toggleSwitch = () => {
    setEnableSwitch(prev => !prev);
    reduceHandler(prev => !prev);
  };

  useEffect(() => {
    if (enableSwitch) {
      currencyHandler(currency);
    }
  }, [currency, currencyHandler, enableSwitch]);

  return (
    <div className={styles.reduceTo}>
      <div className={styles.reduceToAction}>
        <Switch checked={enableSwitch} onChange={toggleSwitch} className={styles.switcher} />
        <Typography.Text className={styles.reduceToTitle}>Reduce to</Typography.Text>
      </div>
      <CurrencySelect value={currency} onChange={currencyHandler} />
    </div>
  );
};
