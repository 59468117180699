import React, { MouseEvent, ReactElement, useState } from 'react';
import { Drawer, Typography } from 'antd';
import clsx from 'clsx';
import { IBusiness } from 'models';
import { LogsViewerTable } from '.';
import styles from './LogsViewer.module.scss';

export const LogsViewer = ({
  business,
  disabled,
}: {
  business: IBusiness;
  disabled?: boolean;
}): ReactElement => {
  const [open, setOpen] = useState(false);

  const showDrawer = (e: MouseEvent<HTMLDivElement>) => {

    e.stopPropagation();
    if (business.ip) {
      setOpen(true);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography.Text
        onClick={showDrawer}
        disabled={disabled || !business.ip}
        className={clsx(styles.text, { [styles.disabled]: disabled || !business.ip })}
      >
        Show logs
      </Typography.Text>

      <Drawer
        className={styles.drawer}
        title={`${business?.name} logs`}
        placement="right"
        width={700}
        onClose={onClose}
        open={open}
      >
        <LogsViewerTable id={business?.id} />
      </Drawer>
    </>
  );
};
