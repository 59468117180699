import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, 'Name must be longer than or equal to 3 characters')
    .required('Business name is required'),
  // ip: yup
  //   .string()
  //   .matches(
  //     /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/,
  //     'Please enter a valid IP address',
  //   )
  //   .min(5, 'IP must be longer than or equal to 5 characters'),
  domain: yup
    .string()
    // .url('Please enter a valid URL with http:// or https://')
    .required('Domain is required'),
  apiKey: yup
    .string()
    // .matches(/^[a-z-]*$/, 'API Subdomain should only contain letters and hyphens')
    .required('API Subdomain is required'),
  apiGateway: yup
    .string()
    // .matches(/^[a-z-]*$/, 'API Client should only contain letters and hyphens')
    .required('API Gateway is required'),
  // userIds: yup
  //   .array()
  //   .of(yup.number().required())
  //   .required('API Subdomain is required'),
});
