export enum CardTitlesEnum {
  PROFIT = 'PROFIT',
  INCOME = 'INCOME',
  EXPENSES = 'EXPENSES',
  TOTAL_VOLUME = 'TOTAL_VOLUME',
  TRANSACTION_COUNT = 'TRANSACTION_COUNT',
  AVERAGE_TRANSACTION_TIME = 'AVERAGE_TRANSACTION_TIME',
  AVERAGE_TRANSACTION_VOLUME = 'AVERAGE_TRANSACTION_VOLUME',
  TOTAL_INFLOW = 'TOTAL_INFLOW',
  TOTAL_OUTFLOW = 'TOTAL_OUTFLOW',
  CURRENCY_EXCHANGE_VOLUME = 'CURRENCY_EXCHANGE_VOLUME',
}
