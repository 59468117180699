import React, { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';
import ReactSelect from 'react-select';
import { Button, Drawer, Form, Input, notification, Select, Space } from 'antd';
import { Rule } from 'antd/es/form';
import clsx from 'clsx';
import { queryClient } from 'config';
import { useAuthorisationContext } from 'services';
import useGroups from 'services/api/groups/useGetGroups';
import useCreateUser, { } from 'services/api/users/useCreateUser';
import { IUser } from 'models/interfaces';
import { UnknownType } from 'models/types';
import { emailValidator, generatePassword, generateSelectOptions } from 'utils';
import { withDrawer } from 'components/common';
import { LockOutlined, MailOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import styles from './FormAddNewUser.module.scss';

export type FormValues = {
  name: string;
  email: string;
  password: string;
  groupId: number;
  businesses: { label: string; value: number; }[];
};

export const FormAddNewUser = forwardRef<UnknownType, { button: UnknownType; }>((props, ref) => {
  const { user } = useAuthorisationContext();
  const [form] = Form.useForm();

  const { data, isLoading: isLoadingGroups } = useGroups();

  const { nodes: groups } = data || { totalCount: 0, nodes: [] };

  const { mutate, isLoading } = useCreateUser({
    onSuccess: async () => {
      notification.success({ message: 'User created' });
      await queryClient.resetQueries('users');
      // (props as UnknownType)?.handleDrawer(false);
    },
    onError: (e) => {
      if (e.response?.status === 403) {
        notification.error({
          message: 'User with this email already exists',
        });
      } else {
        notification.error({
          message: e.response?.data?.message,
        });
      }
    },
  });

  const onSubmit = async (values: FormValues) => {
    mutate({
      email: values.email,
      name: values.name.trim(),
      password: values.password,
      groupId: Number(values.groupId),
      businessIds: values.businesses.map((business: FormValues['businesses'][number]) => business.value),
    });
  };

  const onGeneratePassword = () => {
    const newPassword = generatePassword();
    form.setFieldsValue({ password: newPassword });
  };

  const inputEmailHandler = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      email: e.target.value.trim(),
    });
  };

  const emailRules: Rule[] = [
    () => ({
      validator(_, value: string) {
        return emailValidator(value);
      },
    }),
  ];

  useImperativeHandle(ref, () => ({ submitHandler: (ref as UnknownType).current && (ref as UnknownType).current.submit }));

  //  TODO: REMOVE AFTER FIX
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={showDrawer}>{props.button}</div>
      <Drawer title="Basic Drawer" onClose={onClose} open={open}>
        <Form
          form={form}
          onFinish={onSubmit}
          className={styles.form}
          name="createInternalUser"
          ref={ref}
        >
          <div className={styles.formContainer}>
            <Form.Item
              labelAlign="left"
              name="name"
              label="Name and surname"
              className={styles.formItem}
              rules={[
                { required: true, type: 'string', message: 'Please input name' },
                { max: 100, message: 'Email should be less than 100 characters' },
                {
                  pattern: /^([a-zA-Zа-яА-ЯҐЄІЇ-ґєії]+ [a-zA-Zа-яА-ЯҐЄІЇ-ґєії]+)$/,
                  message: 'Please input name and surname',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className={styles.icon} />}
                placeholder="Name and surname"
                className={styles.input}
              />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              required
              name="email"
              label="Email"
              className={styles.formItem}
              rules={emailRules}
            >
              <Input
                prefix={<MailOutlined className={styles.icon} />}
                placeholder="Email"
                onChange={inputEmailHandler}
                className={styles.input}
              />
            </Form.Item>
            <div className={clsx(styles.formItem, styles.withAddonBtn)}>
              <Form.Item
                labelAlign="left"
                label="Password"
                name="password"
                className={styles.input}
                rules={[
                  { required: true, message: 'Please input password' },
                  {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\dA-Za-z]{8,48}$/,
                    message: 'Password should contain at least 8 characters, including uppercase, lowercase letters and numbers',
                  },
                ]}
              >
                <Input.Password
                  autoComplete="new-password"
                  prefix={<LockOutlined className={styles.icon} />}
                  placeholder="Password"
                  className={styles.input}
                />
              </Form.Item>

              <Button
                type="link"
                onClick={onGeneratePassword}
                className={styles.generatePassBtn}
              >
                <ReloadOutlined />
                Generate password
              </Button>
            </div>
            <Form.Item
              className={styles.formItem}
              labelAlign="left"
              name="groupId"
              label="Role"
              rules={[
                { required: true, message: 'Please select role' },
              ]}
            >
              <Select
                loading={isLoadingGroups}
                disabled={isLoadingGroups}
                placeholder="Select role"
                className={styles.select}
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                {groups.map((item) => (
                  <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Businesses"
              name="businesses"
              labelAlign="left"
              rules={[{ required: true, message: 'Please select business' }]}
              className={styles.formItem}
            >
              <ReactSelect
                isMulti
                options={generateSelectOptions((user as IUser).businesses)}
              />
            </Form.Item>
          </div>

          <Space className={styles.formActions}>
            <Button
              loading={isLoading}
              disabled={isLoading}
              className={styles.cancelButton}
              type="text"
              onClick={onClose}
              htmlType="submit"
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              className={styles.submitButton}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
});

export const UserFormWithDrawer = withDrawer(FormAddNewUser);
