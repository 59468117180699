import { ReactElement } from 'react';
import { Table } from 'antd';
import _ from 'lodash';
import { IStatistics } from 'models';
import { CardInfoTableProps } from './types';
import { generateCardTableColumns, generateCardTableSourceData } from './utils';
import styles from './CardTable.module.scss';

export const CardTable = ({
  data,
  type,
  currency,
}: CardInfoTableProps): ReactElement => {
  const generatedColumns = generateCardTableColumns({ type, initCurrency: currency });
  const generatedDataSource = generateCardTableSourceData(data as IStatistics, type);

  return (
    <Table
      className={styles.table}
      columns={generatedColumns}
      dataSource={generatedDataSource}
      pagination={false}
      scroll={{
        x: _.isEmpty(data) ? undefined : true,
      }}
    />
  );
};
