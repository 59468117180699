import React, { useCallback } from 'react';
import { Table as AntdTable } from 'antd';
import {
  ColumnsType,
  GetRowKey,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import { UnknownType } from 'models';
import styles from './Table.module.scss';

type TableProps<TData> = {
  loading?: boolean;
  data: TData[];
  columns: ColumnsType<TData>;
  onSort?: (sort: SorterResult<TData> | null) => void;
  scrollWidth?: string | number;
  scrollHeight?: string | number;
  renderFooter?: (data: readonly TData[]) => React.ReactNode;
  rowKey?: string | keyof TData | GetRowKey<TData>;
  tableLayout?: 'auto' | 'fixed';
  pagination?: false | TablePaginationConfig;
};

const Table = <TData extends Record<string, unknown>>({
  loading,
  data,
  columns,
  onSort,
  renderFooter,
  scrollWidth,
  scrollHeight,
  rowKey,
  tableLayout = 'auto',
  pagination = false,
}: TableProps<TData>) => {

  const handleChange = useCallback((
    _pagination: UnknownType, // Unused: TablePaginationConfig,
    _filters: UnknownType, // Unused: Record<string, FilterValue | null>,
    sorter: SorterResult<TData> | SorterResult<TData>[],
  ) => {
    if (Array.isArray(sorter)) return;

    const sortResult = sorter.order
      ? { field: sorter.columnKey || sorter.field, order: sorter.order }
      : null;

    if (onSort) onSort(sortResult);
  }, [onSort]);

  return (
    <AntdTable
      rowKey={rowKey}
      loading={loading}
      dataSource={data}
      columns={columns}
      footer={renderFooter}
      onChange={handleChange}
      pagination={pagination}
      tableLayout={tableLayout}
      scroll={{ x: scrollWidth, y: scrollHeight }}
      rootClassName={styles.table}
      rowClassName={styles.row}
    />
  );
};

export default Table;
