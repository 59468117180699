import React, { PropsWithChildren } from 'react';
import { useAuthorisationContext } from 'services';
import { StageEnum } from 'hooks/use2FA';
import { Form2FA } from '../Form';
import { FormScanQR } from '../ScanQR';

type With2FAProps = PropsWithChildren<{
  onSubmit: (code: string) => void;
  style?: React.CSSProperties,
  className?: string,
}>;

const With2FA = ({
  onSubmit,
  children,
  className,
  style,
}: With2FAProps) => {
  const {
    twoFA: {
      qrcode,
      stage,
      setStage,
      prevStage,
      setPrevStage,
    },
    loading,
  } = useAuthorisationContext();

  return (
    <>
      {stage === StageEnum.DEFAULT && children}

      {(stage === StageEnum.QR_CODE && !!qrcode) && (
        <div className={className} style={style}>
          <FormScanQR
            qrCode={qrcode}
            onBack={() => setStage(StageEnum.DEFAULT)}
            onContinue={() => {
              setPrevStage(StageEnum.QR_CODE);
              setStage(StageEnum.OTP_CODE);
            }}
          />
        </div>
      )}

      {stage === StageEnum.OTP_CODE && (
        <div className={className} style={style}>

          <Form2FA
            isLoading={loading}
            onSubmit={onSubmit}
            onBack={() => setStage(prevStage)}
          />
        </div>
      )}
    </>
  );
};

export default With2FA;
