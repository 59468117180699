import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Checkbox, notification, Space, Spin } from 'antd';
import { IPermission, permissionsMap, UnknownType } from 'models';
import { formatPermissions } from 'utils/formatPermissions';
import { usePermissions } from './usePermissions';

export interface PermissionsSelectProps {
  value?: IPermission[];
  onChange?: (permissions: IPermission[]) => void;
}

export const PermissionsList = ({
  value,
  onChange,
}: PermissionsSelectProps): ReactElement => {
  const [valueArray, setValueArray] = useState<number[]>([]);

  const handleErrorPermissions = useCallback((e: UnknownType) => {
    notification.error({
      message: e.response?.data?.message || e.message,
    });
  }, []);

  const { data, isLoading } = usePermissions({
    onError: handleErrorPermissions,
  });

  const permissions: IPermission[] = useMemo(
    () => data?.nodes || [],
    [data?.nodes],
  );

  const toggleCheckbox = (id: number) => {
    const newValueArray = [...valueArray];
    const index = newValueArray.indexOf(id);

    if (index !== -1) {
      newValueArray.splice(index, 1);
    } else {
      newValueArray.push(id);
    }

    setValueArray(newValueArray);

    if (onChange) {
      const newPermissions = permissions.filter((item) =>
        newValueArray.includes(item.id),
      );
      onChange(newPermissions);
    }
  };

  const groupedPermissions = formatPermissions(permissions || []);

  useEffect(() => {
    setValueArray(value?.map((v) => v?.id) || ([] as number[]));
  }, [value]);

  if (isLoading) {
    return (
      <div style={{ margin: 8, display: 'flex', justifyContent: 'center' }}>
        <Spin />
      </div>
    );
  }

  return (
    <Space
      direction="vertical"
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: 16,
        width: '100%',
      }}
    >
      {groupedPermissions?.map((item: UnknownType) => {
        return (
          <Space
            key={item?.group}
            direction="vertical"
            style={{ width: '100%', marginBottom: '16px' }}
          >
            <h2
              style={{
                fontSize: 16,
                fontWeight: 600,
                marginBottom: 0,
                textTransform: 'capitalize',
              }}
            >
              {item.group}
            </h2>
            {item.category.map((category: UnknownType) => {
              return (
                <Space
                  key={category?.name}
                  direction="vertical"
                  style={{ overflowX: 'hidden' }}
                >
                  {category?.permissions?.map((perm: UnknownType) => {
                    return (
                      <Space
                        key={category?.name + perm?.name}
                        direction="vertical"
                        style={{ marginLeft: 8 }}
                      >
                        <Checkbox
                          checked={valueArray.includes(perm?.id)}
                          onChange={() => toggleCheckbox(perm?.id)}
                        >
                          <span>
                            {permissionsMap.get(perm.name) || perm.name}
                          </span>
                        </Checkbox>
                      </Space>
                    );
                  })}
                </Space>
              );
            })}
          </Space>
        );
      })}
    </Space>
  );
};
