import {
  UseMutateFunction,
  useMutation,
  useQuery,
  UseQueryResult,
} from 'react-query';
import { axios } from 'config';
import { IUser } from 'models';
import { LocalStorage, LocalStorageKey } from 'utils';

type AuthMeReturned = UseQueryResult<IUser, unknown>;
type AuthMeOptionsType = {
  onSuccess: (data: IUser) => void;
  onError: () => void;
};

type LoginBody = {
  email: string;
  password: string;
  otpCode?: string;
};

export type LoginData = {
  status?: 'OK',
  qrcode?: string,
  currentUser: IUser,
  accessToken: string;
  refreshToken: string;
};

type LoginOptionsType = {
  onSuccess: (data: LoginData) => void;
  onError: (e: unknown) => void;
};
type LoginReturned = {
  loginMutate: UseMutateFunction<LoginData, unknown, LoginBody, unknown>;
  isLoading: boolean;
};

export const useAuthMe = (
  options: AuthMeOptionsType,
  enabled: boolean,
): AuthMeReturned => {
  const fetchUser = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_AUTH_URI}/auth/current-user`,
      {
        headers: {
          Authorization:
            'Bearer ' + LocalStorage.get(LocalStorageKey.ACCESS_TOKEN),
        },
      },
    );
    return data;
  };

  return useQuery('currentUser', fetchUser, {
    ...options,
    enabled,
  });
};

export const useLogin = (options: LoginOptionsType): LoginReturned => {
  const login = async (body: LoginBody) => {
    const res = await axios.post(
      `${process.env.REACT_APP_AUTH_URI}/auth/login`,
      body,
    );
    return res?.data;
  };

  const { mutate, isLoading } = useMutation(login, options);

  return {
    loginMutate: mutate,
    isLoading,
  };
};
