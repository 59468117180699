interface Option {
  value: number;
  label: string;
}

export const generateDefaultOptions = <T extends Option>(
  options: T[],
  selectedIds: number[],
): T[] => {
  return options.filter((option) => selectedIds.includes(option.value));
};
