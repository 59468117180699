import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { ElementsSize } from '../../Pagination';
import styles from './PerPageItem.module.scss';

export interface IPerPageItemProps {
  count: number;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
  size: ElementsSize;
}

const PerPageItem = ({
  count, selected, disabled, onClick, size,
}: IPerPageItemProps): ReactElement => {

  const className = clsx(styles.perPageItem, {
    [styles.selected]: selected,
    [styles.disabled]: disabled,
    [styles.large]: size === 'large',
    [styles.medium]: size === 'medium',
    [styles.small]: size === 'small',
    [styles.xs]: size === 'xs',
  });

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {count}
    </button>
  );
};

export default PerPageItem;
