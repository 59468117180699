import React from 'react';
import { Divider, Space, Tooltip } from 'antd';
import clsx from 'clsx';
import { IPermission, permissionsMap, UnknownType } from 'models';
import { formatPermissions } from 'utils';
import { LockOutlined } from '@ant-design/icons';
import styles from './Permissions.module.scss';

export interface IPermissionsProps {
  permissions: IPermission[];
}

const Permissions = ({ permissions }: IPermissionsProps) => {
  const areTherePermissions = Boolean(permissions.length);

  const bodyJSX = (
    <span
      className={clsx(styles.permissions, {
        [styles.disabled]: !areTherePermissions,
      })}
    >
      <LockOutlined className={styles.icon} />
      <span>permissions</span>
    </span>
  );

  if (!areTherePermissions) {
    return bodyJSX;
  }

  const groupedPermissions = formatPermissions(permissions || []);

  const tooltipContentJSX = (
    <div className={styles.content}>
      {groupedPermissions.map((item, index) => (
        <Space key={item?.group} direction="vertical" style={{ width: '100%' }}>
          <h2
            style={{
              fontSize: 14,
              fontWeight: 600,
              marginBottom: 0,
              color: 'black',
              textTransform: 'capitalize',
            }}
          >
            {item.group}
          </h2>
          {item.category.map((category: UnknownType) => {
            return (
              <Space
                key={category?.name}
                direction="vertical"
                style={{ width: '100%', marginBottom: 0 }}
              >
                <ul className={styles.permissionsList}>
                  {category?.permissions?.map((perm: UnknownType) => (
                    <li
                      key={category?.name + perm?.name}
                      className={styles.permissionsItem}
                    >
                      <span>{permissionsMap.get(perm.name) || perm.name}</span>
                    </li>
                  ))}
                </ul>
              </Space>
            );
          })}
          {groupedPermissions.length - 1 !== index && (
            <Divider
              className={styles.divider}
              style={{ margin: 0, marginTop: '10px', marginBottom: '12px' }}
            />
          )}
        </Space>
      ))}
    </div>
  );

  return (
    <Tooltip
      overlayClassName={styles.tooltip}
      placement="bottom"
      color="transparent"
      overlayInnerStyle={{ padding: 0, backgroundColor: 'transparent' }}
      overlay={tooltipContentJSX}
    >
      {bodyJSX}
    </Tooltip>
  );
};

export default Permissions;
