import React, { lazy } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { PathEnum, PERMISSIONS } from 'models';
import { AuthLayout, MainLayout, withPermissions } from 'components';

const Signin = lazy(() => import('pages/Signin'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const Overview = withPermissions(lazy(() => import('pages/Overview')), [PERMISSIONS.business.read]);
const Overview2 = withPermissions(lazy(() => import('pages/Overview_v2')), [PERMISSIONS.business.read]);
const Businesses = withPermissions(lazy(() => import('pages/Businesses')), [PERMISSIONS.business.read]);
const Monetization = withPermissions(lazy(() => import('pages/Monetization')), [PERMISSIONS.monetization.read]);
const Notifications = withPermissions(lazy(() => import('pages/Notifications')), [PERMISSIONS.notification.read]);
const Roles = withPermissions(lazy(() => import('pages/Roles')), [PERMISSIONS.group.read]);
const Users = withPermissions(lazy(() => import('pages/Users')), [PERMISSIONS.user.read]);
const Logs = withPermissions(lazy(() => import('pages/Logs')), [PERMISSIONS.logs.read]);
const Exchange = withPermissions(lazy(() => import('pages/Exchange')), [PERMISSIONS.exchange.read]);

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to={PathEnum.OVERVIEW} replace />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <ErrorPage />,

    children: [
      {
        path: PathEnum.OVERVIEW,
        element: <Overview />,
      },
      {
        path: PathEnum.OVERVIEW_V2,
        element: <Overview2 />,
      },
      {
        path: PathEnum.BUSINESSES,
        element: <Businesses />,
      },
      {
        path: PathEnum.MONETIZATION,
        element: <Monetization />,
      },
      {
        path: PathEnum.NOTIFICATIONS,
        element: <Notifications />,
      },
      {
        path: PathEnum.ROLES,
        element: <Roles />,
      },
      {
        path: PathEnum.USERS,
        element: <Users />,
      },
      {
        path: PathEnum.EXCHANGE,
        element: <Exchange />,
      },
      {
        path: PathEnum.LOGS,
        element: <Logs />,
      },
    ],
  },
  {
    path: PathEnum.SIGN_IN,
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Signin />,
      },
    ],
  },
]);
