import { useMutation } from 'react-query';
import { axios } from 'config';

export const useUpdateImage = (id: number, container: string) => {
  return useMutation('container', async () => {
    const { data } = await axios.put(process.env.REACT_APP_AUTH_URI + '/ssh/' + id, {
      container,
    });
    return data;
  }, {
  });
};
