import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Typography } from 'antd';
import { PathEnum } from 'models';
import { useHeaderContext } from 'services';
import { NavMenuButton } from 'components';
import { UserCard } from './components';
import styles from './Header.module.scss';

const generateTitle = (title: string) => {
  switch (title) {
    case PathEnum.NOTIFICATIONS:
      return 'Notifications';
    case PathEnum.MONETIZATION:
      return 'Monetization';
    case PathEnum.BUSINESSES:
      return 'Businesses';
    case PathEnum.OVERVIEW_V2:
      return 'Overview v2';
    case PathEnum.OVERVIEW:
      return 'Overview';
    case PathEnum.ROLES:
      return 'Roles';
    case PathEnum.USERS:
      return 'Users';
    case PathEnum.EXCHANGE:
      return 'Exchange';
    case PathEnum.LOGS:
      return 'Logs';
    default:
      break;
  }
};

export const Header = (): ReactElement => {
  const { setContentRef } = useHeaderContext();

  const nav = useLocation();

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.container}>
        <Typography.Title className={styles.title}>
          {generateTitle(nav.pathname)}
        </Typography.Title>
        <div className={styles.content} ref={setContentRef} />
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <UserCard />
          <NavMenuButton />
        </div>
      </div>
    </Layout.Header>
  );
};
