import React from 'react';
import { Button } from 'antd';
import { GlobalHeaderContent } from 'components';
import { BusinessFormWithModal } from 'components/forms/BusinessForm/BusinessForm';
import { PlusOutlined } from '@ant-design/icons';
import styles from './AddBusinessButton.module.scss';

export const AddBusinessButton = () => {
  const invokeButton = (
    <Button
      className={styles.createBusinessButton}
      type="text"
      size="large"
      icon={<PlusOutlined />}
    >
      Business
    </Button>
  );

  return (
    <GlobalHeaderContent>
      <BusinessFormWithModal
        drawerProps={{
          button: invokeButton,
          title: 'Add Business',
        }}
      />
    </GlobalHeaderContent >
  );
};
