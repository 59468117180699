import React from 'react';
import { Button, Dropdown, Radio, Spin } from 'antd';
import styles from './SelectOption.module.scss';

export type SelectOptionData = {
  label: string;
  value: string;
};

export type SelectOptionProps<T extends SelectOptionData> = {
  options: T[];
  selected: T | undefined;
  onSelect: (item: T) => void;
  loading?: boolean;
  btnText?: string;
  btnIcon?: React.ReactNode;
  btnStyle?: React.CSSProperties;
};

const SelectOption = <T extends SelectOptionData>({
  selected,
  onSelect,
  options,
  loading,
  btnIcon,
  btnStyle,
  btnText = 'set property "btnText" to change this text',
}: SelectOptionProps<T>) => {
  const dropdownMenu = (
    <div className={styles.dropdownMenu}>
      {options.map(option => (
        <Radio
          key={option.value}
          checked={selected?.value === option.value}
          onChange={() => onSelect(option)}
        >
          {option.label}
        </Radio>
      ))}
    </div>
  );

  return (
    <Dropdown
      arrow
      trigger={['click']}
      placement="bottomRight"
      dropdownRender={() => (loading ? <Spin /> : dropdownMenu)}
    >
      <Button
        size="large"
        icon={btnIcon}
        style={btnStyle}
        loading={loading}
        disabled={loading}
        className={styles.button}
      >
        {btnText}
      </Button>
    </Dropdown>
  );
};

export default SelectOption;
