import React, { ReactElement } from 'react';
import { Image, Select, SelectProps, Space } from 'antd';
import clsx from 'clsx';
import { currencyMap } from './utils/currencyMap';
import { ReactComponent as CaretDown } from 'assets/icons/curret-down.svg';
import styles from './CurrencySelect.module.scss';

export type CurrencySelectProps = SelectProps;

export const CurrencySelect = (props: CurrencySelectProps): ReactElement => {
  return (
    <Select
      defaultValue="USDT"
      onChange={props.onChange}
      className={styles.currencySelect}
      size="large"
      suffixIcon={<CaretDown />}
      {...props}
    >
      {Object.entries(currencyMap).map(([key, value]) => (
        <Select.Option key={key}>
          <Space className={styles.currencySelectOption}>
            <Image
              src={value.icon}
              preview={false}
              className={clsx(styles.currencySelectImage, {
                [styles.disabled]: props.disabled,
              })}
            />
            {value.title}
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};
