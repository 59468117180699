// eslint-disable-next-line
const EMAIL_SCHEMA = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
const EMAIL_USERNAME_REGEX = /(^([a-zA-Z0-9])*$)|(^[a-zA-Z0-9]+[a-zA-Z0-9.]+[a-zA-Z0-9])+$/;

export const emailValidator = (value: string): Promise<Error | void> => {
  if (!value) {
    return Promise.reject(
      new Error('Please input email'),
    );
  }
  if (value.includes('@')) {
    const username = value.split('@')[0];
    const domain = `@${value.split('@')[1]}`;
    if (username.length > 64) {
      return Promise.reject(
        new Error('Username length must be less than 64 characters'),
      );
    }
    if (domain.length > 254) {
      return Promise.reject(
        new Error('Domain length must be less than 254 characters'),
      );
    }
    if (username.includes('..')) {
      return Promise.reject(
        new Error('Consecutive dots are not allowed in username'),
      );
    }
    if (!EMAIL_USERNAME_REGEX.test(username)) {
      return Promise.reject(
        new Error('Username must contain only letters, numbers, dots and not start or end with a dot'),
      );
    }
  }
  if (!EMAIL_SCHEMA.test(value)) {
    return Promise.reject(
      new Error('Invalid email'),
    );
  }
  return Promise.resolve();
};
