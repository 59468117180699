import _ from 'lodash';
import { UnknownType } from 'models';

export const filterByKeys = <T extends object>(data: T, filters: string[] = ['bep20', 'erc20', 'eth', 'bnb', 'trc20']) => {
  const filteredTokens: UnknownType = {};

  if (_.isEmpty(data)) return {};

  Object.entries(data)?.forEach(([key, value]) => {
    const [currency, network] = key.split('_');
    if (filters.includes(network) && !filters.includes(currency)) {
      filteredTokens[key] = value;
    }
  });

  return filteredTokens;
};
