import { useQuery } from 'react-query';
import { axios } from 'config/axios';
import { Filter, Sort } from 'models';
import { IExchangeHistory } from 'pages/Exchange/models/ExchangeHistoryData.interface';

type ExchangeHistoryParams = {
  filter: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset?: number };
  sorting?: (Sort<IExchangeHistory>)[];
};

const getExchangeHistory = (params: ExchangeHistoryParams) => async () => {
  const { data } = await axios.get('/exchange', { params });
  return data;
};

export const useGetExchangeHistory = (params: ExchangeHistoryParams) => {
  return useQuery({
    queryKey: ['exchange-history', params.paging, params.sorting],
    queryFn: getExchangeHistory(params),
    retry: false,
  });
};
