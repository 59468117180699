import { ThemeConfig } from 'antd';

const ANT_DESIGN_VARS = {
  PRIMARY_COLOR: '#6B5EFF',
  PRIMARY_COLOR_HOVER: '#594DDF',
  BORDER_COLOR: '#f5f5f5',
  BORDER_RADIUS: 6,
};

export const antDesignTheme: ThemeConfig = {
  token: {
    colorPrimary: ANT_DESIGN_VARS.PRIMARY_COLOR,
    colorPrimaryHover: ANT_DESIGN_VARS.PRIMARY_COLOR_HOVER,
    colorTextDescription: '#8C8C8C',
  },
  components: {
    Input: {
      borderRadius: 6,
      size: 40,
    },
    Button: {
      borderRadius: 6,
    },
    Drawer: {

    },
    Table: {},
    Layout: {
      headerBg: 'rgba(248, 248, 255, 1)',
    },
    Modal: {
      contentBg: 'rgba(251, 251, 251, 1)',
    },
  },
};
