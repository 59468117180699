import React from 'react';
import {
  ArrowDown,
  ArrowLineDownRight,
  ArrowLineUpRight,
  ArrowsDownUp,
  ArrowUp,
  ChartLineUp,
  Clock,
  CurrencyCircleDollar,
  Database,
} from '@phosphor-icons/react';
import { CardTitlesEnum } from 'models';
import { DashboardOutlined } from '@ant-design/icons';

export const overviewCardIcons = {
  [CardTitlesEnum.PROFIT]: <ChartLineUp />,
  [CardTitlesEnum.INCOME]: <ArrowLineDownRight />,
  [CardTitlesEnum.EXPENSES]: <ArrowLineUpRight />,
  [CardTitlesEnum.TOTAL_VOLUME]: <ArrowsDownUp />,
  [CardTitlesEnum.TRANSACTION_COUNT]: <Database />,
  [CardTitlesEnum.AVERAGE_TRANSACTION_TIME]: <Clock />,
  [CardTitlesEnum.AVERAGE_TRANSACTION_VOLUME]: <DashboardOutlined />,
  [CardTitlesEnum.TOTAL_INFLOW]: <ArrowDown />,
  [CardTitlesEnum.TOTAL_OUTFLOW]: <ArrowUp />,
  [CardTitlesEnum.CURRENCY_EXCHANGE_VOLUME]: <CurrencyCircleDollar />,
};
