import { UnknownType } from 'models/types';

export const sortGenerator = <T extends Record<string, UnknownType>>(
  a: T,
  b: T,
  field: string,
): number => {
  if (typeof a[field] === 'string') {
    return String(a[field]).localeCompare(String(b[field]));
  } else if (typeof a[field] === 'number') {
    return a[field] - b[field];
  } else if (a[field] instanceof Date && b[field] instanceof Date) {
    return a[field].getTime() - b[field].getTime();
  } else if (typeof a[field] === 'object' && typeof b[field] === 'object') {
    return 0;
  } else {
    return 0;
  }
};
