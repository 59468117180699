import React, {
  ReactElement,
} from 'react';
import Sider from 'antd/es/layout/Sider';
import { Logo, NavMenu } from 'components';
import styles from './Sidebar.module.scss';

export const Sidebar = (): ReactElement => {

  return (
    <Sider className={styles.sidebar} width={237}>
      <div className={styles.logoWrapper}>
        <Logo />
      </div>
      <NavMenu />
    </Sider>
  );
};
