import React, {
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input, notification } from 'antd';
import { queryClient } from 'config/react-query';
import type { FormWithRefReturn, UnknownType, WithDrawerProps } from 'models';
import { useBusinessesMutate } from 'services';
import { validationSchema } from './BusinessForm.schema';
import type { BusinessFormProps } from './types';
import { FormItem, UserSelect } from 'components';
import { withDrawer } from 'components/common/withDrawer/withDrawer';
import styles from './BusinessForm.module.scss';

export type ErrorOption = {
  response?: {
    status: number;
    message: string;
  };
};

export const BusinessForm = forwardRef<FormWithRefReturn, BusinessFormProps>(
  (props, ref) => {
    const { handleSubmit, control } = useForm({
      resolver: yupResolver(validationSchema) as UnknownType,
      defaultValues: async () => props.initialValues,
    });

    const { mutate } = useBusinessesMutate();

    const onSubmit = handleSubmit((data: UnknownType) => {
      mutate(
        {
          ...data,
          userIds: data.userIds.map(
            (item: UnknownType) => (item as UnknownType).value,
          ),
        },
        {
          onSuccess: async () => {
            notification.success({
              message: 'Success!',
              description: props.initialValues
              && props.initialValues.id ?
                'Business updated successfully.' :
                'Business created successfully.',
            });
            await queryClient.resetQueries('businesses');
            (props as UnknownType)?.handleDrawer(false);
          },

          onError: (error: unknown) => {
            const errorOption = error as ErrorOption;

            if (errorOption.response?.status === 403) {
              notification.error({
                message: 'Error!',
                description: 'Business already exists.',
              });
            } else if (errorOption.response?.status === 600) {
              notification.error({
                message: 'Error!',
                description: `Business ${data.name} does not exist.`,
              });
            } else {
              notification.error({
                message: 'Error!',
                description: 'Something went wrong.',
              });
            }
          },
        },
      );
    });

    useImperativeHandle(ref, () => ({ submitHandler: onSubmit }));

    return (
      <Form className={styles.form} initialValues={props.initialValues}>
        <div className={styles.formContainer}>
          <FormItem control={control} name="name" label="Business name">
            <Input placeholder="Enter business name" />
          </FormItem>
          <FormItem control={control} name="ip" label="IP address">
            <Input placeholder="Enter IP address" />
          </FormItem>
          <FormItem control={control} name="domain" label="Domain">
            <Input placeholder="Enter domain" />
          </FormItem>
          <FormItem control={control} name="apiKey" label="API Key">
            <Input placeholder="Enter API key" />
          </FormItem>
          <FormItem control={control} name="apiGateway" label="API Gateway">
            <Input placeholder="Enter API gateway" />
          </FormItem>
          <UserSelect
            initialValues={props.initialValues!}
            control={control}
            name="userIds"
          />
        </div>
      </Form>
    );
  },
);

export const BusinessFormWithModal = withDrawer<BusinessFormProps & WithDrawerProps>(BusinessForm);
