import React, { useState } from 'react';
import { Typography } from 'antd';
import styles from './Ellipsis.module.scss';

interface EllipsisProps {
  text: string;
  maxLength?: number;
}

export const Ellipsis: React.FC<EllipsisProps> = ({ text, maxLength = 46 }) => {
  const [showFullText, setShowFullText] = useState(false);

  const truncatedText = showFullText ? text : text?.slice(0, maxLength);
  const shouldShowButton = text?.length > maxLength;

  return (
    <div>
      <Typography.Text>{truncatedText}</Typography.Text>
      {shouldShowButton && (
        <>
          <Typography.Text>... </Typography.Text>
          <Typography.Text
            className={styles.action}
            onClick={() => setShowFullText(!showFullText)}
          >
            {showFullText ? 'Less' : 'More'}
          </Typography.Text>
        </>
      )}
    </div>
  );
};
