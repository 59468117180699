import { ReactElement } from 'react';
import { Divider, Skeleton } from 'antd';
import clsx from 'clsx';
import { CardTitles } from 'models';
import { getCorrectDate } from 'utils';
import {
  overviewCardIcons,
} from './utils';
import { CardProps, CountUp } from 'components';
import styles from './Card.module.scss';

export const ProfitCard = ({
  title,
  currency,
  amount,
  dateRange,
  isLoading,
}: CardProps): ReactElement => {
  const Icon = overviewCardIcons[title as keyof typeof overviewCardIcons];

  return isLoading ? (
    <Skeleton className="__sk_card" style={{ width: '100%', marginTop: 14 }} />
  ) : (
    <div className={clsx(styles.card, styles.cardBlock)}>
      <div className={styles.cardInner}>
        <div className={styles.header}>
          <div className={styles.iconWrapper}>{Icon}</div>
          <span className={styles.title}>{CardTitles[title]}</span>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.footer}>
          <span className={styles.date}>{getCorrectDate(dateRange)}</span>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.amountRow}>
          <CountUp
            className={styles.amount}
            currency={currency}
            start={0}
            end={amount!}
            type="currency"
            withText
          />
        </div>
      </div>
    </div>
  );
};
