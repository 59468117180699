import { SegmentedDateEnum } from 'models';

export type Value = string | number;
export type Handler = (value: number | boolean) => void;

export const generateDateAmount = (value: SegmentedDateEnum): number | null => {
  const today = new Date();
  let daysInMonth;

  switch (value) {
    case SegmentedDateEnum.YEAR:
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      const endOfYear = new Date(today.getFullYear() + 1, 0, 1);
      const daysInYear = Math.floor(
        ((endOfYear as unknown as number) - (startOfYear as unknown as number)) /
        (24 * 60 * 60 * 1000),
      );

      return daysInYear;
    case SegmentedDateEnum.MONTH:
      daysInMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0,
      ).getDate();
      return daysInMonth;
    case SegmentedDateEnum.WEEK:
      return 7;
    default:
      return null;
  }
};
