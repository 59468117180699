import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Drawer, Typography } from 'antd';
import { io, Socket } from 'socket.io-client';
import { LogList } from '..';
import styles from './LogsDrawer.module.scss';

export type LogsDrawerProps = {
  name: string;
  businessId: number;
  setContainerLogs: (logi: string[]) => void;
};

export const LogsDrawer = ({
  name,
  businessId,
  setContainerLogs,
}: LogsDrawerProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const [socket, setSocket] = useState<Socket>();
  const [logi, setLogi] = useState<string[]>([]);

  const getLogs = useCallback(
    (id: number, container: string) => {
      (socket as Socket).emit('docker_logs', {
        id,
        container,
      });
    },
    [socket],
  );

  const closeLogs = () => (socket as Socket).emit('stop_docker_logs');

  useEffect(() => {
    const socketInit = io(`${process.env.REACT_APP_AUTH_URI}/logs`, {
      transports: ['websocket'],
    });

    setSocket(socketInit);

    socketInit.on('stream_logs', (data) => {
      setLogi((prev) => [...prev, data]);
    });

    return () => {
      socketInit.disconnect();
    };
  }, []);

  useEffect(() => {
    if (logi.length) {
      setContainerLogs(logi);
    }
  }, [logi, setContainerLogs]);

  const showDrawer = () => {
    setOpen(true);
    getLogs(businessId, name);
  };

  const onClose = () => {
    setOpen(false);
    closeLogs();
  };

  return (
    <>
      <Typography.Text onClick={showDrawer} className={styles.containerHeader}>
        {name}
      </Typography.Text>
      <Drawer
        className={styles.drawer}
        title={name}
        placement="right"
        width={900}
        onClose={onClose}
        open={open}
      >
        <LogList logs={logi.join('\n')} />
      </Drawer>
    </>
  );
};
