import { ReactElement } from 'react';
import { Spin, SpinProps } from 'antd';
import clsx from 'clsx';
import styles from './Loader.module.scss';

export type LoaderProps = {
  isFullsize?: boolean;
  spinProps?: SpinProps;
};

export const Loader = ({ isFullsize, spinProps }: LoaderProps): ReactElement => {
  return (
    <div className={clsx(styles.loader, { [styles.fullsize]: isFullsize })}>
      <Spin {...spinProps} />
    </div>
  );
};
