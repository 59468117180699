import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Form, Input, notification, Select, Space, Typography } from 'antd';
import { queryClient } from 'config/react-query';
import { IPermission, UnknownType } from 'models';
import useCreateGroup, { CreateGroupBody } from 'services/api/groups/useCreateGroup';
import { PermissionsList } from 'components';
import { LeftOutlined, SlidersOutlined } from '@ant-design/icons';
import styles from './FormAddNewRole.module.scss';

export type RoleType = {
  name: string;
  status: number;
  permissions: IPermission[];
};

export type DefaultValuesType = {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  email: string;
  password: string;
  role: string;
};

export const FormAddNewRole = forwardRef<UnknownType>((props, ref) => {
  const [isShowPermissionsList, setIsShowPermissionsList] = useState(false);
  const [form] = Form.useForm<RoleType>();

  const { mutation } = useCreateGroup({
    onSuccess: async () => {
      notification.success({
        message: 'Group created',
      });

      await queryClient.resetQueries('groups');
      (props as UnknownType)?.handleDrawer(false);
    },
    onError: (e) => {
      if (e.response?.status === 403) {
        notification.error({
          message: 'Group with this name already exists',
        });
      } else {
        notification.error({
          message: e.response?.data?.message,
        });
      }
    },
  });

  const onSubmit = async (values: RoleType) => {
    const updatedValue: CreateGroupBody = {
      permissionIds: values?.permissions?.map((permission) => permission?.id),
      name: values?.name,
      isActive: values?.status === 1,
    };
    mutation(updatedValue);
  };

  const handlePermissionSelect = (selected: IPermission[]) => {
    form.setFieldValue('permissions', selected);
  };

  useImperativeHandle(ref, () => ({ submitHandler: (ref as UnknownType).current && (ref as UnknownType).current.submit }));

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      className={styles.form}
      name="createInternalUser"
      ref={ref}
    >
      <div
        className={styles.formContainer}
        style={{ display: isShowPermissionsList ? 'none' : undefined }}
      >
        <Form.Item
          labelAlign="left"
          name="name"
          label="Role name"
          className={styles.formItem}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please input role name',
            },
            { max: 60, message: 'Role name should be less than 60 characters' },
            { min: 3, message: 'Role name should be more than 3 characters' },
            {
              message: 'Role name should contain only letters',
              pattern: /^[a-zA-Zа-яА-ЯҐЄІЇ-ґєії ]+$/i,
            },
          ]}
        >
          <Input placeholder="Enter role name" className={styles.input} />
        </Form.Item>
        <Form.Item
          className={styles.formItem}
          labelAlign="left"
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select role' }]}
        >
          <Select
            placeholder="Select status"
            className={styles.select}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <Select.Option value={1}>Activated</Select.Option>
            <Select.Option value={0}>Deactivated</Select.Option>
          </Select>
        </Form.Item>
      </div>

      <Form.Item
        rules={[{ required: true, message: 'Please select permissions' }]}
        name="permissions"
      >
        <>
          <Button
            className={styles.permissionButton}
            icon={<SlidersOutlined />}
            type="text"
            onClick={() => setIsShowPermissionsList(true)}
            style={{ display: isShowPermissionsList ? 'none' : undefined }}
          >
            Configure permissions
          </Button>

          <Space
            direction="vertical"
            style={{
              marginBottom: 16,
              display: !isShowPermissionsList ? 'none' : 'grid',
            }}
          >
            <Space.Compact
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Button
                type="link"
                style={{ padding: 0, marginRight: 'auto' }}
                onClick={() => setIsShowPermissionsList(false)}
                className={styles.backButton}
              >
                <LeftOutlined /> Back
              </Button>

              <Typography.Text>Permissions</Typography.Text>
            </Space.Compact>

            <PermissionsList
              value={form.getFieldValue('permissions') || []}
              onChange={handlePermissionSelect}
            />
          </Space>
        </>
      </Form.Item>

      {/* <Space className={styles.formActions}>
        <Button
          loading={isCreating}
          disabled={isCreating}
          className={styles.cancelButton}
          type="text"
          htmlType="submit"
        >
          Cancel
        </Button>
        <Button
          loading={isCreating}
          disabled={isCreating}
          className={styles.submitButton}
          type="primary"
          htmlType="submit"
        >
          Save
        </Button>
      </Space> */}
    </Form>
  );
});
