import React, { ReactElement } from 'react';
import { NavLink as Link } from 'react-router-dom';
import styles from './NavLink.module.scss';

export type NavLinkProps = {
  to: string;
  children: ReactElement | string | number;
  onClick?: () => void;
};

export const NavLink = ({
  to,
  children,
  onClick,
  ...rest
}: NavLinkProps): ReactElement => {
  return (
    <Link
      to={to}
      className={({ isActive }) => (isActive ? styles.linkActive : '')}
      onClick={onClick}
    >
      <span className={styles.linkText} {...rest}>
        {children}
      </span>
    </Link>
  );
};
