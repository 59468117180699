export enum ApiPath {
  AUTH_LOGIN = 'auth/login',
  BUSINESSES = 'business',
  BUSINESS_COMMISSIONS = 'business/commissions',
  BUSINESS_STATISTICS = 'business/statistics',
  STATISTICS_V2 = 'statistics',
  LOGS = 'logs',
  NOTIFICATIONS = 'notifications',
  USERS = 'users',
  GROUPS = 'groups',
  GROUPS_CREATE = 'groups/create',
  USERS_CREATE = '/users/create',
}
