import dayjs, { Dayjs } from 'dayjs';

export const getCorrectDate = (
  _dateRange: [Dayjs | null, Dayjs | null] | [],
): string => {
  const [startDate, endDate] = _dateRange.map((date) => dayjs(date));
  if (startDate.year() === endDate.year()) {
    return `
      ${startDate.format('DD MMM')} - 
      ${endDate.format('DD MMM, YYYY')}`;
  } else {
    return `
      ${startDate.format('DD MMM, YYYY')} - 
      ${endDate.format('DD MMM, YYYY')}`;
  }
};
