import { FunctionComponent, ReactElement, SVGProps } from 'react';
import { PERMISSIONS } from 'models/consts/permissions';
import { PathEnum } from './Path';
import { CodeOutlined } from '@ant-design/icons';
import { ReactComponent as ExchangeIcon } from 'assets/icons/sidebar/currency_exchange.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/sidebar/group_add.svg';
import { ReactComponent as MonitizationIcon } from 'assets/icons/sidebar/monetization_on.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/sidebar/move_item.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/sidebar/notifications.svg';
import { ReactComponent as RolesIcon } from 'assets/icons/sidebar/tune.svg';
import { ReactComponent as OverviewIcon } from 'assets/icons/sidebar/window.svg';
import { ReactComponent as BusinessIcon } from 'assets/icons/sidebar/work.svg';

export type MenuItemType = {
  [key: string]: {
    path: string;
    label: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>> | ReactElement;
    permissions?: string[];
  };
};

export type RoutesMapType = {
  [key: string]: MenuItemType;
};

export const ROUTES: RoutesMapType = {
  GENERAL: {
    OVERVIEW: {
      path: PathEnum.OVERVIEW,
      label: 'Overview',
      icon: <OverviewIcon />,
      permissions: [PERMISSIONS.business.read],
    },
    BUSINESSES: {
      path: PathEnum.BUSINESSES,
      label: 'Businesses',
      icon: <BusinessIcon />,
      permissions: [PERMISSIONS.business.read],
    },
    MONETIZATION: {
      path: PathEnum.MONETIZATION,
      label: 'Monetization',
      icon: <MonitizationIcon />,
      permissions: [PERMISSIONS.monetization.read],
    },
    EXCHANGE: {
      path: PathEnum.EXCHANGE,
      label: 'Exchange',
      icon: <ExchangeIcon />,
      permissions: [PERMISSIONS.exchange.read],
    },
    EMPLOYEES_CLIENTS: {
      path: PathEnum.USERS,
      label: 'Users',
      icon: <UsersIcon />,
      permissions: [PERMISSIONS.user.read],
    },
    ROLES: {
      path: PathEnum.ROLES,
      label: 'Roles',
      icon: <RolesIcon />,
      permissions: [PERMISSIONS.group.read],
    },
    LOGS: {
      path: PathEnum.LOGS,
      label: 'Logs',
      icon: <CodeOutlined />,
      permissions: [PERMISSIONS.logs.read],
    },
  },
  SETTINGS: {
    NOTIFICATIONS: {
      path: PathEnum.NOTIFICATIONS,
      label: 'Notifications',
      icon: <NotificationsIcon />,
      permissions: [PERMISSIONS.notification.read],
    },
    LOG_OUT: {
      path: '',
      label: 'Log out',
      icon: <LogoutIcon />,
    },
  },
};
