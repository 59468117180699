import React from 'react';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import styles from './AuthLayout.module.scss';

export const AuthLayout = (): ReactElement => {
  return (
    <Layout className={styles.authLayout}>
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};
