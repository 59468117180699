import _ from 'lodash';
import { IStatistics, UnknownType } from 'models';
import { CardCollapseTableEnum } from 'components/common/CardNew/utils';

export const generateCardTableSourceData = (
  data: IStatistics,
  type: CardCollapseTableEnum,
) => {
  if (_.isEmpty(data)) { return []; }

  const dataEntries = Object.entries(data);

  const currencyTable = dataEntries.map(([currency, stats]) => ({
    currency,
    ...stats,
  }));

  const currencyAmountTable = dataEntries.map(([currency, amount]) => ({
    currency,
    amount,
  }));

  const spreadDataTable = [data];

  const dataSource = {
    [CardCollapseTableEnum.CURRENCY]: currencyTable,
    [CardCollapseTableEnum.GROSS_INCOME_CEX]: currencyTable,
    [CardCollapseTableEnum.EXPENSES_BREAKDOWN]: spreadDataTable,
    [CardCollapseTableEnum.AVERAGE_TRANSCACTIONS_VOLUME]: spreadDataTable,
    [CardCollapseTableEnum.AVERAGE_TRANSCACTIONS_TIME]: currencyAmountTable,
    [CardCollapseTableEnum.PAYIN_PAYOUT]: spreadDataTable,
    [CardCollapseTableEnum.REFILL_EXPENSES]: currencyTable,
  };

  return (dataSource as UnknownType)[type];
};
