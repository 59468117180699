import { createContext, RefObject, useContext } from 'react';

export interface IHeaderContext {
  contentRef: RefObject<HTMLDivElement>;
  setContentRef: (ref: HTMLDivElement | null) => void;
  rendered: boolean;
}

const HeaderContext = createContext<IHeaderContext>({
  contentRef: { current: null },
  setContentRef: () => null,
  rendered: false,
});

export const useHeaderContext = () => useContext(HeaderContext);

export default HeaderContext;
