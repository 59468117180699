import React, { ReactElement, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { PathEnum } from 'models';
import { AuthorisationContext } from 'services';

export const RequireAuth = ({
  children,
}: {
  children: JSX.Element;
}): ReactElement => {
  const { token } = useContext(AuthorisationContext);

  if (!token) {
    return <Navigate to={PathEnum.SIGN_IN} replace />;
  }

  return children;
};
