import { useQuery } from 'react-query';
import { axios } from 'config/axios';
import { Filter, IUser, Sort } from 'models';
import { ApiPath } from 'models/consts';

type GetUsersParams = {
  filter: Filter<Record<string, unknown>>;
  paging?: { limit: number; offset?: number };
  sorting?: (Sort<IUser>)[];
};

const getUsers = (params?: GetUsersParams) => async () => {
  const response = await axios.get(ApiPath.USERS, { params });
  return response.data || { totalCount: 0, nodes: [] };
};

const useGetUsers = (params?: GetUsersParams) => {
  return useQuery({
    queryKey: ['users', params],
    queryFn: getUsers(params),
  });
};

export default useGetUsers;
