import { ReactElement } from 'react';
import { isNil } from 'lodash';
import { CurrencyEnum } from 'models';
import { CountUp } from 'components';
import { CountUpType } from 'components/ui/CountUp/CountUp';
import styles from './CollapseHeader.module.scss';

export type CollapseHeaderProps = {
  title: string;
  amount?: number | null;
  currency: CurrencyEnum;
  type: CountUpType;
};

export const CollapseHeader = ({
  amount,
  currency,
  title,
  type = 'count',
}: CollapseHeaderProps): ReactElement => {

  return (
    <div className={styles.spaceBetween}>
      <span>{title}</span>
      {!isNil(amount) && (
        <CountUp currency={currency} end={amount} withText type={type} />
      )}
    </div>
  );
};
