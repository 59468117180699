import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { useHeaderContext } from 'services';

const GlobalHeaderContent = ({ children }: PropsWithChildren) => {
  const { contentRef, rendered } = useHeaderContext();

  if (!contentRef.current || !children || !rendered) {
    return null;
  }

  return createPortal(children, contentRef.current);
};

export default GlobalHeaderContent;
