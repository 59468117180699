import React from 'react';
import { PencilSimple } from '@phosphor-icons/react';
import { Space, Typography } from 'antd';
import {
  IBusiness,
  IBusinessPost,
  IBusinessPostWithId,
  UnknownType,
} from 'models';
import { BusinessFormWithModal } from 'components/forms/BusinessForm/BusinessForm';

export type DefaultValuesType = IBusiness | IBusinessPost | IBusinessPostWithId;

export type EditBusinessActionProps = {
  defaultData: IBusiness | IBusinessPost | IBusinessPostWithId;
};

export const EditBusinessAction = ({ defaultData }: EditBusinessActionProps) => {
  const modalHeader = (
    <Space style={{ gap: '16px' }}>
      <PencilSimple />
      <Typography.Text>Edit business</Typography.Text>
    </Space>
  );

  return (
    <BusinessFormWithModal
      initialValues={defaultData as UnknownType}
      drawerProps={{
        button: <Typography.Text>Edit business</Typography.Text>,
        title: modalHeader,
      }}
    />
  );
};
