import IconAED from 'assets/icons/currencies/IconAED.svg';
import IconBNB from 'assets/icons/currencies/IconBNB.svg';
import IconBTC from 'assets/icons/currencies/IconBTC.svg';
import IconDAI from 'assets/icons/currencies/IconDAI.svg';
import IconETH from 'assets/icons/currencies/IconETH.svg';
import IconEUR from 'assets/icons/currencies/IconEUR.svg';
import IconGBP from 'assets/icons/currencies/IconGBP.svg';
import IconILS from 'assets/icons/currencies/IconILS.svg';
import IconTRX from 'assets/icons/currencies/IconTRX.svg';
import IconUAH from 'assets/icons/currencies/IconUAH.svg';
import IconUSD from 'assets/icons/currencies/IconUSD.svg';
import IconUSDC from 'assets/icons/currencies/IconUSDC.svg';
import IconUSDT from 'assets/icons/currencies/IconUSDT.svg';
// import IconBUSD from 'assets/icons/currencies/IconBUSD.svg';

export const CurrencyIcon: { [key: string]: string } = {
  ETH: IconETH,
  TRX: IconTRX,
  BTC: IconBTC,

  USDT: IconUSDT,
  USDC: IconUSDC,
  'USDT TRC20': IconUSDT,
  'USDT ERC20': IconUSDT,

  UAH: IconUAH,
  USD: IconUSD,
  EUR: IconEUR,
  GBP: IconGBP,
  ILS: IconILS,
  AED: IconAED,

  BNB: IconBNB,
  // BUSD: IconBUSD,
  DAI: IconDAI,
};
