import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { HeaderProvider } from 'services';
import { Footer, Header, RequireAuth } from 'components';
import { Sidebar } from './components';
import styles from './MainLayout.module.scss';

export const MainLayout = (): ReactElement => {
  return (
    <RequireAuth>
      <Layout className={styles.layout}>
        <Sidebar />
        <HeaderProvider>
          <Layout.Content className={styles.main}>
            <Header />
            <div className={styles.outlet}>
              <Outlet />
            </div>
            <Footer />
          </Layout.Content>
        </HeaderProvider>
      </Layout>
    </RequireAuth>
  );
};
