import React, { useMemo } from 'react';
import ReactCountUp, { CountUpProps } from 'react-countup';
import { Space, Spin } from 'antd';
import clsx from 'clsx';
import { CardTitlesEnum, CurrencyEnum } from 'models';
import { formatDuration, truncateNumber } from 'utils';
import { CurrencyIcon } from './utils';
import styles from './CountUp.module.scss';

export type CountUpType = 'currency' | 'time' | 'count' | 'percentage';

export type CountUpPropTypes = Omit<CountUpProps, 'end'> & {
  type?: CountUpType;
  currency?: string;
  styles?: string;
  withIcon?: boolean;
  isRoundDown?: boolean;
  staticDecimals?: number;
  classNames?: string;
  end?: number;
  isCount?: boolean;
  isTime?: boolean;
  withText?: boolean;
  isLoading?: boolean;
};

type GetCurrencyLabelJSXProps = {
  currency: string;
  network?: string;
  label?: string | false;
  className?: string;
  withNetwork?: boolean;
  size?: string | number;
};

const STABLE_COINS = ['USDT', 'USDC', 'BNB', 'DAI', 'ETH', 'BTC', 'TRX'];

export const getCurrencyLabelJSX = ({
  currency: initCurrency,
  network: initNetwork,
  className,
  label,
  size = 18,
}: GetCurrencyLabelJSXProps) => {
  const [currency, network] = initCurrency.split('_');
  const isStableCoin = (initNetwork || network) && STABLE_COINS.includes(currency);

  return (
    <span className={clsx(styles.optionLabel, className)}>
      {currency && (
        <img
          src={CurrencyIcon[currency]}
          style={{ width: size, height: size }}
          className={styles.currencyIcon}
          alt={currency}
        />
      )}
      {label !== false && (
        <>
          <span className={styles.label}>{label || currency}</span>
          {isStableCoin && <span className={styles.network}>({network || initNetwork})</span>}
        </>
      )}
    </span>
  );
};

const LONG_NETWORKS = ['btc', 'eth', 'bnb', 'dai', 'erc', 'bep'];

export const generateCountupType = (title: CardTitlesEnum): CountUpType => {
  switch (title) {
    case CardTitlesEnum.TRANSACTION_COUNT:
      return 'count';
    case CardTitlesEnum.AVERAGE_TRANSACTION_TIME:
      return 'time';
    default:
      return 'currency';
  }
};

export const CountUp = ({
  end,
  currency = CurrencyEnum.USDT,
  isRoundDown,
  staticDecimals,
  classNames,
  isCount,
  isTime,
  type = 'count',
  withText = false,
  isLoading,
  ...otherProps
}: CountUpPropTypes) => {
  // const decimals = staticDecimals || (LONG_NETWORKS.some((network) => currency.toLowerCase().includes(network)) ? 5 : 2);
  // // end = isRoundDown
  // //   ? Number(formatNumberWithDecimals(end, decimals))
  // //   : Number(end);

  // end = Number(truncateNumber(end, decimals));

  const countUpType = useMemo(() => ({
    currency: {
      decimals: (LONG_NETWORKS.some((network) =>
        currency.toLowerCase().includes(network) && !currency?.toLowerCase().includes('usdt') && !currency?.toLowerCase().includes('usdc')) ? 5 : 2),
      text: getCurrencyLabelJSX({ currency }),
    },
    time: {
      decimals: 2,
      text: <span className={styles.time}>{formatDuration(end || 0)}</span>,
    },
    count: {
      decimals: 0,
      text: '',
    },
    percentage: {
      decimals: 2,
      text: '%',
    },
  }), [currency, end]);

  const decimals = countUpType[type!].decimals;
  end = Number(truncateNumber(end, decimals));

  const countUpProps = {
    separator: ' ',
    decimal: ',',
    decimals: end === 0 ? 0 : decimals,
    end,
    start: Number(end) || 0,
    ...otherProps,
  };

  return (
    <Space className={clsx(styles.countUp, classNames)}>
      {isLoading ? <Spin /> : <ReactCountUp {...countUpProps} />}
      {withText && countUpType[type!].text}
    </Space>
  );
};
