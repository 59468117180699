import _ from 'lodash';
import qs from 'qs';
import { PerPageNumber } from 'components';

export type ParsePaginationFromQueryParamsProps = {
  location: Location;
  allowedPerPageOptions: number[];
};

export type ParsePaginationFromQueryParamsResult = {
  page?: number;
  perPage?: PerPageNumber;
};

const parsePaginationFromQueryParams = ({
  location,
  allowedPerPageOptions,
}: ParsePaginationFromQueryParamsProps): ParsePaginationFromQueryParamsResult => {
  const { page, perPage } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const parsedPage = _.isString(page) ? parseInt(page, 10) : null;
  const parsedPerPage = _.isString(perPage) ? parseInt(perPage, 10) : null;

  return {
    page: _.isNumber(parsedPage) && parsedPage > 0 ? parsedPage : undefined,
    perPage: _.isNumber(parsedPerPage) && allowedPerPageOptions.includes(parsedPerPage)
      ? parsedPerPage as PerPageNumber
      : undefined,
  };
};

export default parsePaginationFromQueryParams;
